import React, { useContext } from 'react'

import { Box, Menu, MenuItem, Typography, ListItemIcon } from '@mui/material'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'
import { hideOrShowProperty, hasNonEmptyNutritionalValue } from 'utils/utils'
import Button from 'components/CustomButtons/Button.js'
import organizationToRules from 'organizationToRules'
import VirtualAssistant from 'components/VirtualAssistant/VirtualAssistant'
const useStyles = makeStyles()(presentationStyle)

const MenuMobile = (props) => {
  console.log('PROPS: ISFASHION:', props.isFashion)
  const { state, dispatch } = useContext(AppContext)
  const { sectionActive, anchorMenu, discover, product, company } = state

  // funzione che apre il menu mobile
  const handleMenuOpen = (event) => {
    dispatch({
      type: 'SET_ANCHOR_MENU',
      payload: document.getElementById('mobile-menu-container')
    })
  }

  // funzione che chiude il menu mobile
  const handleMenuClose = () => {
    dispatch({
      type: 'SET_ANCHOR_MENU',
      payload: null
    })
  }

  // funzione che cambia la sezione visualizzata
  const changeSection = (event, section) => {
    dispatch({
      type: 'SET_MENU_SECTION',
      payload: section
    })
    handleMenuClose()
  }

  const { classes } = useStyles()
  const { t } = useTranslation('menu')

  return (
    <>
      <Box
        id='mobile-menu-container'
        component='div'
        position='fixed'
        bottom={0}
        zIndex='tooltip'
        boxShadow={3}
        className={classes.menuContainerMobile}
      >
        <Box component='div' className={classes.mainMenuMobile}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Button
              style={{ width: '50px', height: '50px' }}
              onClick={() => changeSection(null, 1)}
              className={classes.menuButtonMobilePadding}
              color={sectionActive !== 1 ? 'secondary' : 'productButton'}
            >
              <div className={classes.sectionButton}>
                <div style={{ marginBottom: '18px' }}>
                  <i
                    style={{
                      fontSize: '1.4rem',
                      marginRight: '2px',
                      color: '#3C4858'
                    }}
                    className='fas fa-search'
                  />
                </div>
              </div>
            </Button>
            <small>
              <div>{t('specifications')}</div>
            </small>
          </div>
          {!organizationToRules[company.organizationId] ||
          (organizationToRules[company.organizationId] &&
            !organizationToRules[company.organizationId].hideCompany)
            ? !state.product.metadata?.isCompanyFrontendHide && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <Button
                    style={{ width: '50px', height: '50px' }}
                    onClick={() => changeSection(null, 2)}
                    className={classes.menuButtonMobilePadding}
                    color={sectionActive !== 2 ? 'secondary' : 'companyButton'}
                  >
                    <div className={classes.sectionButton}>
                      <div style={{ marginBottom: '18px' }}>
                        <i
                          style={{
                            fontSize: '1.4rem',
                            marginRight: '2px',
                            color: '#3C4858'
                          }}
                          className='fas fa-industry'
                        />
                      </div>
                    </div>
                  </Button>
                  <small>{t('company')}</small>
                </div>
              )
            : null}
          {product.waste != null && product.waste.length > 0 && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Button
                style={{ width: '50px', height: '50px' }}
                onClick={() => changeSection(null, 5)}
                className={classes.menuButtonMobilePadding}
                color={sectionActive !== 5 ? 'secondary' : 'allergenesButton'}
              >
                <div className={classes.sectionButton}>
                  <div style={{ marginBottom: '18px' }}>
                    <i
                      style={{
                        fontSize: '1.4rem',
                        marginRight: '2px',
                        color: '#3C4858'
                      }}
                      className='fas fa-recycle'
                    />
                  </div>
                </div>
              </Button>
              <small>{t('waste')}</small>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {(hideOrShowProperty(discover, 'traceability', 'product') ||
              hideOrShowProperty(discover, 'traceability', 'lot')) && (
              <>
                <Button
                  style={{ width: '50px', height: '50px' }}
                  onClick={() => changeSection(null, 4)}
                  className={classes.menuButtonMobilePadding}
                  color={
                    sectionActive !== 4 ? 'secondary' : 'traceabilityButton'
                  }
                >
                  <div className={classes.sectionButton}>
                    <div style={{ marginBottom: '18px' }}>
                      <i
                        style={{
                          fontSize: '1.4rem',
                          marginRight: '2px',
                          color: '#3C4858'
                        }}
                        className='far fa-calendar-alt'
                      />
                    </div>
                  </div>
                </Button>
                <small>{t('traceability')}</small>
              </>
            )}
          </div>
          {(product.certifications && product.certifications.length) ||
          (product.nutritionalValue &&
            Object.keys(product.nutritionalValue).length) ? (
            <>
              {!props.isFashion && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <Button
                      style={{ width: '50px', height: '50px' }}
                      onClick={handleMenuOpen}
                      className={classes.menuButtonMobilePadding}
                      color='transparent'
                    >
                      <div className={classes.sectionButton}>
                        <div style={{ marginBottom: '18px' }}>
                          <i
                            style={{
                              fontSize: '1.4rem',
                              marginRight: '2px',
                              color: '#3C4858'
                            }}
                            className='fas fa-ellipsis-v'
                          />
                        </div>
                      </div>
                    </Button>
                    <small>{t('otherSections')}</small>
                  </div>

                  <Menu
                    id='mobile-menu'
                    anchorEl={anchorMenu}
                    keepMounted
                    open={Boolean(anchorMenu)}
                    onClose={handleMenuClose}
                    PaperProps={{
                      style: {
                        maxHeight: '75vh',
                        width: '90%'
                      }
                    }}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                    }}
                  >
                    {/* {hideOrShowProperty(discover, 'allergenes', 'product') && product.showAllergenes &&
                      <MenuItem onClick={() => changeSection(null, 4)}>
                        <ListItemIcon><i style={{ fontSize: '1.4rem' }} className='fas fa-utensils' /></ListItemIcon>
                        <Typography variant='inherit' noWrap>{t('allergenes')}</Typography>
                      </MenuItem>} */}
                    {hideOrShowProperty(
                      discover,
                      'certifications',
                      'product'
                    ) &&
                      product.certifications != null &&
                      product.certifications.length > 0 && (
                        <MenuItem onClick={() => changeSection(null, 3)}>
                          <ListItemIcon>
                            <i
                              style={{ fontSize: '1.4rem' }}
                              className='fas fa-fingerprint'
                            />
                          </ListItemIcon>
                          <Typography variant='inherit' noWrap>
                            {t('certifications')}
                          </Typography>
                        </MenuItem>
                      )}
                    {hideOrShowProperty(
                      discover,
                      'nutritionalValue',
                      'product'
                    ) &&
                      product.nutritionalValue &&
                      Object.keys(product.nutritionalValue).length > 0 &&
                      hasNonEmptyNutritionalValue(
                        state.product.nutritionalValue
                      ) && (
                        <MenuItem onClick={() => changeSection(null, 6)}>
                          <ListItemIcon>
                            <i
                              style={{ fontSize: '1.4rem' }}
                              className='fas fa-list'
                            />
                          </ListItemIcon>
                          <Typography variant='inherit' noWrap>
                            {t('nutritionalValues')}
                          </Typography>
                        </MenuItem>
                      )}
                  </Menu>
                </>
              )}
            </>
          ) : null}
        </Box>
      </Box>
      {organizationToRules[company.organizationId] &&
      organizationToRules[company.organizationId].virtualAssistant ? (
        <VirtualAssistant />
      ) : null}
    </>
  )
}

export default MenuMobile
