import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import useMediaQuery from '@mui/material/useMediaQuery'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'
import { alog } from 'utils/apioLog'

import { Box, Typography } from '@mui/material'
import Button from 'components/CustomButtons/Button.js'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
//import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import 'react-image-gallery/styles/css/image-gallery.css'
//import ImageGallery from 'react-image-gallery'
// import Carousel, { Modal, ModalGateway } from 'react-images'
import './style.css'
import YouTube from 'react-youtube'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const useStyles = makeStyles()(presentationStyle)
const openPage = (link) => {
  alog('link', link, 'DEBUG', 'info')
  window.open(link, '_blank')
}
const MediaSectionDesktop = () => {
  const { classes } = useStyles()
  const { state } = useContext(AppContext)
  const { product } = state
  //const [currentImage, setCurrentImage] = useState(0)
  //const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const [images, setImages] = useState([])
  const [videos, setVideos] = useState([])
  const [cta, setCta] = useState([])
  const { t } = useTranslation('attributesGrid')

  useEffect(() => {
    const images = product.additionalAttributes?.filter((element) => element.type === 'image')
      .map((elem) => ({
        original: elem.link,
        thumbnail: elem.link
      }))
    alog('images:::::::', images, 'DEBUG', 'info')

    setImages(images)

    const videos = product.additionalAttributes?.filter(
      (element) => element.type === 'video'
    )
    alog('videos:::::::', videos, 'DEBUG', 'info')

    setVideos(videos)

    const cta = product.additionalAttributes?.filter(
      (element) => element.type === 'button'
    )
    alog('cta:::::::', cta, 'DEBUG', 'info')

    setCta(cta)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  const isSmall = useMediaQuery('(max-width: 600px)')

  return (
    <GridContainer
      className={classes.contentContainer}
      style={
        isSmall
          ? { paddingLeft: '0px', paddingRight: '0px', marginTop: '16px' }
          : { background: 'white' }
      }
    >
      {images && images.length > 0 ? (
        <>
          {/* <GridItem xs={12} md={12} xl={12} /> */}
          <GridItem xs={12} md={6} xl={6} style={{ paddingBottom: 40 }}>
            <Typography
              variant="h5"
              component="p"
              style={{ color: '#3C4858', margin: '0px 0 20px 0' }}
              className={classes.fredoka}
            >
              {`${t('image')}`}
            </Typography>

            {/* </GridItem>
          <GridItem xs={12} md={6} xl={6}> */}
            <Slider
              dots={true}
              infinite={true}
              slidesToShow={1}
              slidesToScroll={1}
            >
              {images.map((elem) => (
                <div className={classes.videoWrapper}>
                  <Box
                    component="img"
                    //className={classes.videoWrapper}
                    sx={{
                      objectFit: 'cover',
                      //width: '100%',
                      height: '100%',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)'
                      //maxHeight: '100%' // Imposta l'altezza massima a 100% del contenitore
                    }}
                    alt="The house from the offer."
                    src={elem.original}
                  />
                </div>
              ))}
            </Slider>
            {/* <div style={{ marginTop: 20 }}>
              <ImageGallery items={images} />
            </div> */}
          </GridItem>
        </>
      ) : null}
      {videos && videos.length > 0 ? (
        <GridItem xs={12} md={6} xl={6} style={{ paddingBottom: 40 }}>
          <Typography
            variant="h5"
            component="p"
            style={{ color: '#3C4858', margin: '0px 0 20px 0' }}
            className={classes.fredoka}
          >
            {`${t('video')}`}
          </Typography>
          <Slider
            dots={true}
            infinite={true}
            slidesToShow={1}
            slidesToScroll={1}
          >
            {videos.map((elem) => {
              let videoElement
              if (new URL(elem.link).hostname.includes('youtu.be')) {
                const videoId = elem.link.slice(elem.link.lastIndexOf('/') + 1)
                videoElement = (
                <div>
                  {elem.description && <Typography variant='h5' component='p' style={{ color: '#3C4858', margin: '0px 0 20px 0' }}>{elem.description}</Typography>}
                  <div key={elem.link} className={classes.videoWrapper}>
                    <YouTube
                      iframeClassName={classes.videoWrapperFrame}
                      opts={{
                        playerVars: {
                          autoplay: 0
                        }
                      }}
                      videoId={videoId}
                    />
                   </div>
                  </div>
                )
              } else if (new URL(elem.link).hostname.includes('vimeo')) {
                const videoId = elem.link.slice(elem.link.lastIndexOf('/') + 1)
                videoElement = (
                  <div>
                    {elem.description && <Typography variant='h5' component='p' style={{ color: '#3C4858', margin: '0px 0 20px 0' }}>{elem.description}</Typography>}
                  <div key={elem.link} className={classes.videoWrapper}>
                    <iframe
                      title={`https://player.vimeo.com/video/${videoId}`}
                      className={classes.videoWrapperFrame}
                      width="560"
                      height="315"
                      src={`https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                  </div>
                )
              } else {
                videoElement = (
                  <div>
                    {elem.description && <Typography variant='h5' component='p' style={{ color: '#3C4858', margin: '0px 0 20px 0' }}>{elem.description}</Typography>}
                  <div key={elem.link} className={classes.videoWrapper}>
                    <video
                      controls
                      className={classes.videoWrapperFrame}
                      src={elem.link}
                    />
                  </div>
                </div>
                )
              }
              return videoElement
            })}
          </Slider>
        </GridItem>
      ) : null}

      {cta && cta.length > 0 ? (
        <>
          {/* call to action dicitura */}

          {/* <GridItem xs={12} md={12} xl={12}></GridItem>
        <GridItem xs={12} md={12} xl={12}>
          <Typography variant="h4" component="p" style={{ margin: '30px 0 20px 0' }}>
            {`${t('callToAction')}`}
          </Typography>
        </GridItem> */}
          {/* <GridContainer /> */}
          {cta.map((elem) => (
            <GridItem
              key={`${elem.link}`}
              sx={{ marginTop: '2%' }}
              xs={12}
              md={6}
              xl={6}
            >
              {/* <Card>
                <CardContent> */}
              {/* <Typography variant="h5" color="textSecondary" component="h5"> */}
              <Typography
                variant="h5"
                component="p"
                style={{ color: '#3C4858', margin: '0px 0 20px 0' }}
                className={classes.fredoka}
              >
                {elem.description}
              </Typography>
              <Typography variant="body2" component="p">
                {/* {`${t('callToActionSentence')}`} */}
              </Typography>
              {/* </CardContent>
                <CardActions> */}
              {/* <Button
                    onClick={() => openPage(elem.link)}
                    className={`${classes.success} btnLink`}
                    variant='contained'
                    endIcon={<ArrowForwardIosOutlinedIcon />}
                  >
                    {`${t('callToActionSentence')}`}
                  </Button> */}
              <Button
                onClick={() => openPage(elem.link)}
                className={`${classes.companyButton} btnLink`}
                variant="contained"
                // endIcon={<ArrowForwardIosOutlinedIcon />}
                color="ctaButton"
                //style={{ width: '100%' }}
              >
                {`${t('callToActionSentence')}`}
              </Button>
              {/* </CardActions>
              </Card> */}
            </GridItem>
          ))}
        </>
      ) : null}
    </GridContainer>
  )
}
export default MediaSectionDesktop
