// require('dotenv').config()
const manifest = require('../package.json')
const configuration = {
  logTag: process.env.REACT_APP_APIO_LOG || null,
  version: manifest.version,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://testapi.trusty.id',
  environment: process.env.REACT_APP_ENV,
  token: process.env.REACT_APP_API_TOKEN,
  publicApiKey: process.env.REACT_APP_PUBLIC_API_KEY,
  blockchainExplorer: 'https://www.blockchain.com',
  sentry: {
    dsn: 'http://a6ed129559004e649e00928a10c02845@sentry.apio.cloud/66'
  }
}

export default configuration
