import React, { useContext } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Link,
  Tooltip,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material'
//import Hidden from '@mui/material/Hidden'
import CopyIcon from '@mui/icons-material/ContentCopy'

import { AppContext } from '../../context/AppState'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import {
  purchaseOrderStaticHUILA,
  purchaseOrderStaticTUMACO
} from '../../utils/traceability-trusty-events'

export default function LukerTraceability({ events, glnName, gtinName }) {
  const { t } = useTranslation('xdaiHeader')
  const { state } = useContext(AppContext)
  const { product } = state
  const isTrackAndTraceClickable = product.metadata.isTrackAndTraceClickable
  console.log('EVENTS LUKER', events, glnName, gtinName, product)
  let eventsComplete
  if (product.resourceId === 'fc06acd0-69ff-443a-baa9-9b57ed009a5f') {
    eventsComplete = purchaseOrderStaticTUMACO.concat(events)
  } else {
    eventsComplete = purchaseOrderStaticHUILA.concat(events)
  }

  return (
    // <TableContainer component={Paper}>
    //   <Table aria-label='collapsible table'>
    //     <TableHead>
    //       <TableRow>
    //         <TableCell />
    //         <TableCell>{t('blockchain')}</TableCell>
    //         <TableCell align='right'>{t('name')}</TableCell>
    //         <TableCell align='right'>{t('eventType.title')}</TableCell>
    //         <TableCell align='right'>{t('bizStep.title')}</TableCell>
    //         <TableCell align='right'>{t('eventTime')}</TableCell>
    //         <TableCell align='right'>{t('location')}</TableCell>
    //         <TableCell align='right'>{t('locationFrom')}</TableCell>
    //         <TableCell align='right'>{t('locationTo')}</TableCell>
    //       </TableRow>
    //     </TableHead>
    //     <TableBody>
    //       {events.map((row, index) => (
    //         <Row
    //           key={`events_${index}`}
    //           row={row}
    //           glnName={glnName}
    //           gtinName={gtinName}
    //           isTrackAndTraceClickable={isTrackAndTraceClickable}
    //         />
    //       ))}
    //     </TableBody>
    //   </Table>
    // </TableContainer>

    <>
      {/* Per il desktop */}
      {/* <Hidden smDown>
    <p>LukerView DESKTOP</p>
    </Hidden> */}
      {/* Per il mobile */}
      {/* <Hidden smUp> */}
      <Grid container spacing={2}>
        {/* sort((a, b) => {
      const dateA = new Date(a.eventTime)
      const dateB = new Date(b.eventTime)
      return dateA - dateB
    }) */}
        {eventsComplete.map((event, index) => (
          <Grid item xs={12} key={index}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={`panel${index}bh-header`}
              >
                <Grid container>
                  {/* <Grid item xs={2}>
                    <Link
                     id="myLink"
                     href={event.blockchain?.gno[0]?.publicUrl}
                     //href={row.blockchain[bloc][row.blockchain[bloc].length - 1].publicUrl}
                     target="_blank"
                     underline="none"
                    >
                    <IconButton type="button" variant="contained" style={{ backgroundColor: '#0AF3FF', padding: '1px' }}>
                    <BlockchainIcons blockchain={"gno"} />  
                    </IconButton>
                    </Link>
                </Grid> */}
                  <Grid item xs={7}>
                    {event.name}
                  </Grid>
                  <Grid item xs={4}>
                    {moment(event.eventTime).format('DD/MM/YYYY')}
                  </Grid>
                  {/* <Grid item xs={4}>
                    {glnName[event.location]}
                </Grid>
                <Grid item xs={4}>
                    {glnName[event.sourceLocations[0]]}
                </Grid>
                <Grid item xs={4}>
                    {glnName[event.destinationLocations[0]]}
                </Grid> */}
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={event.bizStep !== 'receiving' ? 6 : 12}
                  >
                    <Grid container sx={{ p: 1 }}>
                      <Grid item xs={12}>
                        {t('materials') + ' Input'}
                      </Grid>
                      <Grid item xs={12}>
                        <Table size="small" aria-label="purchases">
                          <TableBody>
                            <TableRow>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ width: '40%' }}
                              >
                                {t('input')}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {t('lottoInput')}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                align="right"
                              >
                                {t('quantityInput')}
                              </TableCell>
                            </TableRow>
                            {event.inputs.map((e, inputIndex) => (
                              <TableRow key={`inputs_${inputIndex}`}>
                                <TableCell component="th" scope="row">
                                  {isTrackAndTraceClickable ? (
                                    <Link
                                      target="_blank"
                                      href={`${window.location.origin}/01/${e.gtin}`}
                                      variant="body2"
                                      color="inherit"
                                      rel="noreferrer"
                                    >
                                      {/* {gtinName[e.gtin]} */}
                                      {e.metadata?.description}
                                    </Link>
                                  ) : (
                                    // <>{gtinName[e.gtin]}</>
                                    <>{e.metadata?.description}</>
                                  )}
                                </TableCell>
                                <TableCell>
                                  <div>
                                    {e.lotNumber}
                                    <Tooltip title={t('copy')}>
                                      <IconButton
                                        aria-label="copy"
                                        size="small"
                                        onClick={() =>
                                          navigator.clipboard.writeText(
                                            e.lotNumber
                                          )
                                        }
                                      >
                                        <CopyIcon fontSize="inherit" />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  {e.quantity
                                    ? e.quantity + '[' + (e.uom || '-') + ']'
                                    : '-'}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </Grid>
                  {event.bizStep !== 'receiving' && (
                    <Grid item xs={12} sm={6}>
                      <Grid container sx={{ mt: { xs: 3, md: 0 }, p: 1 }}>
                        <Grid item xs={12}>
                          {t('materials') + ' Output'}
                        </Grid>
                        <Grid item xs={12}>
                          <Table size="small" aria-label="purchases">
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ width: '40%' }}
                                >
                                  {t('output')}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {t('lottoOutput')}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="right"
                                >
                                  {t('quantityOutput')}
                                </TableCell>
                              </TableRow>
                              {event.outputs.map((e, inputIndex) => (
                                <TableRow key={`inputs_${inputIndex}`}>
                                  <TableCell component="th" scope="row">
                                    {isTrackAndTraceClickable ? (
                                      <Link
                                        target="_blank"
                                        href={`${window.location.origin}/01/${e.gtin}`}
                                        variant="body2"
                                        color="inherit"
                                        rel="noreferrer"
                                      >
                                        {/* {gtinName[e.gtin]} */}
                                        {e.metadata?.description}
                                      </Link>
                                    ) : (
                                      // <>{gtinName[e.gtin]}</>
                                      <>{e.metadata?.description}</>
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <div>
                                      {e.lotNumber}
                                      <Tooltip title={t('copy')}>
                                        <IconButton
                                          aria-label="copy"
                                          size="small"
                                          onClick={() =>
                                            navigator.clipboard.writeText(
                                              e.lotNumber
                                            )
                                          }
                                        >
                                          <CopyIcon fontSize="inherit" />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </TableCell>
                                  <TableCell size="small" align="right">
                                    {e.quantity
                                      ? e.quantity + '[' + (e.uom || '-') + ']'
                                      : '-'}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>

      {/* </Hidden> */}
    </>
  )
}
