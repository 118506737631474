import React from 'react'
import trackIt from '../../assets/img/logo-trackit.png'

import {
  Box
} from '@mui/material'

const FooterTrackit = () => {
  return (
    <Box className='footerBackground'>
      <Box className='footerLabel'>
        <img style={{ width: '50%' }} src={trackIt} alt='Trackit' />
      </Box>
    </Box>
  )
}

export default FooterTrackit
