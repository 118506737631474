import React from 'react'
import './App.css'
import configuration from './configuration'
import PresentationPage from './views/PresentationPage/PresentationPage.js'
import NotFound from 'components/NotFound/NotFound'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AppProvider } from 'context/AppState'
import preval from 'preval.macro'
import { alog } from 'utils/apioLog'

import './i18n'

const matomo = () => {
  alog('attivo matomo', null, 'config', 'ok')
  const _paq = window._paq || []
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  // _paq.push(['setDocumentTitle', document.domain + '/' + document.title])
  // _paq.push(['setDomains', ['*.']])
  _paq.push(['trackPageView'])
  _paq.push(['enableLinkTracking'])
  // (function () {
  //   var u = 'https://analytics.trusty.id/'
  //   _paq.push(['setTrackerUrl', u + 'matomo.php'])
  //   _paq.push(['setSiteId', '2'])
  //   var d = document; var g = d.createElement('script'); var s = d.getElementsByTagName('script')[0]
  //   g.type = 'text/javascript'; g.async = true; g.defer = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s)
  // })()
}

function App () {
  // controllo se attivare matomo o no
  if (configuration.environment === 'production') {
    matomo()
  }

  // Stampiamo in console la data dell'ultima build dell'app
  const buildDate = preval`
const moment = require('moment')
const buildTimestamp = moment().format('DD/MM/YYYY HH:mm:ss')
module.exports = buildTimestamp
`

  alog('build date', buildDate, 'config', 'ok')
  // console.log(trying to work)
  return (
    <BrowserRouter>
      <AppProvider>
        <Routes>
          <Route path='/gtin/:prod/lot/:lot' exact element={<PresentationPage />} />
          <Route path='/01/:prod/10/:lot' exact element={<PresentationPage />} />
          <Route path='/gtin/:prod' exact element={<PresentationPage />} />
          <Route path='/01/:prod' exact element={<PresentationPage />} />
          <Route path='/01/:prod/22/:version' exact element={<PresentationPage />} />
          {/* //TODO: rimettere questa vista se serve */}
          {/* <Route path='/opentimestamps' exact component={OpentimestampsPage} /> */}
          <Route element={<NotFound />} />
        </Routes>
      </AppProvider>
    </BrowserRouter>
  )
}

export default App
