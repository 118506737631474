import React, { useContext } from 'react'
import { makeStyles } from 'tss-react/mui'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import { Box, Typography, Link, Grid } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'
import RoomIcon from '@mui/icons-material/Room'
// import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Map from 'components/Map/Map'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
import { hideOrShowProperty } from 'utils/utils'
import { AppContext } from '../../context/AppState'
import XdaiStorytelling from './XdaiStorytelling'
import organizationToRules from 'organizationToRules'

const useStyles = makeStyles()(presentationStyle)

function SingleSource({ src }) {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <img
        alt='location-img'
        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
        src={src}
      />
    </div>
  )
}

const inputsOutputsTableDoby = (track, inputs = [], outputs = []) => {
  const array = [
    ...Array(
      !outputs
        ? inputs.length
        : inputs.length >= outputs.length
        ? inputs.length
        : outputs.length
    )
  ]
  const finalArray = []
  array.forEach((el, i) => {
    const newLine = []
    if (inputs && inputs.length > 0 && inputs[i] && track.inputs[i]) {
      newLine[0] = (
        <Grid key={`${track.name}-input-grid-${i.toString()}`}>
          <b>{`${inputs[i].productName}`}</b>
          <br />
        </Grid>
      )
    } else {
      newLine[0] = ''
    }
    if (outputs && outputs[i] && track.outputs[i]) {
      newLine[1] = (
        <Grid key={`${track.name}-output-grid-${i.toString()}`}>
          <b key={i.toString()}>{`${outputs[i].productName} `}</b>
          <br />
        </Grid>
      )
    } else {
      newLine[1] = ''
    }

    finalArray.push(newLine)
  })

  return finalArray.map((elem, i) => {
    return (
      <div key={`${track.name}-elem-div-${i.toString()}`}>
        <div
          key={`${track.name}-input-div-${i.toString()}`}
          className='raleway inputList'
        >
          {elem[0]}
        </div>
        {outputs && outputs.length > 0 && (
          <div
            key={`${track.name}-output-div-${i.toString()}`}
            className='raleway inputList'
          >
            {elem[1]}
          </div>
        )}
      </div>
    )
  })
}

const ProductTraceabilityMobileCard = (props) => {
  const { classes } = useStyles()
  const isSmall = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation('traceabilityCard')

  // const [textSize, setTextSize] = useState('default')
  const {
    track,
    index,
    events,
    glnName,
    gtinName,
    organizationId,
    totalPhases
  } = props

  // stato globale dell'app estrapolato dal context
  const { state, dispatch } = useContext(AppContext)
  const { product, discover, facilityUiidNameMap } = state

  // funzione che apre modal documenti
  //   const handleOpenTrackDoc = (track) => {
  //     dispatch({
  //       type: 'SET_TRACK_DOC',
  //       payload: track
  //     })
  //     dispatch({
  //       type: 'SET_OPEN_TRACK_DOC',
  //       payload: true
  //     })
  //   }

  // funzione che apre modal descrizione traccia
  const handleOpenTrackDesc = (description, name, imgUrl) => {
    dispatch({
      type: 'SET_TRACK_DESC',
      payload: { description, name, imgUrl }
    })
    dispatch({
      type: 'SET_OPEN_TRACK_DESC',
      payload: true
    })
  }
  // const facilityParams = {
  //   limit: Number.MAX_SAFE_INTEGER
  //   // fields: 'name,gln,uuid,geolocation'
  // }

  // funzione che mostra la mappa di una card nella tracciabilità
  const toggleMap = (cardIndex) => {
    const prod = Object.assign({}, product)
    const traceability = prod.traceability
    const { activeSection, ...track } = traceability[cardIndex]
    traceability.splice(cardIndex, 1, {
      activeSection: { card: cardIndex, showMap: !activeSection.showMap },
      ...track
    })
    prod.traceability = traceability
    dispatch({
      type: 'SET_PRODUCT',
      payload: prod
    })
  }

  // funzione che torna il bottone di mostra di più per le descrizioni troncate
  const addShowMoreButton = (id, description, name, imgUrl) => {
    const elem = document.getElementById(id)
    if (elem) {
      if (elem.scrollWidth >= elem.clientWidth) {
        return (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            style={{ width: '100%' }}
          >
            {/* <Link onClick={() => handleOpenTrackDesc(description, name, imgUrl)} style={{ cursor: 'pointer', color: ' #3C4858' }}>
              <AddCircleOutlineIcon />
            </Link> */}
            <Link
              color={'#00A4AE'}
              underline='always'
              onClick={() => handleOpenTrackDesc(description, name, imgUrl)}
            >
              {t('readMore').toLocaleUpperCase() + ' >>'}
            </Link>
          </Box>
        )
      } else {
        return ''
      }
    }
  }

  const getPositionsLocationFromTo = () => {
    const toReturn = []
    track.locationFrom.map((locFrom) =>
      toReturn.push({
        position: [
          facilityUiidNameMap[locFrom].geolocation.lat,
          facilityUiidNameMap[locFrom].geolocation.lng
        ],
        iconUrl: '/marker/marker_red.svg',
        popup: <p>{facilityUiidNameMap[locFrom].name}</p>
      })
    )
    track.locationTo.map((locTo) =>
      toReturn.push({
        position: [
          facilityUiidNameMap[locTo].geolocation.lat,
          facilityUiidNameMap[locTo].geolocation.lng
        ],
        iconUrl: '/marker/marker_green.svg',
        popup: <p>{facilityUiidNameMap[locTo].name}</p>
      })
    )
    return toReturn
  }
  const getMultiPositionsLocationFromTo = () => {
    const toReturn = []
    //const toReturnColor = []
    track.locationFrom.map((locFrom) => {
      track.locationTo.map((locTo) => {
        toReturn.push([
          [
            facilityUiidNameMap[locFrom].geolocation.lat,
            facilityUiidNameMap[locFrom].geolocation.lng
          ],
          [
            facilityUiidNameMap[locTo].geolocation.lat,
            facilityUiidNameMap[locTo].geolocation.lng
          ]
        ])
        //toReturnColor.push(getRandomColor())
        return null
      })
      return null
    })

    return toReturn //{colors:toReturnColor ,positions:toReturn}
  }

  //  track.locationFromName=["altissimo", "pierissimo spa", "pippissimo", "plutissomo oods spa"]
  //  track.locationToName=["altissimo", "pierissimo spa", "pippissimo", "plutissomo oods spa"]

  return (
    <GridItem
      md={6}
      xs={12}
      style={isSmall ? { paddingLeft: 0, paddingRight: 0 } : {}}
    >
      <Card
        style={{
          marginBottom: '40px',
          paddingBottom: 14,
          height: 'auto',
          flexDirection: 'column',
          display: 'flex'
        }}
      >
        <CardHeader style={{ padding: 0 }}>
          {/* FASE */}
          <GridContainer
            style={{ width: '100%', margin: '10px 0', flexDirection: 'column' }}
            className='phasesContainer'
            alignItems='center'
          >
            {/* HEADER FASE */}
            <GridItem
              xs={12}
              md={12}
              style={{ textAlign: 'center', padding: 0 }}
            >
              {/* <span style={{ textAlign: 'center' }} className={`${classes.fredoka} ${classes.cardTitleSmall} ${classes.nameTraceabilityMobile}`}>{index + 1}</span> */}
              <span
                style={{ textAlign: 'center' }}
                className={`${classes.fredoka} ${classes.cardTitleSmall} ${classes.nameTraceabilityMobile}`}
              >
                {t('phaseOfMobile', {
                  phaseNumber: index + 1,
                  phaseTot: totalPhases
                })}
              </span>
            </GridItem>
            <GridItem xs={12} md={12}>
              {/* {textSize === 'default'
              ? <h4 className={clFgasses.phasesName} style={{ fontSize: '1.7em' }}>{track.name}</h4>
              : <h4 className={classes.phasesName} style={{ fontSize: '1.4em' }}>{track.name}</h4>
            } */}
              <h4
                className={classes.phasesName}
                style={{ fontSize: '1.3em', textAlign: 'center' }}
              >
                {track.name}
              </h4>
            </GridItem>
            {/* MAPPE */}
            {/* NOTE: nonostante locationTo e locationFrom siano array, l'accesso al loro contenuto può esser fatto così e non direttamente fornendo l'indice */}
            <GridItem style={{ padding: '0', aspectRatio: '3/2' }} xs={12}>
              {hideOrShowProperty(discover, 'allLocations', 'lot') &&
              track.location &&
              facilityUiidNameMap[track.location] &&
              track.activeSection.showMap ? (
                <div
                  className={`${classes.mapContainer} noImage`}
                  style={{ height: '100%' }}
                >
                  {facilityUiidNameMap[track.location].geolocation?.lat &&
                    facilityUiidNameMap[track.location].geolocation?.lng && (
                      <Map
                        zoom={4}
                        isHeader
                        lat={
                          facilityUiidNameMap[track.location].geolocation.lat
                        }
                        lng={
                          facilityUiidNameMap[track.location].geolocation.lng
                        }
                      />
                    )}
                </div>
              ) : track.locationFrom &&
                track.locationFrom.length &&
                facilityUiidNameMap[track.locationFrom[0]] &&
                track.locationTo &&
                track.locationTo.length &&
                facilityUiidNameMap[track.locationTo[0]] &&
                track.activeSection.showMap ? (
                <div
                  className={`${classes.mapContainer} noImage`}
                  style={{ height: '100%' }}
                >
                  {facilityUiidNameMap[track.locationFrom[0]].geolocation
                    ?.lat &&
                    facilityUiidNameMap[track.locationFrom[0]].geolocation
                      ?.lng &&
                    facilityUiidNameMap[track.locationTo[0]].geolocation?.lat &&
                    facilityUiidNameMap[track.locationTo[0]].geolocation
                      ?.lng && (
                      // <Map isHeader zoom={8} positions={[[facilityUiidNameMap[track.locationFrom[0]].geolocation.lat, facilityUiidNameMap[track.locationFrom[0]].geolocation.lng], [facilityUiidNameMap[track.locationTo[0]].geolocation.lat, facilityUiidNameMap[track.locationTo[0]].geolocation.lng]]} lat={facilityUiidNameMap[track.locationTo[0]].geolocation.lat} lng={facilityUiidNameMap[track.locationTo[0]].geolocation.lng} />}
                      <Map
                        isHeader
                        zoom={5}
                        multiPositions={getMultiPositionsLocationFromTo()}
                        positions={getPositionsLocationFromTo()}
                        lat={
                          facilityUiidNameMap[track.locationTo[0]].geolocation
                            .lat
                        }
                        lng={
                          facilityUiidNameMap[track.locationTo[0]].geolocation
                            .lng
                        }
                      />
                    )}
                </div>
              ) : (
                <div
                  className={classes.mapContainer}
                  style={{ height: '100%' }}
                >
                  {track.media?.length > 0 ? (
                    <SingleSource
                      key={track.media[0].cropped}
                      src={track.media[0].cropped}
                    />
                  ) : null}
                </div>
              )}
              {/* <div className={classes.imgContainer} style={track.media?.length > 0 ? { minHeight: 150, backgroundImage: `url('${track.media[0].cropped}')` } : {}}>
            </div> */}
            </GridItem>
            {hideOrShowProperty(discover, 'allLocations', 'lot') && (
              <GridItem
                style={{
                  marginTop: '-50px',
                  padding: 0,
                  width: '100%',
                  textAlign: 'center'
                }}
                xs={12}
              >
                <>
                  {(facilityUiidNameMap[track.location]?.geolocation?.lat &&
                    facilityUiidNameMap[track.location]?.geolocation?.lng) ||
                  (facilityUiidNameMap[track.locationFrom[0]]?.geolocation
                    ?.lat &&
                    facilityUiidNameMap[track.locationFrom[0]]?.geolocation
                      ?.lng &&
                    facilityUiidNameMap[track.locationTo[0]]?.geolocation
                      ?.lat &&
                    facilityUiidNameMap[track.locationTo[0]]?.geolocation
                      ?.lng) ? (
                    //    <Button
                    //    style={{ backgroundColor: '#00A4AE', zIndex: 1000 }}
                    //    className='mapButton'
                    //    round
                    //    justIcon
                    //    onClick={() => toggleMap(index)}
                    //   //  disabled = {(facilityUiidNameMap[track.location]?.geolocation?.lat && facilityUiidNameMap[track.location]?.geolocation?.lng) ? !(facilityUiidNameMap[track.location]?.geolocation?.lat && facilityUiidNameMap[track.location]?.geolocation?.lng) : !(facilityUiidNameMap[track.locationFrom[0]]?.geolocation?.lat && facilityUiidNameMap[track.locationFrom[0]]?.geolocation?.lng && facilityUiidNameMap[track.locationTo[0]]?.geolocation?.lat && facilityUiidNameMap[track.locationTo[0]]?.geolocation?.lng)}
                    //  >
                    //  <i title={track.activeSection.showMap ? t('showImage') : t('showMap')} className={track.activeSection.showMap ? 'fas fa-image' : 'fas fa-map-marker-alt'} />
                    //  </Button>
                    <Button
                      style={{
                        backgroundColor: '#00A4AE',
                        zIndex: 1000,
                        textTransform: 'none'
                      }}
                      className='mapButton'
                      round
                      //justIcon
                      onClick={() => toggleMap(index)}
                      // disabled = {!(facilityUiidNameMap[track.location]?.geolocation?.lat && facilityUiidNameMap[track.location]?.geolocation?.lng)}
                    >
                      {/* <i title={track.activeSection.showMap ? t('showImage') : t('showMap')} className={track.activeSection.showMap ? 'fas fa-image' : 'fas fa-map-marker-alt'} /> */}
                      <b>
                        {track.activeSection.showMap
                          ? t('showImage')
                          : t('showMap')}
                      </b>
                    </Button>
                  ) : null}
                </>
                {/* <Button
                style={{ backgroundColor: '#00A4AE', zIndex: 1000 }}
                className='mapButton'
                round
                justIcon
                onClick={() => toggleMap(index)}
                disabled = {(facilityUiidNameMap[track.location]?.geolocation?.lat && facilityUiidNameMap[track.location]?.geolocation?.lng) ? !(facilityUiidNameMap[track.location]?.geolocation?.lat && facilityUiidNameMap[track.location]?.geolocation?.lng) : !(facilityUiidNameMap[track.locationFrom[0]]?.geolocation?.lat && facilityUiidNameMap[track.locationFrom[0]]?.geolocation?.lng && facilityUiidNameMap[track.locationTo[0]]?.geolocation?.lat && facilityUiidNameMap[track.locationTo[0]]?.geolocation?.lng)}
               
              >
                <i title={track.activeSection.showMap ? t('showImage') : t('showMap')} className={track.activeSection.showMap ? 'fas fa-image' : 'fas fa-map-marker-alt'} />
              </Button> */}
              </GridItem>
            )}
          </GridContainer>
        </CardHeader>
        <CardBody
          className={classes.cardBodyContainer}
          style={isSmall ? { padding: '0 10px' } : {}}
        >
          {/* LUOGO */}
          {(!organizationToRules[organizationId] ||
            (organizationToRules[organizationId] &&
              !organizationToRules[organizationId].tags)) &&
            (track.location ? (
              <GridContainer
                style={{
                  width: '100%',
                  margin: '10px 0',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <h6
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <RoomIcon style={{ marginBottom: 10, color: '#3CCAE7' }} />
                  <b>{track.locationName}</b>
                </h6>
              </GridContainer>
            ) : track.locationFrom &&
              Array.isArray(track.locationFrom) &&
              track.locationFrom.length > 0 &&
              track.locationTo &&
              Array.isArray(track.locationTo) &&
              track.locationTo.length > 0 ? (
              <>
                <GridContainer
                  style={{ display: 'block', width: '100%', margin: '10px 0' }}
                >
                  <div style={{ width: '50%', float: 'left' }}>
                    <h6
                      style={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '0 10px'
                      }}
                    >
                      <RoomIcon
                        style={{ marginBottom: 10, color: '#E74B3C' }}
                      />
                      {t('from')} <br />
                      {track.locationFrom ? (
                        <ul style={{ paddingLeft: 20 }}>
                          {track.locationFrom?.map((locFrom) => (
                            <li style={{ textAlign: 'left' }}>
                              {facilityUiidNameMap[locFrom]?.name}{' '}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        track.locationFromName
                      )}
                      {/* {Array.isArray(track.locationFromName)? 
                      <ul>
                        {track.locationFromName.map((locNameFrom) => 
                        <li style={{textAlign: "left"}}>
                          {locNameFrom}
                        </li> 
                        )
                        }        
                      </ul>
                      : track.locationFromName} */}
                    </h6>
                  </div>
                  <div style={{ width: '50%', float: 'left' }}>
                    <h6
                      style={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '0 10px'
                      }}
                    >
                      <RoomIcon
                        style={{ marginBottom: 10, color: '#52E73C' }}
                      />
                      {t('to')} <br />
                      {track.locationTo ? (
                        <ul style={{ paddingLeft: 20 }}>
                          {track.locationTo?.map((locTo) => (
                            <li style={{ textAlign: 'left' }}>
                              {facilityUiidNameMap[locTo]?.name}{' '}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        track.locationToName
                      )}
                      {/* {Array.isArray(track.locationToName)? 
                      <ul>
                        {track.locationToName.map((locNameTo) => 
                        <li style={{textAlign: "left"}}>
                          {locNameTo}
                        </li> 
                        )
                        }        
                      </ul>
                      : track.locationToName} */}
                    </h6>
                  </div>
                </GridContainer>

                <GridContainer style={{ width: '100%' }}>
                  <div className='lineContainer'>
                    <span className='dot first' />
                    <span className='line' />
                    <span className='dot last' />
                  </div>
                </GridContainer>
              </>
            ) : (
              ''
            ))}

          {/* MATERIE */}
          <GridContainer style={{ width: '100%' }}>
            {(!organizationToRules[organizationId] ||
              (organizationToRules[organizationId] &&
                !organizationToRules[organizationId].tags)) && (
              <Accordion defaultExpanded='true' className='accordionRoot'>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      paddingLeft: '10%'
                    }}
                    className='fredoka'
                  >
                    {t('usedMaterials')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'block' }}>
                  {inputsOutputsTableDoby(track, track.inputs)}
                </AccordionDetails>
              </Accordion>
            )}
            {track.outputs &&
              track.outputs.length > 0 &&
              (!organizationToRules[organizationId] ||
                (organizationToRules[organizationId] &&
                  !organizationToRules[organizationId].tags)) && (
                <Accordion defaultExpanded='true' className='accordionRoot'>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                  >
                    <Typography
                      style={{
                        textAlign: 'center',
                        width: '100%',
                        paddingLeft: '10%'
                      }}
                      className='fredoka'
                    >
                      {t('producedMaterials')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: 'block' }}>
                    {inputsOutputsTableDoby(track, [], track.outputs)}
                  </AccordionDetails>
                </Accordion>
              )}
          </GridContainer>

          {organizationToRules[organizationId] &&
            organizationToRules[organizationId].tags &&
            events &&
            Array.isArray(events) &&
            events.length > 0 &&
            [events]
              .map((evts) =>
                evts.filter((event) =>
                  event.tagsId.some((evt) => track.tagsId.includes(evt))
                )
              )
              .map((evts) => (
                <XdaiStorytelling
                  events={evts}
                  glnName={glnName}
                  gtinName={gtinName}
                  phaseIndex={index}
                  organizationId={organizationId}
                ></XdaiStorytelling>
              ))}
          {/* DESCRZIONE */}
          {track.description !== '-' && (
            <GridContainer style={{ width: '100%', marginTop: 20 }}>
              <Typography
                id={`${track.name}-${index}`}
                variant='body1'
                noWrap
                style={{
                  marginTop: 0,
                  marginBottom: 8,
                  width: '100%',
                  textAlign: 'center'
                }}
                className={classes.cardDescription}
              >
                {track.description}
              </Typography>
              {track.description.length > 10 &&
                addShowMoreButton(
                  `${track.name}-${index}`,
                  track.description,
                  track.name,
                  track.media?.length > 0 ? track.media[0].cropped : null
                )}
            </GridContainer>
          )}

          {/* DOCUMENTI */}
          {/* {hideOrShowProperty(discover, 'allDocuments', 'lot') && <GridContainer direction="row" justify="flex-end" alignItems="flex-end" style={{ marginTop: 10 }}>
          <GridItem>
            <Button
              color="ctaButton"
              className="attachmentButton"
              disabled={track.documents.length === 0}
              onClick={() => handleOpenTrackDoc(track)}
              style={{ display: track.documents.length === 0 ? 'none' : 'block' }}
            >
              {track.documents.length === 0 ? t('noAttachments') : t('showAttachments')}
            </Button>
          </GridItem>
        </GridContainer>
        } */}
        </CardBody>
      </Card>
    </GridItem>
  )
}

export default ProductTraceabilityMobileCard
