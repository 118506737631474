import React, { useContext } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Box, Typography, Dialog, DialogContent, DialogTitle, DialogContentText } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Input from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'
import { populateLotData, getLightLotsTranslated, someLots } from '../../actions/AppActions'
import { useNavigate } from 'react-router-dom'
import LogoLoader from 'components/LogoLoaderImage/index'
import organizationToRules from 'organizationToRules'
import TraceabilityCollectionsTable from './TraceabilityCollectionsTable'

const useStyles = makeStyles()(presentationStyle)

const TraceabilityInput = () => {
  const { classes } = useStyles()
  const isSmall = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation('traceabilityInput')
  const [isLoading, setIsLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [collections, setCollections] = React.useState([])

  const navigate = useNavigate()
  // stato globale dell'app estrapolato dal context
  const { state, dispatch } = useContext(AppContext)
  const { lotNotFound, product, lotNumber, languageCode } = state
  const organizationId  = product.projectId

  // temporary gtin
  const productUuid = product.resourceId

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const searchLot = async () => {
    if (!lotNumber) {
      return alert('Non è stato inserito nessun lotto')
    }

    try {
      setIsLoading(true)
      let lightLots = await getLightLotsTranslated(productUuid, lotNumber, languageCode)
      if (window.location.pathname.indexOf('lot') === -1) {
        if (window.location.pathname.indexOf('gtin') > -1) {
          navigate(window.location.pathname + `/lot/${lotNumber}`, {
            state: {
              fromProductPage: true
            }
          })
        }
        if (window.location.pathname.indexOf('/01/') > -1) {
          navigate(window.location.pathname + `/10/${lotNumber}`, {
            state: {
              fromProductPage: true
            }
          })
        }
      }
      if (Object.entries(lightLots).length > 0) {
        // if (window.location.pathname.indexOf('lot') === -1) {
        //   history.push(window.location.pathname + `/lot/${lotNumber}`, {
        //     fromProductPage: true
        //   })
        // }
        // const expirationDate = lots.expirationDate
        lightLots = await populateLotData(lightLots)
        dispatch({
          type: 'SET_LIGHT_LOT',
          payload: lightLots
        })
        dispatch({
          type: 'SET_LIGHT_LOT_LOADED',
          payload: true
        })

        dispatch({
          type: 'SET_URL_HAS_LOT',
          payload: true
        })
        setIsLoading(false)
      } else {
        // dispatch({
        //   type: 'SET_LOT_NOT_FOUND',
        //   payload: true
        // })
        dispatch({
          type: 'SET_URL_HAS_LOT',
          payload: true
        })
        setIsLoading(false)
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error && e.response.data.error.statusCode === 404) {
        if (window.location.pathname.indexOf('lot') === -1) {
          if (window.location.pathname.indexOf('gtin') > -1) {
            navigate(window.location.pathname + `/lot/${lotNumber}`, {
              state: {
                fromProductPage: true
              }
            })
          }
          if (window.location.pathname.indexOf('/01/') > -1) {
            navigate(window.location.pathname + `/10/${lotNumber}`, {
              state: {
                fromProductPage: true
              }
            })
          }
        }
        dispatch({
          type: 'SET_URL_HAS_LOT',
          payload: true
        })
      } else {
        dispatch({
          type: 'SET_LOT_NOT_FOUND',
          payload: true
        })
      }
      setIsLoading(false)
    }
  }

  const searchAllCollections = async () => {
      setIsLoading(true)
      // TODO: aggiungere il parametro languageCode quando saranno pronte le API
      let collections = await someLots(productUuid)
      console.log("LightLots: ", collections) 
      setIsLoading(false)
      setCollections(collections)
  }

  const followCollection = async (lotNumber, lightLots) => {
    try {
      setIsLoading(true)
      if (window.location.pathname.indexOf('lot') === -1) {
        if (window.location.pathname.indexOf('gtin') > -1) {
          navigate(window.location.pathname + `/lot/${lotNumber}`, {
            state: {
              fromProductPage: true
            }
          })
        }
        if (window.location.pathname.indexOf('/01/') > -1) {
          navigate(window.location.pathname + `/10/${lotNumber}`, {
            state: {
              fromProductPage: true
            }
          })
        }
      }
      if (Object.entries(lightLots).length > 0) {
        // if (window.location.pathname.indexOf('lot') === -1) {
        //   history.push(window.location.pathname + `/lot/${lotNumber}`, {
        //     fromProductPage: true
        //   })
        // }
        // const expirationDate = lots.expirationDate
        lightLots = await populateLotData(lightLots)
        dispatch({
          type: 'SET_LOT_NUMBER',
          payload: lotNumber
        })
        dispatch({
          type: 'SET_LIGHT_LOT',
          payload: lightLots
        })
        dispatch({
          type: 'SET_LIGHT_LOT_LOADED',
          payload: true
        })

        dispatch({
          type: 'SET_URL_HAS_LOT',
          payload: true
        })
        setIsLoading(false)
      } else {
        // dispatch({
        //   type: 'SET_LOT_NOT_FOUND',
        //   payload: true
        // })
        dispatch({
          type: 'SET_URL_HAS_LOT',
          payload: true
        })
        setIsLoading(false)
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error && e.response.data.error.statusCode === 404) {
        if (window.location.pathname.indexOf('lot') === -1) {
          if (window.location.pathname.indexOf('gtin') > -1) {
            navigate(window.location.pathname + `/lot/${lotNumber}`, {
              state: {
                fromProductPage: true
              }
            })
          }
          if (window.location.pathname.indexOf('/01/') > -1) {
            navigate(window.location.pathname + `/10/${lotNumber}`, {
              state: {
                fromProductPage: true
              }
            })
          }
        }
        dispatch({
          type: 'SET_URL_HAS_LOT',
          payload: true
        })
      } else {
        dispatch({
          type: 'SET_LOT_NOT_FOUND',
          payload: true
        })
      }
      setIsLoading(false)
    }
  }

  // funzione che simula il click su un bottone
  const simulateClick = (e) => {
    dispatch({
      type: 'SET_LOT_NOT_FOUND',
      payload: false
    })
    if (e.keyCode === 13) {
      searchLot()
    }
  }

  // funzione che cambia il lotto mostrato
  const changeLotNumber = (event) => {
    const lot = event.target.value
    dispatch({
      type: 'SET_LOT_NUMBER',
      payload: lot
    })
  }

  return (
    <Box component='div'>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('discoverPosition')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {(product.metadata && product.metadata.lotposition && product.metadata.lotposition.description) ? product.metadata.lotposition.description : ''}
          </DialogContentText>
          {(product.metadata && product.metadata.lotposition && product.metadata.lotposition.url)
            ? <Box
                component='img'
                sx={{
                  height: 233,
                  width: 350,
                  maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: 350, md: 250 }
                }}
                src={product.metadata.lotposition.url}
              />
            : ''}
        </DialogContent>
      </Dialog>
      {(!organizationToRules[organizationId] || (organizationToRules[organizationId] && !organizationToRules[organizationId].tableCollections))
        ?  <Typography align='center' style={{ display: 'block', padding: isSmall ? 8 : '' }}>{t('message')}</Typography>
        : <Typography align='center' style={{ display: 'block', padding: isSmall ? 8 : '' }}>{t('messageCollection')}</Typography>
       }
     
      {(product.metadata && product.metadata.lotposition) 
      ? <Button onClick={handleClickOpen} color='white' style={{ width: '100%', margin: isSmall ? 8 : '' }}>{t('discoverPosition')}</Button> 
      : ''
      }
      {lotNotFound === true
        ? <Box component='div' boxShadow={1} style={{ margin: isSmall ? 8 : '' }} className={classes.warningAlert}>
          <Typography align='center' variant='button'>{t('warning')}</Typography>
          <Typography align='center' style={{ display: 'block' }}>
            {lotNumber !== '' ? t('notFoundMessage', { lotNumber }) : t('notFoundUrlMessage')}
          </Typography>
        </Box>
        : ''}
      <GridContainer direction='column' justify='center' alignItems='center' spacing={2} style={{ width: isSmall ? '100%' : '', margin: isSmall ? 0 : '' }}>
        <GridItem xs={12} md={6} zeroMinWidth>
        {(!organizationToRules[organizationId] || (organizationToRules[organizationId] && !organizationToRules[organizationId].tableCollections))
        && <Input value={lotNumber} formControlProps={{ fullWidth: true }} inputProps={{ onKeyUp: (e) => simulateClick(e), placeholder: t('inputPlaceholder'), onChange: (event) => changeLotNumber(event) }} />
       }
         
        </GridItem>
        <GridItem xs={12} md={6}>
          {isLoading
            ? <LogoLoader />
            : (!organizationToRules[organizationId] || (organizationToRules[organizationId] && !organizationToRules[organizationId].tableCollections))
              ? <Button onClick={() => searchLot()} color='ctaButton' style={{ width: '100%' }}>{t('searchButton')}</Button>
              : collections && collections.length > 0 
               ?
               <GridContainer> 
                <GridItem xs={12} md={12} zeroMinWidth>
                  <TraceabilityCollectionsTable followCollection={followCollection} collections={collections} />
               </GridItem>
               </GridContainer>
                : <Button onClick={() => searchAllCollections()} color='ctaButton' style={{ width: '100%' }}>{t('searchCollectionsButton')}</Button>
              } 
             </GridItem>
      </GridContainer>
    </Box>
  )
}

export default TraceabilityInput
