import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { Typography, Link, Grid } from '@mui/material'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { useTranslation, Trans } from 'react-i18next'
import RegularButton from '../CustomButtons/Button.js'
import VerifyWithTrusty from './VerifyWithTrusty'
import './style.css'
import { domain } from 'serviceWorker'
import {
  getTraceabilityEvents,
  getFacilitiesTraceability,
  getProductsTraceability
} from 'actions/AppActions'
// import LogoLoader from 'components/LogoLoaderImage'
import ProductTraceabilityCard from './ProductTraceabilityCard'
import ProductTraceabilityMobileCard from './ProductTraceabilityMobileCard'
import AttributesLotGrid from 'components/AttributesGrid/AttributesLotGrid'
import { isTrackit } from 'utils/utils'
import organizationToRules from 'organizationToRules'

const trustyLink = (
  <Link
    target='_blank'
    color='primary'
    key='trustyLink'
    href='https://www.trusty.id'
    rel='noreferrer'
  />
)

const trackitLink = (
  <Link
    target='_blank'
    color='primary'
    key='trackitLink'
    href='https://marketing.trackit.trusty.id'
    rel='noreferrer'
  />
)

const strongType = <strong key='strongType' />
const cursive = <em key='cursive' />

const RenderRightTraceability = (props) => {
  const {
    urlHasLot,
    company,
    isSmall,
    currLot,
    companyLink,
    product,
    lightLot
  } = props
  const { prod, lot } = useParams()
  const [open, setOpen] = React.useState(false)
  const [isCalledBy, setIsCalledBy] = React.useState('lots')
  const [isClassicLotFromMarketing, setIsClassicLotFromMarketing] =
    React.useState(false)
  const [isLightLotFromMarketing, setIsLightLotFromMarketing] =
    React.useState(false)
  const [itHasEvents, setItHasEvents] = React.useState(false)
  const [facilities, setFacilities] = React.useState([])
  const [products, setProducts] = React.useState([])
  const [glnName, setGlnName] = React.useState({})
  const [gtinName, setGtinName] = React.useState({})
  const [events, setEvents] = React.useState([])
  const { t } = useTranslation('traceability')
  const shouldSetLotFromMarketing = currLot && Object.keys(currLot).length > 0 // devo caricare le informazioni del lotto che mi proviene dal marketing
  const shouldSetLightLotFromMarketing =
    lightLot && Object.keys(lightLot).length > 0 // devo caricare le informazioni del lotto che mi proviene dal marketing
  const shouldProductReady = product && Object.keys(product).length > 0
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const facilityParams = {
    limit: Number.MAX_SAFE_INTEGER
    // fields: 'name,gln,uuid'
  }

  const productParams = {
    limit: Number.MAX_SAFE_INTEGER
    // fields: 'name,gtin,uuid'
  }

  // NOTE: gestire la casistica in cui vengano trovati sia light lot che lot normali (in teoria, per come è costruita la visualizzazione viene mostrato il lotto)

  const dynamicDescription = () => {
    return isTrackit(domain) ? (
      <Trans i18nKey='descriptionTraceabilityTrackit'>
        {[trackitLink, strongType, cursive, companyLink, strongType]}
      </Trans>
    ) : (
      <Trans i18nKey='descriptionTraceability'>
        {[trustyLink, strongType, cursive, companyLink, strongType]}
      </Trans>
    )
  }

  useEffect(() => {
    if (shouldSetLotFromMarketing) {
      setIsClassicLotFromMarketing(true)
    } else {
      // eslint-disable-next-line no-inner-declarations
      async function fetchEvents() {
        // MODIFICATO PER LUKER INIZIO
        // const events = await getTraceabilityEvents(prod, lightLot.lotNumber)
        // setEvents(events)
        let events
        console.log('FETCHEVENTS', product, lightLot)
        if (lightLot?.projectId === '7f214744-3871-4b45-b9aa-fd63126c8318') {
          if (lightLot.productId === '24efb372-6499-4ecc-85c7-105650c39c46') {
            events = await getTraceabilityEvents(
              'e365f71b-0651-4ed2-9994-59b5fd425877-p00002389',
              '9900216126'
            )
          } else if (
            lightLot.productId === 'fc06acd0-69ff-443a-baa9-9b57ed009a5f'
          ) {
            events = await getTraceabilityEvents(
              'e365f71b-0651-4ed2-9994-59b5fd425877-p00000821',
              '9900211528'
            )
          }
        } else {
          events = await getTraceabilityEvents(prod, lightLot.lotNumber)
        }
        setEvents(events)
        // FINE MODIFICHE LUKER
      }
      async function fetchFacilities() {
        let allFacilities
        console.log('company:::', lightLot.projectId)
        const facilities = await getFacilitiesTraceability(
          lightLot.projectId,
          facilityParams
        )
        if (lightLot.projectId === '461aab09-b719-43ae-b970-4e7ca1498d53') {
          const supplyChainFacilities = await getFacilitiesTraceability(
            '29728a30-c59c-4ef6-af54-463fede397a7',
            facilityParams
          )
          allFacilities = [...facilities, ...supplyChainFacilities]
        } else {
          allFacilities = facilities
        }
        setFacilities(allFacilities)
        const mapFac = {}
        allFacilities.forEach((x) => {
          mapFac[x.gln] = x.name
        })
        setGlnName(mapFac)
      }
      // eslint-disable-next-line no-inner-declarations
      async function fetchProducts() {
        let allProducts
        const products = await getProductsTraceability(
          lightLot.projectId,
          productParams
        )
        if (lightLot.projectId === '461aab09-b719-43ae-b970-4e7ca1498d53') {
          const supplychainProducts = await getProductsTraceability(
            '29728a30-c59c-4ef6-af54-463fede397a7',
            productParams
          )
          allProducts = [...products, ...supplychainProducts]
        } else {
          allProducts = products
        }
        setProducts(allProducts)
        const mapProd = {}
        allProducts.forEach((x) => (mapProd[x.gtin] = x.name))
        setGtinName(mapProd)
      }
      if (shouldSetLightLotFromMarketing) {
        setIsLightLotFromMarketing(true)
      }
      fetchEvents()
      fetchFacilities()
      fetchProducts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSetLotFromMarketing, shouldSetLightLotFromMarketing])

  useEffect(() => {
    console.log('Light lots', lightLot)
    if (events.length > 0) {
      setIsCalledBy('events')
      setItHasEvents(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events])

  // NOTE: se urlHasLot allora devo poter caricare un componente che vede se il lotto è di mktg o di traceability
  return (
    <>
      {open && (
        <VerifyWithTrusty
          company={company}
          open={open}
          onClose={handleClose}
          isCalledBy={isCalledBy}
          events={events}
          facilities={facilities}
          products={products}
        />
      )}
      {!(
        organizationToRules[product.projectId] &&
        organizationToRules[product.projectId].traceabilityRenderRight
      ) &&
        itHasEvents &&
        urlHasLot && (
          <GridContainer
            style={{ padding: '30px', margin: 'auto', maxWidth: '940px' }}
          >
            <GridItem xs={12} md={9}>
              <Typography className='descriptionSentences raleway'>
                {dynamicDescription()}
              </Typography>
            </GridItem>
            <GridItem xs={12} md={3}>
              <RegularButton
                color='ctaButton'
                onClick={handleClickOpen}
                round
                style={{ width: '100%' }}
                className='verifyButton'
              >
                {t('discover')}
              </RegularButton>
            </GridItem>
            {lightLot?.projectId === '7f214744-3871-4b45-b9aa-fd63126c8318' && (
              <GridItem xs={12} md={9}>
                <Typography className='raleway'>
                  All the associations are of small producers, non-profit that
                  functions as a solidarity economy group, which are formed to
                  market cocoa and facilitate the management of projects and
                  activities for the development of the cocoa community, with
                  which Luker has a long-term relationship. Asoprocar: has
                  approximately 130 cocoa producers. Corcacao: has approximately
                  100 cocoa producers associated with it. Asprocat: is an
                  association from Tumaco.
                </Typography>
              </GridItem>
            )}
          </GridContainer>
        )}
      {isLightLotFromMarketing ? (
        isSmall ? ( // NOTE: casistica in cui ci sono gli eventi
          <>
            {shouldSetLightLotFromMarketing && (
              <AttributesLotGrid lightLot={lightLot} />
            )}
            {shouldProductReady &&
              product.traceability.map((track, index) => {
                return (
                  <>
                    <ProductTraceabilityMobileCard
                      organizationId={product.projectId} // serve per filtrare sulle facilities
                      index={index}
                      track={track}
                      key={`${track.name}-${index}`}
                      events={events}
                      glnName={glnName}
                      gtinName={gtinName}
                      totalPhases={product.traceability.length}
                    />
                  </>
                )
              })}
          </>
        ) : (
          <Grid container spacing={3} className='vittorio'>
            {/* <Box component="div" className={classes.masonryWrapper} style={{ maxHeight: calcCardHeight(currLot.traceability) }}> */}
            {shouldSetLightLotFromMarketing && (
              <AttributesLotGrid lightLot={lightLot} />
            )}
            {shouldProductReady &&
              product.traceability.map((track, index) => {
                return (
                  <>
                    <ProductTraceabilityCard
                      organizationId={product.projectId} // serve per filtrare sulle facilities
                      index={index}
                      track={track}
                      key={`${track.name}-${index}`}
                      events={events}
                      glnName={glnName}
                      gtinName={gtinName}
                      totalPhases={product.traceability.length}
                    />
                  </>
                )
              })}
          </Grid>
        )
      ) : isSmall ? ( // NOTE: casistica in cui non ci sono gli eventi
        shouldProductReady &&
        product.traceability.map((track, index) => {
          return (
            <ProductTraceabilityMobileCard
              organizationId={product.projectId} // serve per filtrare sulle facilities
              index={index}
              track={track}
              key={`${track.name}-${index}`}
              totalPhases={product.traceability.length}
            />
          )
        })
      ) : (
        <Grid container spacing={3} className='vittorio'>
          {/* <Box component="div" className={classes.masonryWrapper} style={{ maxHeight: calcCardHeight(currLot.traceability) }}> */}
          {shouldProductReady &&
            product.traceability.map((track, index) => {
              return (
                <>
                  <ProductTraceabilityCard
                    organizationId={product.projectId} // serve per filtrare sulle facilities
                    index={index}
                    track={track}
                    key={`${track.name}-${index}`}
                    totalPhases={product.traceability.length}
                  />
                </>
              )
            })}
        </Grid>
      )}
      {organizationToRules[product.projectId] &&
        organizationToRules[product.projectId].traceabilityRenderRight &&
        itHasEvents && (
          <GridContainer
            style={{ padding: '30px', margin: 'auto', maxWidth: '940px' }}
          >
            <GridItem xs={12} md={9}>
              <Typography className='descriptionSentences raleway'>
                {dynamicDescription()}
              </Typography>
            </GridItem>
            <GridItem xs={12} md={3}>
              <RegularButton
                color='ctaButton'
                onClick={handleClickOpen}
                round
                style={{ width: '100%' }}
                className='verifyButton'
              >
                {t('discover')}
              </RegularButton>
            </GridItem>
          </GridContainer>
        )}
    </>
  )
  // : <LogoLoader></LogoLoader>
}

export default RenderRightTraceability
