import React, { useContext } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon
} from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Box,
  Collapse,
  Link,
  Tooltip
} from '@mui/material'
import CopyIcon from '@mui/icons-material/ContentCopy'
import BlockchainIcons from 'components/Blockchain/BlockchainIcons'
import { AppContext } from '../../context/AppState'

const useStyles = makeStyles()(presentationStyle)

function Row(props) {
  const { row, glnName, gtinName, isTrackAndTraceClickable } = props
  const { t } = useTranslation('xdaiHeader')
  // const preventDefault = (event) => event.preventDefault()
  const [open, setOpen] = React.useState(false)
  const { classes } = useStyles()
  const shouldContainerBlockchainObject =
    row.blockchain && Object.keys(row.blockchain).length > 0
  console.log('ROW', row)

  const handleClickViewFile = (e, document) => {
    e.preventDefault()
    window.open(document.fileUrl)
  }
  return (
    <>
      <TableRow className={classes.rootTable}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {shouldContainerBlockchainObject &&
            Object.keys(row.blockchain).map(
              (bloc, index) => (
                <Link
                  key={`index_${index}`}
                  target="_blank"
                  href={
                    row.blockchain[bloc][row.blockchain[bloc].length - 1]
                      .publicUrl
                  }
                  rel="noreferrer"
                >
                  {shouldContainerBlockchainObject ? (
                    <BlockchainIcons blockchain={bloc} />
                  ) : (
                    ''
                  )}
                </Link>
              )
              // row.blockchain[bloc][row.blockchain.length - 1].publicUrl
            )}
        </TableCell>
        <TableCell align="right" component="th" scope="row">
          {row.name}
        </TableCell>

        <TableCell align="right" component="th" scope="row">
          {/* {t(`eventType.${row.eventType}`)} */}
          {row.eventType === 'uploading_file' ? (
            <>
              {t(`eventType.${row.eventType}`)}
              {row.documents?.map((document, i) => (
                <div>
                  {/* <Link
                    key={`file_uploading_${i}`}
                    target="_blank"
                    href={document.fileUrl}
                    rel="noreferrer"
                  >
                    {'upload_file_' + (i + 1)}
                  </Link> */}
                  <Link
                    key={`file_uploading_${i}`}
                    target="_blank"
                    href={document.fileUrl}
                    rel="noreferrer"
                    onClick={(e) => {
                      handleClickViewFile(e, document)
                    }}
                  >
                     {document.originalFilename || 'upload_file_' + (i + 1)}
                  </Link>
                </div>
              ))}
            </>
          ) : (
            t(`eventType.${row.eventType}`)
          )}
        </TableCell>
        <TableCell align="right">{t(`bizStep.${row.bizStep}`)}</TableCell>
        <TableCell align="right">
          {row.eventTime
            ? moment(row.eventTime).format('DD/MM/YYYY')
            : moment().format('DD/MM/YYYY')}
        </TableCell>
        <TableCell align="right">{glnName[row.location]}</TableCell>
        <TableCell align="right">{glnName[row.sourceLocations[0]]}</TableCell>
        <TableCell align="right">
          {glnName[row.destinationLocations[0]]}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, width:"100%" }}>
              <Typography variant="h6" gutterBottom component="div">
                {t('materials')}
              </Typography>
              <Box display={"flex"} allignItems="center" sx={{}}>
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {t('input')}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {t('lottoInput')}
                      </TableCell>
                      <TableCell component="th" scope="row" align="right">
                        {t('quantityInput')}
                      </TableCell>
                      <TableCell component="th" scope="row" align="right">
                        UOM
                      </TableCell>
                    </TableRow>
                    {row.inputs.map((e, inputIndex) => (
                      <TableRow key={`inputs_${inputIndex}`}>
                        <TableCell component="th" scope="row">
                          {isTrackAndTraceClickable ? (
                            <Link
                              target="_blank"
                              href={`${window.location.origin}/01/${e.gtin}`}
                              variant="body2"
                              color="inherit"
                              rel="noreferrer"
                            >
                              {gtinName[e.gtin]}
                            </Link>
                          ) : (
                            <>{t(`gtin.${e.gtin}`, gtinName[e.gtin])}</>
                          )}
                        </TableCell>
                        <TableCell>
                          <div>
                            {e.lotNumber}
                            <Tooltip title={t('copy')}>
                              <IconButton
                                aria-label="copy"
                                size="small"
                                onClick={() =>
                                  navigator.clipboard.writeText(e.lotNumber)
                                }
                              >
                                <CopyIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </TableCell>
                        <TableCell align="right">{e.quantity}</TableCell>
                        <TableCell align="right">{e.uom}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {t('output')}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {t('lottoOutput')}
                      </TableCell>
                      <TableCell component="th" scope="row" align="right">
                        {t('quantityOutput')}
                      </TableCell>
                      <TableCell component="th" scope="row" align="right">
                        UOM
                      </TableCell>
                    </TableRow>
                    {row.outputs.map((e, outputIndex) => (
                      <TableRow key={`outputs_${outputIndex}`}>
                        <TableCell component="th" scope="row">
                          {isTrackAndTraceClickable ? (
                            <Link
                              target="_blank"
                              href={`${window.location.origin}/01/${e.gtin}`}
                              variant="body2"
                              color="inherit"
                              rel="noreferrer"
                            >
                              {gtinName[e.gtin]}
                            </Link>
                          ) : (
                            <>{t(`gtin.${e.gtin}`, gtinName[e.gtin])}</>
                          )}
                        </TableCell>
                        <TableCell>
                          <div>
                            {e.lotNumber}
                            <Tooltip title={t('copy')}>
                              <IconButton
                                aria-label="copy"
                                size="small"
                                onClick={() =>
                                  navigator.clipboard.writeText(e.lotNumber)
                                }
                              >
                                <CopyIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </TableCell>
                        <TableCell align="right">{e.quantity}</TableCell>
                        <TableCell align="right">{e.uom}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {row.eventType === 'uploading_file' &&
                  <Button  color='ctaButton' style={{height:"100%", margin:"auto", borderRadius:"50px", marginLeft:20}} 
                    onClick={(e) => {handleClickViewFile(e)}}>
                    {t("view", "View")}
                    </Button>
                }
              </Box>
             
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default function XdaiTable({ events, glnName, gtinName }) {
  const { t } = useTranslation('xdaiHeader')
  const { state } = useContext(AppContext)
  const { product } = state
  const isTrackAndTraceClickable = product.metadata?.isTrackAndTraceClickable
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{t('blockchain')}</TableCell>
            <TableCell align="right">{t('name')}</TableCell>
            <TableCell align="right">{t('eventType.title')}</TableCell>
            <TableCell align="right">{t('bizStep.title')}</TableCell>
            <TableCell align="right">{t('eventTime')}</TableCell>
            <TableCell align="right">{t('location')}</TableCell>
            <TableCell align="right">{t('locationFrom')}</TableCell>
            <TableCell align="right">{t('locationTo')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((row, index) => (
            <Row
              key={`events_${index}`}
              row={row}
              glnName={glnName}
              gtinName={gtinName}
              isTrackAndTraceClickable={isTrackAndTraceClickable}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
