import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery'
import { makeStyles } from 'tss-react/mui'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js'
import Avatar from '@mui/material/Avatar'
import MenuDesktop from 'components/Menu/MenuDesktop'
import Dropdown from 'components/CustomDropdown/CustomDropdown.js'
import LanguageIcon from '@mui/icons-material/Language'

const useStyles = makeStyles()(presentationStyle)

export default function MyAppBar({company, languageSelected, selectLanguage, dropdownListLanguage,  isFashion}) {
  const { classes } = useStyles()
  const isSmall = useMediaQuery('(max-width: 600px)')
  const isMedium = useMediaQuery('(max-width: 960px)')
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar style= {{backgroundColor:"#FAFAFA"}} color="inherit" position="fixed" sx={{zIndex:999, height:"70px"}}>
        <Toolbar>
        <div style={{ position: isSmall ? 'fixed' : 'absolute', top: 5, left: isSmall ? '20px' : '40px', zIndex: '999' }}>
            {(company && company.logo && company.logo.original)
              ? (company.website
                  ? <>
                    <a href={`${company.website}`} target='_blank' rel='noopener noreferrer'>
                      <Avatar className={classes.roundedAvatar} alt={`${company.name} logo`} src={`${company.logo.original}`} />
                    </a>
                    </>
                  : <Avatar className={classes.roundedAvatar} alt={`${company.name} logo`} src={`${company.logo.original}`} />
                )
              : null}
          </div>
          <div style={{ position: isSmall ? 'fixed' : 'absolute', top: 5, right: isMedium ? '20px' : '40px', zIndex: '999' }}>
          <Dropdown
            buttonText={isMedium ? "" : languageSelected}
            className='fredoka'
            buttonProps={{
              color: 'ctaButton',
              startIcon: <LanguageIcon />
            }}
            dropdownList={dropdownListLanguage}
            onClick={(e) => selectLanguage(e)}
          />
        </div>
           <MenuDesktop isFashion={isFashion} /> 

        </Toolbar>
      </AppBar>
    </Box>
  );
}