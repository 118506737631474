/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
// import { makeStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { AppContext } from '../../context/AppState'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'
import './style.css'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { currLang } from '../../utils/translations'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(presentationStyle)

const IngredientsSection = () => {
  const { classes } = useStyles()
  const { state } = useContext(AppContext)
  const { product } = state
  const [rawMaterialsIngredients, setRawMaterialsIngredients] = useState([])
  const [acidityRegulatorsIngredients, setAcidityRegulatorsIngredients] =
    useState([])
  const [preservativesIngredients, setPreservativesIngredients] = useState([])
  const [antioxidantsIngredients, setAntioxidantsIngredients] = useState([])
  const [agentsStabilisingIngredients, setAgentsStabilisingIngredients] =
    useState([])
  const [gassesIngredients, setGassesIngredients] = useState([])
  const [othersIngredients, setOthersIngredients] = useState([])
  const [othersCategoryIngredients, setOthersCategoryIngredients] = useState([])
  const [isBottledInProtectiveAtmosphere, setIsBottledInProtectiveAtmosphere] =
    useState(false)
  const [isRawMaterialTitleHide, setIsRawMaterialTitleHide] = useState(false)
  const [order, setOrder] = useState([
    'rawMaterials',
    'acidityRegulators',
    'preservatives',
    'antioxidants',
    'agentsStabilising',
    'gasses',
    'others',
    'othersIngredients'
  ])
  const rawMaterials = [
    'grapes',
    'concentratedGrapeMust',
    'sucrose',
    'tirageLiqueur',
    'expeditionLiqueur'
  ]
  const acidityRegulators = [
    'tartaricAcid',
    'malicAcid',
    'lacticAcid',
    'calciumSulphate',
    'calciumTartrate',
    'citricAcidAcidityRegulators'
  ]
  const preservatives = [
    'sulphurDioxide2',
    'potassiumBisulphite',
    'potassiumMetabisulphite',
    'potassiumSorbate',
    'lysozyme',
    'lAscorbicAcid',
    'dimethyldicarbonate',
    'sulphites'
  ]
  const antioxidants = [
    'sulphurDioxide3',
    'potassiumBisulphite2',
    'potassiumMetabisulphite2',
    'potassiumSorbate2',
    'lysozyme2',
    'lAscorbicAcid2',
    'dimethyldicarbonate2',
    'sulphites2'
  ]
  const agentsStabilising = [
    'citricAcid',
    'metatartaricAcid',
    'gumArabic',
    'yeastMannoproteins',
    'carboxymethylcellulose',
    'potassiumPolyaspartate',
    'fumaricAcid'
  ]
  const gasses = ['argon', 'nitrogen', 'carbonDioxide']
  const others = [
    'aleppoPineResin',
    'freshLees',
    'caramel',
    'allylIsothiocyanate',
    'inactivatedYeasts'
  ]
  const otherCategory = [
    'potassiumL-tartrate',
    'potassiumBicarbonate',
    'calciumCarbonate',
    'potassiumCarbonate',
    'charcoalForOenologicalUse',
    'selectiveVegetalFibres',
    'microcrystallineCellulose',
    'diammoniumHydrogenPhosphate',
    'ammoniumSulphate',
    'ammoniumBisulphite',
    'thiamineHydrochloride',
    'yeastAutolysates',
    'yeastCellWalls',
    'inactivatedYeasts',
    'inactivatedYeastsWithGuaranteedGlutathioneLevels',
    'edibleGelatine',
    'wheatProtein',
    'peasProtein',
    'potatoesProtein',
    'isinglass',
    'casein',
    'potassiumCaseinates',
    'eggAlbumin',
    'bentonite',
    'siliconDioxide',
    'kaolin',
    'tannins',
    'chitosanDerivedFromAspergillusNiger',
    'chitin-glucanDerivedFromAspergillusNiger',
    'yeastProteinExtracts',
    'polyvinylpolypyrrolidone',
    'calciumAlginate',
    'potassiumAlginate',
    'potassiumHydrogenTartrate',
    'calciumTartrate',
    'urease',
    'pectinLyases',
    'pectinMethylesterase',
    'polygalacturonase',
    'hemicellulase',
    'cellulase',
    'betaglucanase',
    'glycosidase',
    'tannins',
    'potassiumFerrocyanide',
    'calciumPhytate',
    'tartaricAcidD-l-OrItsNeutralSaltOfPotassium',
    'polyvinylimidazole-polyvinylpyrrolidoneCopolymers',
    'gaseousOxygen',
    'yeastsForWineProduction',
    'lacticAcidBacteria',
    'copperSulphatePentahydrate',
    'copperCitrate',
    'chitosanDerivedFromAspergillusNiger',
    'chitin-glucanDerivedFromAspergillusNiger',
    'inactivatedYeasts',
    'water',
    'flavour',
    'naturalFlavour',
    'sulphurDioxide'
  ]
  const { t } = useTranslation('ingredients')
  let rawMaterialsIngredientsTemp = []
  let acidityRegulatorsIngredientsTemp = []
  let preservativesIngredientsTemp = []
  let antioxidantsIngredientsTemp = []
  let agentsStabilisingIngredientsTemp = []
  let gassesIngredientsTemp = []
  let othersIngredientsTemp = []
  let otherCategoryIngredientsTemp = []
  let isBottledInProtectiveAtmosphereTemp
  let isRawMaterialTitleHideTemp
  let orderTemp = []

  useEffect(() => {
    /*for (const el of product.attributes) {
      if (el.name.includes('<VN>')) {
        values.push(el)
      }
    }*/
    if (product.ingredients) {
      rawMaterialsIngredientsTemp = product.ingredients?.values
        .filter((el) => rawMaterials.includes(el))
        .map((x) => t(`components.${x}`))
        .reduce((prev, curr, index) => {
          return index !== rawMaterialsIngredientsTemp.length - 1
            ? [...prev, curr, ', ']
            : [...prev, curr]
        }, [])
      rawMaterialsIngredientsTemp.pop() // remove last comma

      acidityRegulatorsIngredientsTemp = product.ingredients?.values
        .filter((el) => acidityRegulators.includes(el))
        .map((x) => t(`components.${x}`))
        .reduce((prev, curr, index) => {
          return index !== acidityRegulatorsIngredientsTemp.length - 1
            ? [...prev, curr, ` ${t('andOr')} `]
            : [...prev, curr]
        }, [])
      acidityRegulatorsIngredientsTemp.pop() // remove last andOr
      // eslint-disable-next-line react-hooks/exhaustive-deps
      preservativesIngredientsTemp = product.ingredients?.values
        .filter((el) => preservatives.includes(el))
        .map((x) => {
          if (
            x === 'lysozyme' ||
            x === 'potassiumBisulphite' ||
            x === 'sulphurDioxide2' ||
            x === 'potassiumMetabisulphite' ||
            x === 'sulphites'
          ) {
            return <b>{t(`components.${x}`)}</b>
          } else {
            return t(`components.${x}`)
          }
        })
        //.join(` ${t('andOr')} `)
        .reduce((prev, curr, index) => {
          return index !== preservativesIngredientsTemp.length - 1
            ? [...prev, curr, ', ']
            : [...prev, curr]
        }, [])
      preservativesIngredientsTemp.pop() // remove last comma

      antioxidantsIngredientsTemp = product.ingredients?.values
        .filter((el) => antioxidants.includes(el))
        .map((x) => {
          if (
            x === 'lysozyme2' ||
            x === 'potassiumBisulphite2' ||
            x === 'sulphurDioxide3' ||
            x === 'potassiumMetabisulphite2' ||
            x === 'sulphites2'
          ) {
            return <b>{t(`components.${x}`)}</b>
          } else {
            return t(`components.${x}`)
          }
        })
        //.join(` ${t('andOr')} `)
        .reduce((prev, curr, index) => {
          return index !== antioxidantsIngredientsTemp.length - 1
            ? [...prev, curr, ', ']
            : [...prev, curr]
        }, []) // remove last comma
      antioxidantsIngredientsTemp.pop()

      agentsStabilisingIngredientsTemp = product.ingredients?.values
        .filter((el) => agentsStabilising.includes(el))
        .map((x) => t(`components.${x}`))
        .reduce((prev, curr, index) => {
          return index !== agentsStabilisingIngredientsTemp.length - 1
            ? [...prev, curr, ` ${t('andOr')} `]
            : [...prev, curr]
        }, []) // remove last andOr
      agentsStabilisingIngredientsTemp.pop()

      gassesIngredientsTemp = product.ingredients?.values
        .filter((el) => gasses.includes(el))
        .map((x) => t(`components.${x}`))
        .reduce((prev, curr, index) => {
          return index !== gassesIngredientsTemp.length - 1
            ? [...prev, curr, ', ']
            : [...prev, curr]
        }, [])
      gassesIngredientsTemp.pop() // remove last comma

      othersIngredientsTemp = product.ingredients?.values
        .filter((el) => others.includes(el))
        .map((x) => t(`components.${x}`))
        .reduce((prev, curr, index) => {
          return index !== othersIngredientsTemp.length - 1
            ? [...prev, curr, ', ']
            : [...prev, curr]
        }, [])
      othersIngredientsTemp.pop() // remove last comma

      otherCategoryIngredientsTemp = product.ingredients?.values
        .filter((el) => otherCategory.includes(el))
        .map((x) => t(`components.${x}`))
        .reduce((prev, curr, index) => {
          return index !== otherCategoryIngredientsTemp.length - 1
            ? [...prev, curr, ', ']
            : [...prev, curr]
        }, [])
      otherCategoryIngredientsTemp.pop() // remove last comma

      isBottledInProtectiveAtmosphereTemp =
        product.ingredients.metadata?.isBottledInProtectiveAtmosphere
      isRawMaterialTitleHideTemp = product.metadata?.isRawMaterialTitleHide
      orderTemp = product.ingredients.metadata?.order?.length
        ? product.ingredients.metadata?.order
        : order
    }

    console.log('ORDER TEMP:::::', orderTemp)
    const ingredients = {
      rawMaterials: rawMaterialsIngredientsTemp,
      acidityRegulators: acidityRegulatorsIngredientsTemp,
      preservatives: preservativesIngredientsTemp,
      agentsStabilising: agentsStabilisingIngredientsTemp,
      preservativesAndAntioxidants: [], // NOTE: questo è solo per backward compatibility con una vecchia impostazione. Prima di rimuovere, sistemare su db i dati sporchi
      antioxidants: antioxidantsIngredientsTemp,
      gasses: gassesIngredientsTemp,
      others: othersIngredientsTemp,
      othersIngredients: otherCategoryIngredientsTemp
    }

    const filteredOrder = orderTemp.filter(
      (category) => ingredients[category].length > 0
    )
    setRawMaterialsIngredients(rawMaterialsIngredientsTemp)
    setAcidityRegulatorsIngredients(acidityRegulatorsIngredientsTemp)
    setPreservativesIngredients(preservativesIngredientsTemp)
    setAntioxidantsIngredients(antioxidantsIngredientsTemp)
    setAgentsStabilisingIngredients(agentsStabilisingIngredientsTemp)
    setGassesIngredients(gassesIngredientsTemp)
    setOthersIngredients(othersIngredientsTemp)
    setOthersCategoryIngredients(otherCategoryIngredientsTemp)
    setIsBottledInProtectiveAtmosphere(isBottledInProtectiveAtmosphereTemp)
    setIsRawMaterialTitleHide(isRawMaterialTitleHideTemp)
    setOrder(filteredOrder)

    console.log('ORDER TEMP:::::', orderTemp)
    // eslint-disable-next-line
  }, [currLang])

  const isSmall = useMediaQuery('(max-width: 600px)')

  return (
    <div
      style={Object.assign(
        {},
        presentationStyle.ingredientContainer,
        isSmall ? { marginTop: '16px' } : { background: 'white' }
      )}
    >
      {product.ingredients?.values &&
        product.ingredients?.values.length > 0 && (
          <>
            <Typography
              variant='h5'
              component='p'
              style={{ color: '#3C4858', margin: '0px 0 20px 0' }}
              className={classes.fredoka}
            >
              {t(`title`)}
            </Typography>
            {order.map((el, index) => {
              switch (el) {
                case 'rawMaterials': {
                  let rawMaterialsIngredientsWithComma = []
                  if (parseInt(index) !== order.length - 1) {
                    if (rawMaterialsIngredients.length > 0) {
                      rawMaterialsIngredientsWithComma = rawMaterialsIngredients
                      rawMaterialsIngredientsWithComma.push(',')
                    }
                  } else {
                    if (rawMaterialsIngredients.length > 0) {
                      rawMaterialsIngredientsWithComma = rawMaterialsIngredients
                      rawMaterialsIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    rawMaterialsIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {!isRawMaterialTitleHide
                          ? `${t(`category.rawMaterials`)}: `
                          : ''}
                        {rawMaterialsIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'acidityRegulators': {
                  let acidityRegulatorsIngredientsWithComma = []
                  if (parseInt(index) !== order.length - 1) {
                    if (acidityRegulatorsIngredients.length > 0) {
                      acidityRegulatorsIngredientsWithComma =
                        acidityRegulatorsIngredients
                      acidityRegulatorsIngredientsWithComma.push(';')
                    }
                  } else {
                    if (acidityRegulatorsIngredients.length > 0) {
                      acidityRegulatorsIngredientsWithComma =
                        acidityRegulatorsIngredients
                      acidityRegulatorsIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    acidityRegulatorsIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t(`category.acidityRegulators`)}:{' '}
                        {acidityRegulatorsIngredientsWithComma.length > 2
                          ? `${t(`contain`)} `
                          : ''}
                        {acidityRegulatorsIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'preservatives': {
                  let preservativesIngredientsWithComma = []
                  if (parseInt(index) !== order.length - 1) {
                    if (preservativesIngredients.length > 0) {
                      preservativesIngredientsWithComma =
                        preservativesIngredients
                      preservativesIngredientsWithComma.push(';')
                    }
                  } else {
                    if (preservativesIngredients.length > 0) {
                      preservativesIngredientsWithComma =
                        preservativesIngredients
                      preservativesIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    preservativesIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t(`category.preservatives`)}:{' '}
                        {preservativesIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'antioxidants': {
                  let antioxidantsIngredientsWithComma = []
                  if (parseInt(index) !== order.length - 1) {
                    if (antioxidantsIngredients.length > 0) {
                      antioxidantsIngredientsWithComma = antioxidantsIngredients
                      antioxidantsIngredientsWithComma.push(';')
                    }
                  } else {
                    if (antioxidantsIngredients.length > 0) {
                      antioxidantsIngredientsWithComma = antioxidantsIngredients
                      antioxidantsIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    antioxidantsIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t(`category.antioxidants`)}:{' '}
                        {antioxidantsIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'agentsStabilising': {
                  let agentsStabilisingIngredientsWithComma = []
                  if (parseInt(index) !== order.length - 1) {
                    if (agentsStabilisingIngredients.length > 0) {
                      agentsStabilisingIngredientsWithComma =
                        agentsStabilisingIngredients
                      agentsStabilisingIngredientsWithComma.push(';')
                    }
                  } else {
                    if (agentsStabilisingIngredients.length > 0) {
                      agentsStabilisingIngredientsWithComma =
                        agentsStabilisingIngredients
                      agentsStabilisingIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    agentsStabilisingIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t(`category.agentsStabilising`)}:{' '}
                        {agentsStabilisingIngredientsWithComma.length > 1
                          ? `${t(`contain`)}  `
                          : ''}
                        {agentsStabilisingIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'gasses': {
                  let gassesIngredientsWithComma = []
                  if (parseInt(index) !== order.length - 1) {
                    if (gassesIngredients.length > 0) {
                      gassesIngredientsWithComma = gassesIngredients
                      gassesIngredientsWithComma.push(';')
                    }
                  } else {
                    if (gassesIngredients.length > 0) {
                      gassesIngredientsWithComma = gassesIngredients
                      gassesIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    gassesIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t(`category.gasses`)}: {gassesIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'others': {
                  let othersIngredientsWithComma = []
                  if (parseInt(index) !== order.length - 1) {
                    if (othersIngredients.length > 0) {
                      othersIngredientsWithComma = othersIngredients
                      othersIngredientsWithComma.push(';')
                    }
                  } else {
                    if (othersIngredients.length > 0) {
                      othersIngredientsWithComma = othersIngredients
                      othersIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    othersIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t(`category.others`)}: {othersIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'othersIngredients': {
                  let othersCategoryIngredientsWithComma = []
                  if (parseInt(index) !== order.length - 1) {
                    if (othersCategoryIngredients.length > 0) {
                      othersCategoryIngredientsWithComma =
                        othersCategoryIngredients
                      othersCategoryIngredientsWithComma.push(';')
                    }
                  } else {
                    if (othersCategoryIngredients.length > 0) {
                      othersCategoryIngredientsWithComma =
                        othersCategoryIngredients
                      othersCategoryIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    othersCategoryIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t(`category.othersIngredients`)}:{' '}
                        {othersCategoryIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                default: {
                  return null
                }
              }
            })}
            <br />
            {isBottledInProtectiveAtmosphere && (
              <Typography variant='body'>
                {t(`category.isBottledInProtectiveAtmosphere`)}
              </Typography>
            )}
            {/* {rawMaterialsIngredients.length > 0 && <Typography variant='body' ><b>{t(`category.rawMaterials`)}</b>: {rawMaterialsIngredients}</Typography>}
        {acidityRegulatorsIngredients.length > 0 && <Typography variant='body' ><b>{t(`category.acidityRegulators`)}</b>: {t(`contain`)} {acidityRegulatorsIngredients}</Typography>}
        {preservativesAndAntioxidantsIngredients.length > 0 && <Typography variant='body'><b>{t(`category.preservativesAndAntioxidants`)}</b>: {t(`contain`)} {preservativesAndAntioxidantsIngredients}</Typography>}
        {agentsStabilisingIngredients.length > 0 && <Typography variant='body'><b>{t(`category.agentsStabilising`)}</b>: {t(`contain`)} {agentsStabilisingIngredients}</Typography>}
        {gassesIngredients.length > 0 && <Typography variant='body'><b>{t(`category.gasses`)}: {t(`contain`)} {gassesIngredients}</b></Typography>}
        {othersIngredients.length > 0 && <Typography variant='body'><b>{t(`category.others`)}: {t(`contain`)} {othersIngredients}</b></Typography>}
        {othersCategoryIngredients.length > 0 && <Typography variant='body'><b>{t(`category.othersIngredients`)}</b>: {othersCategoryIngredients}</Typography>}
        */}
            {/* <Typography variant='body' sx={{ mb:2,  textAlign:"center"}}>{valuesArray}</Typography> */}
            {/* <Typography variant='body' sx={{ mb: othersArray? 1:2,  textAlign:"center"}}>{valuesArray}</Typography>
        <Typography variant='body' sx={{ mb: 2,  textAlign:"center"}}>{"Contiene: "+othersArray}</Typography> */}
          </>
        )}
    </div>
  )
}
export default IngredientsSection
