import React from 'react'
import PropTypes from 'prop-types'
import Link from '@mui/material/Link'
import '../CompanyInfo/style.css'

export default function TextWithUrl (props) {
  let description = props.text
  const regex = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/\S*)?/g
  let lines = []
  let url = []
  if (description.indexOf('https:') !== -1) {
    url = description.match(regex)[0]
    description = description.replace(url, '')
  }
  if (description.includes('\n')) {
    lines = description.split('\n')
      .map(row => {
        const rand = Math.random()
        return (<p key={`${row} ${rand}`}>{row}</p>)
      })
  } else {
    lines.push(<p>{description}</p>)
  }
  /* Non capisco utilità di questo terzo if, levare se serve */
  // if (description.indexOf('https:') !== -1) {
  //   url = description.match(regex)[0]
  // }
  return (
    <>
      {lines.map(row => row)}
      {url.length > 0 &&
    (<Link target='_blank' href={`${url}`} rel='noreferrer'><b>{`${props.companyName}`}</b></Link>)}
    </>
  )
}

TextWithUrl.propTypes = {
  text: PropTypes.string,
  companyName: PropTypes.string
}
