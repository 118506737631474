import i18n from '../i18n'
import { alog } from 'utils/apioLog'
// VARIABLES
// variabile con la lingua attuale
export let currLang = getCurrLang()
alog('currLang => ', currLang, 'DEBUG', 'info')

// FUNCTIONS
// funzione che mi ritorna la lingua attuale
export function getCurrLang () {
  return i18n.language
}

// funzione che fa cambiare lingua
export function changeLanguage (lang) {
  alog('new lang => ', lang, 'DEBUG', 'info')
  if (!lang) {
    lang = 'it-IT'
  }
  i18n.changeLanguage(lang)
  currLang = getCurrLang()
}

// funzione che ritorna la lingua leggibile
// can you compile with all the european 24 official languages?
export function returnAbbr (lang) {
  console.log('LANG', lang)
  let abbr = 'Ita'
  if (lang === 'en-GB' || lang === 'en-US' || lang === 'en') {
    abbr = 'ENG'
  } else if (lang === 'fr-FR' || lang === 'fr') {
    abbr = 'FRA'
  } else if (lang === 'de-DE' || lang === 'de') {
    abbr = 'DEU'
  } else if (lang === 'es-ES' || lang === 'es') {
    abbr = 'ESP'
  } else if (lang === 'pt-PT' || lang === 'pt') {
    abbr = 'POR'
  } else if (lang === 'pl-PL' || lang === 'pl') {
    abbr = 'POL'
  } else if (lang === 'ro-RO' || lang === 'ro') {
    abbr = 'ROM'
  } else if (lang === 'nl-NL' || lang === 'nl') {
    abbr = 'NLD'
  } else if (lang === 'bg-BG' || lang === 'bg') {
    abbr = 'BUL'
  } else if (lang === 'cs-CZ' || lang === 'cs') {
    abbr = 'CZE'
  } else if (lang === 'da-DK' || lang === 'da') {
    abbr = 'DAN'
  } else if (lang === 'et-EE' || lang === 'et') {
    abbr = 'EST'
  } else if (lang === 'el-GR' || lang === 'el') {
    abbr = 'GRE'
  } else if (lang === 'fi-FI' || lang === 'fi') {
    abbr = 'FIN'
  } else if (lang === 'hu-HU' || lang === 'hu') {
    abbr = 'HUN'
  } else if (lang === 'ga-IE' || lang === 'ga') {
    abbr = 'GLE'
  } else if (lang === 'lv-LV' || lang === 'lv') {
    abbr = 'LAV'
  } else if (lang === 'lt-LT' || lang === 'lt') {
    abbr = 'LIT'
  } else if (lang === 'mt-MT' || lang === 'mt') {
    abbr = 'MLT'
  } else if (lang === 'sk-SK' || lang === 'sk') {
    abbr = 'SVK'
  } else if (lang === 'sl-SI' || lang === 'sl') {
    abbr = 'SLV'
  } else if (lang === 'sv-SE' || lang === 'sv') {
    abbr = 'SWE'
  }
  return abbr
}

export function returnAbbrTwo (lang) {
  let abbr = 'it'
  if (lang === 'en-GB' || lang === 'en-US' || lang === 'fr-FR') {
    abbr = 'en'
  }
  return abbr
}