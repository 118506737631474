import React, { useContext } from 'react'
// import useMediaQuery from '@mui/material/useMediaQuery'
import { makeStyles } from 'tss-react/mui'

import { useTranslation } from 'react-i18next'
import {
  Box,
  Card,
  CardContent,
  CardMedia,
} from '@mui/material'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { AppContext } from '../../context/AppState'
import './style.css'

// import Accordion from '@mui/material/Accordion'
// import AccordionSummary from '@mui/material/AccordionSummary'
// import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DownloadIcon from '@mui/icons-material/Download';
import Button from 'components/CustomButtons/Button'

const useStyles = makeStyles()(presentationStyle)

// Funzione che scarica il documento
const downloadDocument = (name, url) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  a.href = url
  a.target = '_blank'
  a.download = name
  a.click()
  window.URL.revokeObjectURL(url)
}

const CertificationsTable = () => {
  const { classes } = useStyles()
  //const isSmall = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation('certificationsTable')
  const { state } = useContext(AppContext)

  const { company } = state
  // funzione che apre modal documenti
  // const handleOpenCert = (cert) => {
  //   dispatch({
  //     type: 'SET_CERT',
  //     payload: cert
  //   })
  //   dispatch({
  //     type: 'SET_OPEN_CERT',
  //     payload: true
  //   })
  // }

  return (
    // <div className={classes.lotContainer} style={isSmall ? { marginTop: '16px' } : {}}>
    //   <GridContainer style={{ width: '100%' }}>
    //     <GridItem xs={12} style={isSmall ? { paddingLeft: '2px', paddingRight: '2px' } : {}}>
    //       <Accordion>
    //         <AccordionSummary
    //           expandIcon={<ExpandMoreIcon />}
    //           aria-controls='panel1a-content'
    //           id='panel1a-header'
    //         >
    //           <Typography className='fredoka'>{t('title')}</Typography>
    //         </AccordionSummary>
    //         <AccordionDetails style={{ display: 'block' }}>
    //           <div className='certificationsTable'>
    //             <TableContainer component={Paper}>
    //               <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
    //                 <TableHead>
    //               <TableRow>
    //                   <TableCell>{t('name')}</TableCell>
    //                   <TableCell>{t('description')}</TableCell>
    //                   <TableCell>{t('authority')}</TableCell>
    //                   <TableCell>{t('protocolNum')}</TableCell>
    //                   <TableCell>{t('download')}</TableCell>
    //                 </TableRow>
    //             </TableHead>
    //                 <TableBody>
    //               {company.certifications.map((row) => (
    //                   <TableRow
    //                     key={row.name}
    //                     sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    //                   >
    //                     <TableCell component='th' scope='row'>
    //                       {row.name}
    //                     </TableCell>
    //                     <TableCell>{row.description}</TableCell>
    //                     <TableCell>{row.authority}</TableCell>
    //                     <TableCell>{row.num}</TableCell>
    //                     <TableCell>{Array.isArray(row.documents) && row.documents.map((doc) => (
    //                       <IconButton
    //                         aria-label='delete'
    //                         onClick={() => downloadDocument(doc.name, doc.fileUrl)}
    //                       >
    //                         <FilePresentIcon />
    //                       </IconButton>
    //                     ))}
    //                     </TableCell>
    //                   </TableRow>
    //                 ))}
    //             </TableBody>
    //               </Table>
    //             </TableContainer>
    //           </div>
    //         </AccordionDetails>
    //       </Accordion>
    //     </GridItem>
    //   </GridContainer>
    // </div>
    <>
      {company.certifications && Array.isArray(company.certifications) && company.certifications.length > 0 && 
      <>
      <h4 className={classes.cardTitleSmall}>{t('title')}</h4>
         {/* <Map zoom={14} isHeader lat={String(company.geolocation.lat)} lng={String(company.geolocation.lng)} /> */}
        <GridContainer container spacing={2}>
        {company.certifications.map((row,index) => (
          <GridItem sx={{fontFamily: "Raleway"}} item xs={12} sm={6} md={4} key={`certification-${index}`}>
            <Card className={classes.card} sx={{height:"100%", fontFamily: "Raleway"}}>
              {row.image?<CardMedia
                component="img"
                alt="Certification Image"
                height="140"
                image={row.image}
                />
                : null
              }
              <CardContent sx={{height:"100%"}}>
                {/* <Box
                  component="img"
                  sx={{
                    height: 233,
                    width: 350,
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                  }}
                  alt="Certification Image"
                  src={row}
                /> */}
                <Typography className='fredoka'>{row.name}</Typography>
                {row.description ? (
                  <p >
                  {row.description}
                </p>
                ): null}
                {row.authority ? (
                  <p>
                    {/* {`${t('authority')}: ${row.authority}`} */}
                    <span className={classes.boldText}>{`${t('authority')}: `}</span>{row.authority}
                  </p>
                ): null}
                {row.num ? (
                  <p>
                  {/* {`${t('protocolNum')}: ${row.num}`} */}
                  <span className={classes.boldText}>{`${t('protocolNum')}: `}</span>{row.num}
                  </p>
                ): null}
                {Array.isArray(row.documents) ? <Box sx={{}}>
                    {row.documents.map((doc) => (
                  <Button variant="outlined" color='ctaButton' sx={{mt:1}} startIcon={<DownloadIcon />}  onClick={() => downloadDocument(doc.name, doc.fileUrl)}>
                    {doc.name.length > 26 ? doc.name.substring(0,18) +" ... "+ doc.name.slice(-6) : doc.name}
                  </Button>
                ))}
                </Box> : null }
                {/* {Array.isArray(row.documents) && row.documents.map((doc) => (
                  <Button variant="outlined" startIcon={<DownloadIcon />}  onClick={() => downloadDocument(doc.name, doc.fileUrl)}>
                    {doc.name}
                  </Button>
                ))} */}
              </CardContent>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
     </>
     }
    </>
  )
}

export default CertificationsTable
