import React, {  useEffect } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { makeStyles } from 'tss-react/mui'
import { Search as SearchIcon } from '@mui/icons-material'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Link
} from '@mui/material'

// import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'

import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
const useStyles = makeStyles()(presentationStyle)

const TraceabilityCollectionsTable = (props) => {
  const { classes } = useStyles()
  const isSmall = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation('collectionsTable')
  const {collections, followCollection} = props
  // funzione che apre modal documenti


  //   [
  //   { title: t('name'), field: 'name' },
  //   { title: t('description'), field: 'description' },
  //   { title: t('authority'), field: 'authority' },
  //   { title: t('protocolNum'), field: 'num' }
  // ]
  return (
    <div className={classes.cerficationContainer} style={isSmall ? { marginTop: '16px' } : {}}>
      <div style={{ width: '100%', paddingBottom: '1%' }} className='certificationsTable showTitle'>
        <TableContainer sx={{ width: "auto"}}component={Paper}>
          <Table sx={{ minWidth: 150 }} size='small' aria-label='a dense table'>
            <TableHead>
              <TableRow>
                <TableCell>{t('name')}</TableCell>
                <TableCell align="right">{t('table.moreInfo')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {collections.map((row) => (
                <TableRow
                  key={row.lotNumber}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell 
                  // sx={{color: 'blue', textDecoration: "underline", cursor: "auto"}}
                  component='th' scope='row'>
                    <Link 
                      component="button"
                      variant="body2"
                      onClick={() => followCollection(row.lotNumber, row)}>{row.lotNumber}</Link>
                  </TableCell>
                  <TableCell
                  align="right">
                    <IconButton
                      aria-label='search'
                      onClick={() => followCollection(row.lotNumber, row)}
                    >
                      <SearchIcon />
                    </IconButton>
                  
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default TraceabilityCollectionsTable
