import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

//import MenuDesktop from './MenuDesktop'
import MenuMobile from './MenuMobile'

const Menu = (props) => {
  //const isSmall = useMediaQuery('(max-width: 600px)')
  const isMedium = useMediaQuery('(max-width: 960px)')

  // return isSmall
  //   ? <MenuMobile isFashion={props.isFashion} />
  //   : <MenuDesktop isFashion={props.isFashion} />
  return isMedium
    && <MenuMobile isFashion={props.isFashion} />
   
}

export default Menu
