import React from 'react'
import trackIt from '../../assets/img/logo-trackit.png'

import {
  Box
} from '@mui/material'

const FooterTrackitDesktop = () => {
  return (
    <Box className='footerBackgroundDesktop'>
      <Box className='footerLabelDesktop'>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <img style={{ width: '13%' }} src={trackIt} alt='Trackit' />
        {/* <img style={{ width: '15%' }} src={trackIt} alt='Trackit' /> */}
        </div>
      </Box>
    </Box>
  )
}

export default FooterTrackitDesktop
