import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// OPTIONAL
// import Backend from 'i18next-xhr-backend' // used to have a pre-render server side with multiple files
// https://github.com/i18next/i18next-xhr-backend
import LanguageDetector from 'i18next-browser-languagedetector'
// https://github.com/i18next/i18next-browser-languageDetector
// all the translations for the 24 official languages of the European Union
import italianCommon from './translations/it/common.json'
import englishCommon from './translations/en/common.json'
import franceCommon from './translations/fr/common.json'
import germanyCommon from './translations/de/common.json'
import spainCommon from './translations/es/common.json'
import portugalCommon from './translations/pt/common.json'
import polandCommon from './translations/pl/common.json'
import romaniaCommon from './translations/ro/common.json'
import netherlandsCommon from './translations/nl/common.json'
// import bulgariaCommon from './translations/bg/common.json'
// import czechiaCommon from './translations/cs/common.json'
// import denmarkCommon from './translations/da/common.json'
// import estoniaCommon from './translations/et/common.json'
// import greeceCommon from './translations/el/common.json'
// import finlandCommon from './translations/fi/common.json'
// import hungaryCommon from './translations/hu/common.json'
// import irelandCommon from './translations/ga/common.json'
// import croatiaCommon from './translations/hr/common.json'
// import lithuaniaCommon from './translations/lt/common.json'
// import latviaCommon from './translations/lv/common.json'
// import slovakiaCommon from './translations/sk/common.json'
// import sloveniaCommon from './translations/sl/common.json'
// import swedenCommon from './translations/sv/common.json'
// import luxembourgCommon from './translations/lb/common.json'
// import maltaCommon from './translations/mt/common.json'
// import cyprusCommon from './translations/el/common.json'


// Some component functions
// https://www.i18next.com/overview/api#addresourcebundle

// variaible con le opzioni del LanguageDetector
const languageDetectorOptions = {
  // order and from where user language should be detected
  order: ['navigator', 'localStorage', 'cookie', 'querystring', 'htmlTag', 'path', 'subdomain'],
  // cache user language on
  caches: ['localStorage', 'cookie'],
  // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  // optional expire and domain for set cookie
  cookieMinutes: 10,
  // cookieDomain: 'myDomain',
  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement

  // only detect languages that are in the whitelist
  // checkWhitelist: true
}

// variabile con le risorse
// can you compile with all the european 24 official languages?
const resources = {
  it: italianCommon,
  en: englishCommon,
  fr: franceCommon,
  de: germanyCommon,
  es: spainCommon,
  pt: portugalCommon,
  pl: polandCommon,
  ro: romaniaCommon,
  nl: netherlandsCommon,
  // bg: bulgariaCommon,
  // cs: czechiaCommon,
  // da: denmarkCommon,
  // et: estoniaCommon,
  // el: greeceCommon,
  // fi: finlandCommon,
  // hu: hungaryCommon,
  // ga: irelandCommon,
  // hr: croatiaCommon,
  // lt: lithuaniaCommon,
  // lv: latviaCommon,
  // sk: slovakiaCommon,
  // sl: sloveniaCommon,
  // sv: swedenCommon,
  // lb: luxembourgCommon,
  // mt: maltaCommon,
  // cy: cyprusCommon
}

// create component istance
i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: {
      'it-IT': ['it'],
      'fr-FR': ['fr'],
      'de-DE': ['de'],
      'es-ES': ['es'],
      'pt-PT': ['pt'],
      'pl-PL': ['pl'],
      'ro-RO': ['ro'],
      'nl-NL': ['nl'],
      'bg-BG': ['bg'],
      'cs-CZ': ['cs'],
      'da-DK': ['da'],
      'et-EE': ['et'],
      'el-GR': ['el'],
      'fi-FI': ['fi'],
      'hu-HU': ['hu'],
      'ga-IE': ['ga'],
      'hr-HR': ['hr'],
      'lt-LT': ['lt'],
      'lv-LV': ['lv'],
      'sk-SK': ['sk'],
      'sl-SI': ['sl'],
      'sv-SE': ['sv'],
      'lb-LU': ['lb'],
      'mt-MT': ['mt'],
      'cy-CY': ['cy'],
      default: ['en']
    },
    // lng: "it", // initialize i18next with language to use
    debug: true,
    // nonExplicitWhitelist: true, // if true will pass eg. en-US if finding en in whitelist
    detection: languageDetectorOptions, // per passare opzioni del languageDeterctor
    resources,

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  }, (err, t) => {
    if (err) return console.error('something went wrong loading i18next, err => ', err)
    t('key') // -> same as i18next.t
  })

export default i18n
