import React, { useContext, useEffect, useState } from 'react'
// import { makeStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { AppContext } from '../../context/AppState'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'
import './style.css'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import {
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material'

const useStyles = makeStyles()(presentationStyle)

const NutritionalValue = () => {
  const { state } = useContext(AppContext)
  const { product } = state
  const [valueObj, setValueObj] = useState({})
  const { classes } = useStyles()
  const { sectionActive } = state
  const { t } = useTranslation('nutrition')
  let obj = {}

  const addUom = (text, label) => {
    switch (label) {
      case 'kJ': {
        if (text?.match(/kj/i)) {
          return text
        } else {
          return `${text} ${label}`
        }
      }
      case 'kcal': {
        if (text?.match(/kcal/i)) {
          return text
        } else {
          return `${text} ${label}`
        }
      }
      case 'g': {
        if (text?.match(/g/i)) {
          return text
        } else {
          return `${text} ${label}`
        }
      }
      default: {
        return text
      }
    }
  }
  useEffect(() => {
    /* for (const el of product.attributes) {
      if (el.name.includes('<VN>')) {
        values.push(el)
      }
    } */
    console.log(product)
    if (!product.nutritionalValue) {
      obj = {
        energy: {
          standardGrams: '0'
        },
        fat: {
          standardGrams: '0'
        },
        ofFatty: {
          standardGrams: '0'
        },
        carbo: {
          standardGrams: '0'
        },
        sugar: {
          standardGrams: '0'
        },
        proteins: {
          standardGrams: '0'
        },
        salt: {
          standardGrams: '0'
        }
      }
    } else {
      obj = product.nutritionalValue
    }

    const kj = obj.energy?.kJ?.standardGrams
    const kcal = obj.energy?.kcal?.standardGrams
    setValueObj({
      ...obj,
      energy: {
        standardGrams:
          kj || kcal ? (
            !isSmall ? (
              <>
                {addUom(kj, 'kJ')}
                <br /> / {addUom(kcal, 'kcal')}
              </>
            ) : (
              `${addUom(kj, 'kJ')} / ${addUom(kcal, 'kcal')}`
            )
          ) : null
      }
    })
    // eslint-disable-next-line
  }, [product.metadata])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [sectionActive])

  const isSmall = useMediaQuery('(max-width: 600px)')

  return (
    <div
      className='ingredientContainer'
      style={isSmall ? { marginTop: '16px' } : { background: 'white' }}
    >
      {/* <div className={classes.cerficationContainer} style={isSmall ? { marginTop: '16px',paddingLeft:20, paddingRight:20 } : {paddingLeft:40, paddingRight:40}}> */}
      {/* <Typography>{t('title', { um: valueObj.label ? valueObj.label.standardGrams : 'ml' })}</Typography> */}
      <Typography
        variant='h5'
        component='p'
        style={{ color: '#3C4858', margin: '0px 0 20px 0' }}
        className={classes.fredoka}
      >
        {t('title', {
          um: valueObj.label ? valueObj.label.standardGrams : 'ml'
        })}
      </Typography>

      <div
        style={{ maxWidth: 950, width: '100%', margin: 'auto' }}
        className='nutritionalValuesTable showTitle'
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 300 }} size='small' aria-label='a dense table'>
            {/* <TableHead>
              <Typography>{t('title', { um: valueObj.label ? valueObj.label.standardGrams : 'ml' })}</Typography>
            </TableHead> */}
            <TableBody>
              <TableRow>
                <TableCell>{t('energy')}</TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  {valueObj.energy && valueObj.energy.standardGrams
                    ? valueObj.energy.standardGrams
                    : '0 kJ / 0 kcal'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {t('fat')}
                  <br />
                  {'- ' + t('fatSub')}
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  {valueObj.fat?.standardGrams
                    ? `${addUom(valueObj.fat.standardGrams, 'g')}`
                    : '0 g'}
                  <br />
                  {valueObj.ofFatty?.standardGrams
                    ? `${addUom(valueObj.ofFatty.standardGrams, 'g')}`
                    : '0 g'}
                </TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>{t('fatSub')}</TableCell>
                <TableCell>{valueObj.ofFatty ? `${addUom(valueObj.ofFatty.standardGrams, 'g')}` : 'No value'}</TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>
                  {t('carbs')}
                  <br />
                  {'- ' + t('carbsSub')}
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  {valueObj.carbo?.standardGrams
                    ? `${addUom(valueObj.carbo.standardGrams, 'g')}`
                    : '0 g'}
                  <br />
                  {valueObj.sugar?.standardGrams
                    ? `${addUom(valueObj.sugar.standardGrams, 'g')}`
                    : '0 g'}
                </TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>{t('carbsSub')}</TableCell>
                <TableCell>{valueObj.sugar ? `${addUom(valueObj.sugar.standardGrams, 'g')}` : 'No value'}</TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>{t('fibre')}</TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  {valueObj.fibre?.standardGrams
                    ? `${addUom(valueObj.fibre.standardGrams, 'g')}`
                    : '0 g'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('proteins')}</TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  {valueObj.proteins?.standardGrams
                    ? `${addUom(valueObj.proteins.standardGrams, 'g')}`
                    : '0 g'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('salt')}</TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  {valueObj.salt?.standardGrams
                    ? `${addUom(valueObj.salt.standardGrams, 'g')}`
                    : '0 g'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}
export default NutritionalValue
