import React, { useContext } from 'react'
import { AppContext } from '../../context/AppState'
import useMediaQuery from '@mui/material/useMediaQuery'

import Button from 'components/CustomButtons/Button.js'
import { Box } from '@mui/material'

const instagramUrl = 'https://www.instagram.com/'
const instagramApp = 'instagram://user?'

const SocialLinks = () => {
  const isSmall = useMediaQuery('(max-width: 600px)')

  const { state } = useContext(AppContext)
  const { sectionActive, company, product } = state

  const { social, website } = company
  const { ecommerce: ecommerceUrl } = product

  let instagramDesktop
  let instagramMobile
  if (social && social.instagram) {
    instagramDesktop = `${instagramUrl}${social.instagram.split('@')[1]}`
    instagramMobile = `${instagramApp}username=${social.instagram.split('@')[1]}`
  } else {
    instagramDesktop = null
    instagramMobile = null
  }
  return (
    <Box component='div' display={isSmall && (sectionActive !== 1 && sectionActive !== 2) ? 'none' : 'block'}>
      {website && (<Button
        disabled={!(website)}
        href={website || ''}
        target='_blank'
        color='github'
        justIcon
        simple
                   >
        <i className='fas fa-globe' style={{ fontSize: '28px', color: '#333333' }}/>
                   </Button>
      )}
      {ecommerceUrl && (<Button
        disabled={!(ecommerceUrl)}
        href={ecommerceUrl || ''}
        target='_blank'
        //color='github'
        justIcon
        simple
                   >
        <i className='fas fa-shopping-cart' style={{ fontSize: '28px', color: '#01A4AE' }}/>
                   </Button>
      )}
      {(social && social.facebook) && (<Button
        disabled={!(social && social.facebook)}
        href={(social && social.facebook) || ''}
        target='_blank'
        color='facebook'
        justIcon
        simple                              
        >
        <i className="fab fa-facebook-f" style={{ fontSize: '28px', color: '#4267B2' }} />
                                       </Button>
      )}
      {(social && social.linkedin) && (<Button
        disabled={!(social && social.linkedin)}
        href={(social && social.linkedin) || ''}
        target='_blank'
        color='linkedin'
        justIcon
        simple
                                       >
        <i className='fab fa-linkedin-in' style={{ fontSize: '28px', color: '#0976B4' }}/>
                                       </Button>
      )}
      {(social && social.twitter) && (
        <Button
          disabled={!(social && social.twitter)}
          href={(social && social.twitter) || ''}
          target='_blank'
          color='twitter'
          justIcon
          simple
        >
          <i className='fa-brands fa-x-twitter' style={{ fontSize: '24px', color: '#000000' }}/>
          {/* <i className='fab fa-twitter' style={{ fontSize: '28px', color: '#000000' }}/> */}
        </Button>)}

      {(social && social.instagram)
        ? (!isSmall)
            ? (<Button
                disabled={!(social && social.instagram)}
                href={(social && social.instagram && `${instagramDesktop}`) || ''}
                target='_blank'
                color='instagram'
                justIcon
                simple
               >
              {/* <i className='fa fa-instagram' style={{ fontSize: '18px', color: '#C13584' }}/> */}
              <i className="fab fa-instagram" style={{ fontSize: '28px', color: '#C13584' }} />
            </Button>)
            : (<Button
                disabled={!(social && social.instagram)}
                href={(social && social.instagram && `${instagramMobile}`) || ''}
                target='_blank'
                color='instagram'
                justIcon
                simple
               >
              <i className='fab fa-instagram' style={{ fontSize: '28px', color: '#C13584' }} />
            </Button>)
        : null}
        
    </Box>
  )
}

export default SocialLinks
