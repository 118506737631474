const organizationToRules = {
  '5ad4a56c-9326-43e5-a236-f1a99f1edf66': {
    tableSearchLot: false
  },
  '0a098fd4-61bd-4dd6-8550-6d7b37b9d1a6': {
    virtualAssistant: true,
    hideCompany: true,
    footer: true
  },
  '0f83790a-d927-4eb6-b910-faf2473645b4': {
    tags: true
  },
  'cd7abad7-86f1-4351-9e3e-912449598add': {
    tags: true,
    traceabilityRenderRight:true,
    traceabilityXdaiStorytelling:true,
  },
  '6246156b-0b6f-4acf-be18-683c204718e3': {
    tableCollections: true
  }
}
export default organizationToRules
