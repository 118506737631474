import React from 'react'
import moment from 'moment'
import configuration from 'configuration'
import useMediaQuery from '@mui/material/useMediaQuery'
import { makeStyles } from 'tss-react/mui'
import { green, amber } from '@mui/material/colors'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import { Paper, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography, Box, Tooltip, Fab, Link } from '@mui/material'
// import { useTranslation, Trans } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import IconButton from '@mui/material/IconButton'
import DownLoadIcon from '@mui/icons-material/CloudDownload'
import CheckIcon from '@mui/icons-material/CheckCircle'
import TimeIcon from '@mui/icons-material/AccessTime'
import GetAppIcon from '@mui/icons-material/GetApp'
import { returnAbbrTwo, currLang } from '../../utils/translations.js'
import GridItem from 'components/Grid/GridItem'
import InfoFromProductor from './InfoFromProductor.js'
// import CompanyVerificationBanner from 'components/CompanyVerificationBanner/index.js'

import './style.css'

const useStyles = makeStyles()(presentationStyle)

// funzione che apre un doc. E' stata commentata perché fino a che il pdf è nello storage di google, si può passare direttamente il link e non ci
// da alcun problema di visualizzazione (questa soluzione del link diretto è stata fornita dal momento in cui avevamo problemi su iphone per la visualizzazione)

// const openDocument = (url) => {
//   const w = window.open('about:blank')
//   // const title = w.document.createElement('title')
//   // title.innerHTML = 'Trusty | Documenti'
//   // w.document.head.appendChild(title)

//   // // w.document.head.appendChilde('<title>Trusty | Documenti</title>')
//   // var objectContainer = w.document.createElement('div')
//   // objectContainer.classList.add('demo-iframe-holder')
//   // objectContainer.innerHTML = `<iframe src="${url}"></iframe>` // <--notice the escaping of /
//   // w.document.body.appendChild(objectContainer)
//   w.document.write(`<html>
//         <head>
//           <title>Trusty | Documenti</title>
//         </head>
//         <body style="margin: 0px;">
//           <iframe width="100%" height="100vh" src="${url}"></iframe>
//         </body>
//       </html>
//       `)
// }

const downloadDocument = (url) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  a.href = url
  a.download = 'ots document'
  a.click()
  window.URL.revokeObjectURL(url)
}

// funzione che apre un link
const openUrl = (url) => {
  window.open(url, '_blank')
}

// const trustyLink = (
//   <Link target='_blank' color="primary" key="trustyLink" href="https://www.trusty.id">
//   </Link>
// )

// const strongType = (
//   <strong key="strongType"></strong>
// )
// const cursive = (
//   <em key="cursive"></em>
// )

const OpentimestampsHeader = (props) => {
  const { classes } = useStyles()
  const { t } = useTranslation('opentimestampsHeader')
  const isSmall = useMediaQuery('(max-width: 600px)')
  const [open, setOpen] = React.useState(false)
  const [type, setType] = React.useState('') // type 0 è il testo per pasta mancini, type 1 è il Nessun info dal produttore
  const [pastamancini, setPastamancini] = React.useState(false)

  const { lot: currLot, lotNumber } = props

  // const { name, website } = company

  // const companyLink = (
  //   <Link target='_blank' classes={classes.companyWebsite} key="companyLink" href={website}>
  //     {{ name }}
  //   </Link>
  // )
  const handleClickOpen = (index, length) => {
    if (currLot && currLot.companyId === 'de231ee7-e975-4d60-b219-d70714b1e2ec') {
      setPastamancini(true)
      if (index === 0 && length > 1) {
        setOpen(true)
        setType('0')
      } else {
        setOpen(true)
        setType('1')
      }
    } else {
      setOpen(true)
      setType('1')
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  return currLot.opentimestamps && currLot.opentimestamps.length > 0 &&
    <>
      {(open && pastamancini) &&
        <InfoFromProductor
          open={open}
          type={type}
          onClose={handleClose}
        />}
      {(open && !pastamancini) && // tutti gli altri produttori che non hanno info addizionali per adesso
        <InfoFromProductor
          open={open}
          type='1'
          onClose={handleClose}
        />}

      <GridItem xs={12} md={12} lg={12} style={{ marginBottom: '70px', textAlign: 'center', padding: 0 }}>
        {/* <Typography className="descriptionSentences">
        <Trans i18nKey='description'>
          {[trustyLink,
            strongType,
            cursive,
            companyLink
          ]}

        </Trans>
      </Typography> */}
        {/* <Typography className="descriptionSentences">{t('html')} */}
        {/* </Typography> */}
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className='openTimeTable'>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell style={{ minWidth: 300 }}>{t('status')}</TableCell>
                <TableCell align='left'>{t('date')}</TableCell>
                <TableCell align='left' style={{ minWidth: 300 }}>{t('txHash')}</TableCell>
                <TableCell align='left' />
              </TableRow>
            </TableHead>
            <TableBody>
              {currLot.opentimestamps.map((timestamp, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <IconButton aria-label='info' color='inherit' onClick={() => handleClickOpen(index, currLot.opentimestamps.length)}>
                      <InfoIcon className={classes.infoButton} />
                    </IconButton>
                    {/* <Fab classes={{ primary: classes.fabPrimary }} onClick={() => currLot.opentimestamps && currLot.opentimestamps.length > 0 && currLot.opentimestamps[index].ots ? downloadDocument(currLot.opentimestamps[index].ots) : {}} color="primary" size="small" style={{ margin: '10px 5px' }}>

                </Fab> */}
                  </TableCell>
                  <TableCell scope='row'>
                    {timestamp.verified
                      ? <Box component='div' display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center'>
                      <CheckIcon style={{ marginRight: '8px', color: green[700] }} />
                      <Typography variant='body1'>{t('successVerification')}</Typography>
                    </Box>
                      : <Box component='div' display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center'>
                      <TimeIcon style={{ marginRight: '8px', color: amber[700] }} />
                      <Typography variant='body1'>{t('pendingVerification')}</Typography>
                    </Box>}
                  </TableCell>
                  <TableCell align='left'>
                    {timestamp.timestamp && timestamp.timestamp !== ''
                      ? moment(timestamp.timestamp).format('DD/MM/YYYY')
                      : moment(currLot.updatedAt).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell align='left'>
                    {timestamp.transactionId && timestamp.transactionId !== ''
                      ? <>
                      <Link href={`${configuration.blockchainExplorer}/${returnAbbrTwo(currLang)}/btc/tx/${timestamp.transactionId}`} target='_blank' rel='noreferrer'>
                        0x{timestamp.transactionId
                        .slice(0, 3)}...{timestamp.transactionId
                          .slice(61, 64)}
                      </Link>
                    </>
                      : `${t('txHashNotPresent')}`}
                  </TableCell>
                  <TableCell align='right'>
                    <Box display='flex' flexDirection='row' justifyContent='flex-end'>
                      <div className={isSmall ? `${classes.btnContainerOpenTimeStampHeader}` : null}>
                      <Tooltip title={t('downloadPDF')}>
                        <Fab
                          classes={{ primary: classes.fabPrimary }}
                          disabled={!(currLot.opentimestamps && currLot.opentimestamps.length > 0 && currLot.opentimestamps[index].filename)}
                          color='primary'
                          size='small'
                          style={{ margin: '10px 5px' }}
                        >
                          <Link href={currLot.opentimestamps[index].filename} target='_blank' color='inherit' rel='noreferrer'>
                            <DownLoadIcon />
                          </Link>
                        </Fab>
                      </Tooltip>
                      {isSmall && <span>{t('mobileDownloadPDF')}</span>}
                    </div>
                      <div className={isSmall ? `${classes.btnContainerOpenTimeStampHeader}` : null}>
                      <Tooltip title={t('downloadOTS')}>
                        <Fab
                          classes={{ primary: classes.fabPrimary }}
                          onClick={() => currLot.opentimestamps && currLot.opentimestamps.length > 0 && currLot.opentimestamps[index].ots ? downloadDocument(currLot.opentimestamps[index].ots) : {}} color='primary' size='small' style={{ margin: '10px 5px', background: '#FBEECA' }}
                        >
                          <GetAppIcon />
                        </Fab>
                      </Tooltip>
                      {isSmall && <span>{t('mobileDownloadOTS')}</span>}
                    </div>
                      <div className={isSmall ? `${classes.btnContainerOpenTimeStampHeader}` : null}>
                      <Tooltip title={t('moreInfo')}>
                        <Fab
                          classes={{ primary: classes.fabPrimary }}
                          onClick={() => currLot.opentimestamps && currLot.opentimestamps.length > 0 && currLot.opentimestamps[index].ots && lotNumber ? openUrl(`/opentimestamps/?filename=${currLot.opentimestamps[index].ots}&lotNum=${lotNumber}`) : {}}
                          size='small'
                          style={{ margin: '10px 5px', background: '#FBEECA', color: 'white' }}
                        >
                          <ExitToAppIcon />
                        </Fab>
                      </Tooltip>
                      {isSmall && <span>{t('mobileMoreInfo')}</span>}
                    </div>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </GridItem>
    </>
}

export default OpentimestampsHeader
// #c8e0e8
