import React, { useContext, useEffect } from 'react'
// import { makeStyles } from 'tss-react/mui'
// import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import Link from '@mui/material/Link'
// import Button from '@material-ui/core/Button'
import DialogContent from '@mui/material/DialogContent'

// import Tooltip from '@material-ui/core/Tooltip'
import DialogContentText from '@mui/material/DialogContentText'
import { useTranslation, Trans } from 'react-i18next'
import { AppContext } from '../../context/AppState'
import PropTypes from 'prop-types'
import OpentimestampsHeader from './OpentimestampsHeader'
import Hidden from '@mui/material/Hidden'
import DialogActions from '@mui/material/DialogActions'
import Button from 'components/CustomButtons/Button'
import './style.css'


// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
// import ChevronRightIcon from '@mui/icons-material/ChevronRight'

// import { Controller, Scene } from 'react-scrollmagic'
import XdaiTable from './XdaiTable'
import XdaiTableMobile from './XdaiTableMobile'
import LukerTraceability from './LukerTraceability'
import { isTrackit } from 'utils/utils'
import { domain } from 'serviceWorker'
import useMediaQuery from '@mui/material/useMediaQuery'


const showSupplier = (show, mapFac, x) => {
  switch(show){
    case 'onlyPlaces':
      return `${x.geolocation?.address?.city}, ${x.geolocation?.address?.country}`
    case 'both':
      return `${x.geolocation?.address?.city}, ${x.geolocation?.address?.country} - ${mapFac[x.gln]}`
    case 'onlyNames':
        return mapFac[x.gln]
    default:
      return mapFac[x.gln]
  }
}
const strongType = (
  <strong key='strongType' />
)
const cursive = (
  <em key='cursive' />
)

function VerifyWithTrusty (props) {
  const { state } = useContext(AppContext)
  const { company, currLot, lotNumber } = state
  const { t } = useTranslation('verifyWithTrusty')
  const { onClose, open, isCalledBy, events, facilities, products} = props
  const [glnName, setGlnName] = React.useState({})
  const [gtinName, setGtinName] = React.useState({})
  const isSmall = useMediaQuery('(max-width: 600px)')

  const name = company.name

  const companyLink = (
    <Link target='_blank' key='companyLink' href={company.website} rel='noreferrer'>
      {{ name }}
    </Link>

  )

  // NOTE: useEffect utile per recuperare le facilies che dovrò mostrare nel tabela
  useEffect(() => {
    if (isCalledBy.includes('events')) {
      // eslint-disable-next-line no-inner-declarations
      async function fetchFacilities () {
        const mapFac = {}

         
          facilities.forEach(x => {
            mapFac[x.gln] = x.name
          })
          if(company && company.metadata && company.metadata.showSuppliers){

            facilities.forEach(x => {
              if(x.geolocation?.address?.city && x.geolocation?.address?.country){
                mapFac[x.gln] = showSupplier(company.metadata.showSuppliers, mapFac, x)
              }
            })
          }

        setGlnName(mapFac)
      }
      // eslint-disable-next-line no-inner-declarations
      async function fetchProducts () {
        
        const mapProd = {}
        products.forEach(x =>
          mapProd[x.gtin] = x.name
        )
        setGtinName(mapProd)
      }
      fetchFacilities()
      fetchProducts()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const componentToRender = (isCalledBy) => {
    console.log("company", company)
    if(company?.projectId === "7f214744-3871-4b45-b9aa-fd63126c8318"){
      return <LukerTraceability events={events} glnName={glnName} gtinName={gtinName}/>
    }else{
      switch (isCalledBy) {
        case 'lots':
          return <OpentimestampsHeader company={company} lot={currLot} lotNumber={lotNumber} />
        case 'events':
          return isSmall ? <XdaiTableMobile events={events} glnName={glnName} gtinName={gtinName} /> : <XdaiTable events={events} glnName={glnName} gtinName={gtinName} />
        default:
          return null
        }
    }
  }

  const dynamicTitle = () => {
    return isTrackit(domain)
      ? t('titleGeneral')
      : t('title')
  }

  return (
    <>
      {/* Per il desktop */}
      <Hidden xsDown>
        <Dialog maxWidth='lg' onClose={onClose} open={open}>
          <DialogTitle className='fredoka'>{dynamicTitle()}</DialogTitle>
          <DialogContent>

            <DialogContentText>
              <Trans i18nKey='verifyWithTrusty'>
                {[strongType,
                  strongType,
                  cursive,
                  companyLink
                ]}

              </Trans>
            </DialogContentText>
            <div>
              {componentToRender(isCalledBy)}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>
      </Hidden>
      {/* Per il mobile */}
      <Hidden smUp>
        <Dialog onClose={onClose} open={open} fullScreen>
          <DialogTitle component='h2' className='fredoka'>{dynamicTitle()}</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ fontFamily: '"Raleway", sans-serif' }}>
              <Trans i18nKey='verifyWithTrusty'>
                {[strongType,
                  strongType,
                  cursive,
                  companyLink
                ]}
              </Trans>
            </DialogContentText>
            {/* //TODO: rimettere scroll magic */}
            {/* <Controller>
              <Scene duration={1000} classToggle="hideIcon" triggerElement="#trigger" indicators={false}>
                {() => (
                  <div className="vIndicatorContainer" style={{ marginTop: 30 }}>
                    <div className="vIconContainer">
                      <ExpandMoreIcon/>
                    </div>
                  </div>
                )}
              </Scene>
            </Controller> */}

            <div id='trigger' className='triggerOne' />
            <div className='openTimeStampContainer'>
              {componentToRender(isCalledBy)}
            </div>

            {/* <div className='vIndicatorContainerTable' style={{ marginTop: 30 }}>
              <div className='olIconContainer'>
                <ChevronRightIcon />
              </div>
              <div className='orIconContainer'>
                <ArrowForwardIosIcon />
              </div>
            </div> */}

          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color='generalButton'>
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>
      </Hidden>
    </>
  )
}
VerifyWithTrusty.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}
export default VerifyWithTrusty
