import React, { useContext } from 'react'
import { makeStyles } from 'tss-react/mui'
import useMediaQuery from '@mui/material/useMediaQuery'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'
import {
  Typography
} from '@mui/material'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import './style.css'

const useStyles = makeStyles()(presentationStyle)
const AttributesGrid = () => {
  const { classes } = useStyles()
  const { state } = useContext(AppContext)
  const { product } = state
  const { t } = useTranslation('attributesGrid')

  const isSmall = useMediaQuery('(max-width: 600px)')

  return (
    <GridContainer className={classes.contentContainer} style={isSmall ? { paddingLeft: '0px', paddingRight: '0px', marginTop: '16px' } : { background: 'white' }}>
      <GridItem xs={12} md={12} xl={12} />
      <GridItem xs={12} md={12} xl={12}>
        <Typography variant='h5' component='p' style={{ color: '#3C4858', margin: '0px 0 20px 0' }} className={classes.fredoka}>
          {`${t('description')}`}
        </Typography>
      </GridItem>
      <GridItem xs={12} md={12} xl={12}>
        {product &&
      product.description
          ? (product.description.includes('\n')
              ? product.description
                .split('\n')
                .map(row => {
                  const rand = Math.random()
                  return (<p key={`${row} ${rand}`}>{row}</p>)
                })
              : product.description
            )
          : console.log('product', product.description)}

      </GridItem>
    </GridContainer>
  )
}
export default AttributesGrid
