import React, { useContext, useEffect } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { makeStyles } from 'tss-react/mui'

import { Box, Card, CardContent, CardMedia } from '@mui/material'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from '@mui/material/Typography'

// import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'

import { AppContext } from '../../context/AppState'
import DownloadIcon from '@mui/icons-material/Download'
import Button from 'components/CustomButtons/Button'

const useStyles = makeStyles()(presentationStyle)

// Funzione che scarica il documento
const downloadDocument = (name, url) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  a.href = url
  a.target = '_blank'
  a.download = name
  a.click()
  window.URL.revokeObjectURL(url)
}

const CertificationsTable = () => {
  const { classes } = useStyles()
  const isSmall = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation('certificationsTable')
  const { state } = useContext(AppContext)

  const { product, sectionActive } = state
  console.log('Product certifications:::', product.certifications)
  // funzione che apre modal documenti

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [sectionActive])


  return (
    <div
      className={classes.cerficationContainer}
      style={
        isSmall
          ? { marginTop: '16px', paddingLeft: 20, paddingRight: 20 ,paddingBottom:20}
          : { paddingLeft: 40, paddingRight: 40, paddingBottom:20 }
      }
    >
      <div style={{ width: '100%' }} className="certificationsTable showTitle">
        {/* <h4 className={classes.cardTitleSmall}>{t('title')}</h4> */}
        <GridContainer container spacing={2}>
          {product.certifications.map((row, index) => (
            <GridItem
              sx={{ fontFamily: 'Raleway' }}
              item
              xs={12}
              sm={6}
              md={4}
              key={`certification-${index}`}
            >
              <Card
                className={classes.card}
                sx={{ height: '100%', fontFamily: 'Raleway' }}
              >
                {row.image ? (
                  <CardMedia
                    component="img"
                    alt="Certification Image"
                    height="140"
                    image={row.image}
                  />
                ) : null}
                <CardContent sx={{ height: '100%' }}>
                  {/* <Box
                  component="img"
                  sx={{
                    height: 233,
                    width: 350,
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                  }}
                  alt="Certification Image"
                  src={row}
                /> */}
                  <Typography className="fredoka">{row.name}</Typography>
                  {row.description ? <p>{row.description}</p> : null}
                  {row.authority ? (
                    <p>
                      {/* {`${t('authority')}: ${row.authority}`} */}
                      <span className={classes.boldText}>{`${t(
                        'authority'
                      )}: `}</span>
                      {row.authority}
                    </p>
                  ) : null}
                  {row.num ? (
                    <p>
                      {/* {`${t('protocolNum')}: ${row.num}`} */}
                      <span className={classes.boldText}>{`${t(
                        'protocolNum'
                      )}: `}</span>
                      {row.num}
                    </p>
                  ) : null}
                  {Array.isArray(row.documents) ? (
                    <Box sx={{}}>
                      {row.documents.map((doc) => (
                        <Button
                          variant="outlined"
                          color="ctaButton"
                          sx={{ mt: 1 }}
                          startIcon={<DownloadIcon />}
                          onClick={() =>
                            downloadDocument(doc.name, doc.fileUrl)
                          }
                        >
                          {doc.name.length > 26
                            ? doc.name.substring(0, 18) +
                              ' ... ' +
                              doc.name.slice(-6)
                            : doc.name}
                        </Button>
                      ))}
                    </Box>
                  ) : null}
                  {/* {Array.isArray(row.documents) && row.documents.map((doc) => (
                  <Button variant="outlined" startIcon={<DownloadIcon />}  onClick={() => downloadDocument(doc.name, doc.fileUrl)}>
                    {doc.name}
                  </Button>
                ))} */}
                </CardContent>
              </Card>
            </GridItem>
          ))}
        </GridContainer>
      </div>
    </div>
  )
}

export default CertificationsTable
