import React, { useContext } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, useTheme } from '@mui/material'
import Button from 'components/CustomButtons/Button'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'
// import { makeStyles } from 'tss-react/mui'
// import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'

// const useStyles = makeStyles()(presentationStyle)

import './style.css'

const DescriptionDialog = (props) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [fullWidth] = React.useState(true)
  const [maxWidth] = React.useState('sm')
  const { t } = useTranslation('descriptionDialog')

  // stato globale dell'app estrapolato dal context
  const { state, dispatch } = useContext(AppContext)
  const { openTrackDesc, selectedTrackDesc } = state

  // funzione che chiude modal documenti
  const handleCloseTrackDesc = () => {
    dispatch({
      type: 'SET_OPEN_TRACK_DESC',
      payload: false
    })
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      scroll='paper'
      open={openTrackDesc}
      onClose={handleCloseTrackDesc}
      aria-labelledby='track-description-dialog'
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle id='track-description-dialog' className='fredoka'>{`${t('title')}: ${selectedTrackDesc.name}`}</DialogTitle>
      <DialogContent dividers style={{ padding: 0 }}>
        {selectedTrackDesc.imgUrl ? <img alt={selectedTrackDesc.name} src={selectedTrackDesc.imgUrl} style={{ width: '100%', height: 'auto' }} /> : null}
        <Typography component='pre' style={{ width: '100%', whiteSpace: 'pre-wrap', padding: 16, fontFamily: "'Raleway', sans-serif" }}>{selectedTrackDesc.description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseTrackDesc} color='generalButton'>
          {t('closeButton')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DescriptionDialog
