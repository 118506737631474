import React, { useContext, useCallback, useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'
import {
  Typography,
  //IconButton ,
  Link
} from '@mui/material'
import { LocationOn as LocationOnIcon } from '@mui/icons-material'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// import RoomIcon from '@mui/icons-material/Room'
// import Dialog from '@mui/material/Dialog'
// import DialogActions from '@mui/material/DialogActions'
// import DialogContent from '@mui/material/DialogContent'
// import DialogContentText from '@mui/material/DialogContentText'
// import DialogTitle from '@mui/material/DialogTitle'
// import SwapCallsIcon from '@mui/icons-material/SwapCalls'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Map from 'components/Map/Map'
import Button from 'components/CustomButtons/Button'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import {
  hideOrShowProperty
  //getRandomColor
} from 'utils/utils'
import organizationToRules from 'organizationToRules'
//import XdaiTable from './XdaiTable'

const useStyles = makeStyles()(presentationStyle)

// funzione che ritorna il corpo della tabella degli input/output

// const inputsOutputsTableDoby = (typeOfElement, track, inputs = [], outputs = []) => {
//   const array = [...Array(!outputs ? inputs.length : inputs.length >= outputs.length ? inputs.length : outputs.length)]
//   const finalArray = []
//   array.forEach((el, i) => {
//     const newLine = []
//     if (inputs && inputs[i] && track.inputs[i]) {
//       newLine[0] = (
//         <Grid key={`${track.name}-input-grid-${i.toString()}`} style={{textAlign:"start"}}><b>{`${inputs[i].productName}`}</b><br />
//         </Grid>
//       )
//     } else {
//       newLine[0] = ''
//     }
//     if (outputs && outputs[i] && track.outputs[i]) {
//       newLine[1] = (
//         <Grid key={`${track.name}-output-grid-${i.toString()}`} style={{textAlign:"start"}}><b key={i.toString()} >{`${outputs[i].productName} `}</b><br />
//         </Grid>
//       )
//     } else {
//       newLine[1] = ''
//     }

//     finalArray.push(newLine)
//   })

//   return finalArray.map((elem, i) => {
//     if (typeOfElement === 'list') {
//       if (i > 1) {
//         return null
//       }
//     }
//     return (
//       <div key={`${track.name}-elem-div-${i.toString()}`}>
//         <div key={`${track.name}-input-div-${i.toString()}`} className='raleway inputList'>
//           {elem[0]}
//         </div>
//         {outputs && outputs.length > 0 &&
//           <div key={`${track.name}-output-div-${i.toString()}`} className='raleway inputList'>
//             {elem[1]}
//           </div>}
//       </div>
//     )
//   })
// }
const inputsOutputsTableDoby = (track, inputs = [], outputs = []) => {
  const array = [
    ...Array(
      !outputs
        ? inputs.length
        : inputs.length >= outputs.length
        ? inputs.length
        : outputs.length
    )
  ]
  const finalArray = []
  array.forEach((el, i) => {
    const newLine = []
    if (inputs && inputs.length > 0 && inputs[i] && track.inputs[i]) {
      newLine[0] = (
        <Grid
          key={`${track.name}-input-grid-${i.toString()}`}
          style={{ textAlign: 'start' }}
        >
          <li>
            <b>{`${inputs[i].productName}`}</b>
          </li>
        </Grid>
      )
    } else {
      newLine[0] = ''
    }
    if (outputs && outputs[i] && track.outputs[i]) {
      newLine[1] = (
        <Grid
          key={`${track.name}-output-grid-${i.toString()}`}
          style={{ textAlign: 'start' }}
        >
          <li>
            <b key={i.toString()}>{`${outputs[i].productName} `}</b>
          </li>
        </Grid>
      )
    } else {
      newLine[1] = ''
    }

    finalArray.push(newLine)
  })

  return finalArray.map((elem, i) => {
    return (
      <div key={`${track.name}-elem-div-${i.toString()}`}>
        <div
          key={`${track.name}-input-div-${i.toString()}`}
          className='raleway inputList'
        >
          {elem[0]}
        </div>
        {outputs && outputs.length > 0 && (
          <div
            key={`${track.name}-output-div-${i.toString()}`}
            className='raleway inputList'
          >
            {elem[1]}
          </div>
        )}
      </div>
    )
  })
}

/*
    come devo avere l'oggetto Location:
    {
        name: ''
        uuid: ''
        lat: ''
        long: ''
    }
*/
const ProductTraceabilityCard = (props) => {
  const { classes } = useStyles()
  // const isSmall = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation('traceabilityCard')

  // const { track, index, organizationId, events, glnName, gtinName, totalPhases } = props
  const { track, index, organizationId, totalPhases } = props

  const [truncatedDescription, setTruncatedDescription] = React.useState()
  // const [typeOfUi, setTypeOfUi] = React.useState('list')

  // const [openMaterials, setOpenMaterials] = React.useState(false)

  // const handleClickOpenMaterials = () => {
  //   setOpenMaterials(true)
  //   setTypeOfUi('modal')
  // }

  // const handleCloseMaterials = () => {
  //   setOpenMaterials(false)
  //   setTypeOfUi('list')
  // }

  // stato globale dell'app estrapolato dal context
  const { state, dispatch } = useContext(AppContext)

  const { product, discover, facilityUiidNameMap } = state
  console.log('facilityUiidNameMap', facilityUiidNameMap, track)
  // funzione che mostra/nasconde la mappa di una card nella tracciabilità
  const toggleMap = (cardIndex, showOnly = false) => {
    const prod = Object.assign({}, product)
    const traceability = prod.traceability
    const { activeSection, ...track } = traceability[cardIndex]
    traceability.splice(cardIndex, 1, {
      activeSection: {
        card: cardIndex,
        showMap: showOnly ? true : !activeSection.showMap
      },
      ...track
    })
    prod.traceability = traceability
    dispatch({
      type: 'SET_PRODUCT',
      payload: prod
    })
  }

  // funzione che apre modal descrizione traccia
  const handleOpenTrackDesc = (description, name, imgUrl) => {
    dispatch({
      type: 'SET_TRACK_DESC',
      payload: { description, name, imgUrl }
    })
    dispatch({
      type: 'SET_OPEN_TRACK_DESC',
      payload: true
    })
  }

  // Uso una funzione di callback perchè riusciamo a capire
  // le dimensioni del nodo anche se viene renderizzato successivamente
  const lengthRef = useCallback(
    (node) => {
      if (node !== null) {
        dispatch({
          type: 'SET_PRODUCT',
          payload: product
        })
      }
    },
    [product, dispatch]
  )

  // funzione che torna il bottone di mostra di più per le descrizioni troncate
  const addShowMoreButton = (id, description, name, imgUrl) => {
    const elem = document.getElementById(id)
    if (elem) {
      return (
        // <Box display='flex' justifyContent='flex-start' alignItems='center' style={{ width: '100%', marginTop: 30 }}>
        // <IconButton onClick={() => handleOpenTrackDesc(description, name, imgUrl)} style={{ cursor: 'pointer', color: ' #3C4858' }}>
        //   <AddCircleOutlineIcon />
        // </IconButton>
        <Link
          color={'#00A4AE'}
          underline='always'
          onClick={() => handleOpenTrackDesc(description, name, imgUrl)}
        >
          {t('readMore').toLocaleUpperCase() + ' >>'}
        </Link>
        // </Box>
      )
    }
  }

  useEffect(() => {
    if (track.media?.length === 0) {
      toggleMap(index, true)
    }

    if (track.description.length > 380) {
      const shortDescription = track.description.substring(0, 380)

      const elementToShow = (
        <div style={{ display: 'flex', alignItems: 'end' }}>
          <Typography
            fontFamily='Raleway, sans-serif'
            fontSize='14px'
            sx={{ paddingRight: '3%' }}
          >
            {shortDescription} {' ...'}{' '}
            {track.description.length > 100 &&
              addShowMoreButton(
                `${track.name}-${index}`,
                track.description,
                track.name,
                track.media?.length > 0 ? track.media[0].cropped : null
              )}
          </Typography>
          {/* <div style={{marginRight:"3%"}}>
        {addShowMoreButton(`${track.name}-${index}`, track.description, track.name, track.media?.length > 0 ? track.media[0].cropped : null)}    
        </div> */}
        </div>
      )
      setTruncatedDescription(elementToShow)
    } else {
      setTruncatedDescription(
        track.description !== '-' ? track.description : null
      )
    }

    // eslint-disable-next-line
  }, [])

  function SingleSource({ src }) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <img
          alt='location-img'
          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
          src={src}
        />
      </div>
    )
  }

  const getPositionsLocationFromTo = () => {
    const toReturn = []
    track.locationFrom.map((locFrom) =>
      toReturn.push({
        position: [
          facilityUiidNameMap[locFrom].geolocation.lat,
          facilityUiidNameMap[locFrom].geolocation.lng
        ],
        iconUrl: '/marker/marker_red.svg',
        popup: <p>{facilityUiidNameMap[locFrom].name}</p>
      })
    )
    track.locationTo.map((locTo) =>
      toReturn.push({
        position: [
          facilityUiidNameMap[locTo].geolocation.lat,
          facilityUiidNameMap[locTo].geolocation.lng
        ],
        iconUrl: '/marker/marker_green.svg',
        popup: <p>{facilityUiidNameMap[locTo].name}</p>
      })
    )
    return toReturn
  }
  const getMultiPositionsLocationFromTo = () => {
    const toReturn = []
    //const toReturnColor = []
    track.locationFrom.map((locFrom) => {
      track.locationTo.map((locTo) => {
        toReturn.push([
          [
            facilityUiidNameMap[locFrom].geolocation.lat,
            facilityUiidNameMap[locFrom].geolocation.lng
          ],
          [
            facilityUiidNameMap[locTo].geolocation.lat,
            facilityUiidNameMap[locTo].geolocation.lng
          ]
        ])
        //toReturnColor.push(getRandomColor())
        return null
      })
      return null
    })

    return toReturn //{colors:toReturnColor ,positions:toReturn}
  }

  // NOTE: potrebbe esserci il caso che sia locationTo che locationFrom che location siano settate. In quel caso viene graficato sulla mappa location.
  // const handleMapAndText = (track, facilityUiidNameMap, index) => {
  //   if (track.activeSection.showMap) {
  //     if (track.location && facilityUiidNameMap[track.location]) {
  //       return (
  //         <div className={classes.imgContainer} onMouseLeave={() => track.media?.length > 0 ? toggleMap(index) : null}>
  //           {facilityUiidNameMap[track.location].geolocation?.lat && facilityUiidNameMap[track.location].geolocation?.lng &&
  //             <Map isHeader lat={facilityUiidNameMap[track.location].geolocation?.lat} lng={facilityUiidNameMap[track.location].geolocation?.lng} />}
  //         </div>
  //       )
  //     }
  //     if (track.locationFrom && facilityUiidNameMap[track.locationFrom[0]] &&
  //       track.locationTo && facilityUiidNameMap[track.locationTo[0]]) {
  //       return (
  //         <div className={classes.imgContainer} onMouseLeave={() => track.media?.length > 0 ? toggleMap(index) : null}>
  //           {facilityUiidNameMap[track.locationFrom[0]].geolocation?.lat && facilityUiidNameMap[track.locationFrom[0]].geolocation?.lng && facilityUiidNameMap[track.locationTo[0]].geolocation?.lat && facilityUiidNameMap[track.locationTo[0]].geolocation?.lng &&
  //             <Map isHeader zoom={14} positions={[[facilityUiidNameMap[track.locationFrom[0]].geolocation?.lat, facilityUiidNameMap[track.locationFrom[0]].geolocation?.lng], [facilityUiidNameMap[track.locationTo[0]].geolocation?.lat, facilityUiidNameMap[track.locationTo[0]].geolocation?.lng]]} lat={facilityUiidNameMap[track.locationTo[0]].geolocation?.lat} lng={facilityUiidNameMap[track.locationTo[0]].geolocation?.lng} />}
  //         </div>
  //       )
  //     }
  //   } else {
  //     return (
  //       <> <div onMouseEnter={() => toggleMap(index)} className={`${classes.imgContainer} noImage`} style={track.media?.length > 0 ? { backgroundImage: `url('${track.media[0].cropped}')` } : {}} />
  //       </>
  //     )
  //   }
  // }
  console.log(
    'TRACK',
    track,
    track.locationFrom,
    track.locationFrom &&
      facilityUiidNameMap[track.locationFrom] &&
      track.locationTo &&
      facilityUiidNameMap[track.locationTo] &&
      track.activeSection.showMap
  )
  // track.locationFromName=["altissimo", "pierissimo spa", "pippissimo", "plutissomo oods spa"]
  // track.locationToName=["altissimo", "pierissimo spa", "pippissimo", "plutissomo oods spa"]
  return (
    <Grid item xs={12} md={12} className={classes.mediaAndMap}>
      <GridContainer
        direction='row'
        justify='flex-start'
        style={{ padding: 0 }}
      >
        {/* start MAPPA ed Immagini  */}
        <GridItem className={classes.mediaAndMapFirstGridItem} xs={12} sm={6}>
          <div>
            {hideOrShowProperty(discover, 'allLocations', 'lot') && (
              <GridItem style={{ padding: '2%', textAlign: 'center' }} xs={12}>
                <GridItem style={{ padding: '0', aspectRatio: '4/3' }} xs={12}>
                  {hideOrShowProperty(discover, 'allLocations', 'lot') &&
                  track.location &&
                  facilityUiidNameMap[track.location] &&
                  track.activeSection.showMap ? (
                    <div
                      className={`${classes.mapContainer} noImage`}
                      style={{ height: '100%' }}
                    >
                      {facilityUiidNameMap[track.location].geolocation?.lat &&
                        facilityUiidNameMap[track.location].geolocation
                          ?.lng && (
                          <Map
                            zoom={5}
                            isHeader
                            lat={
                              facilityUiidNameMap[track.location].geolocation
                                .lat
                            }
                            lng={
                              facilityUiidNameMap[track.location].geolocation
                                .lng
                            }
                          />
                        )}
                    </div>
                  ) : track.locationFrom &&
                    track.locationFrom.length &&
                    facilityUiidNameMap[track.locationFrom[0]] &&
                    track.locationTo &&
                    track.locationTo.length &&
                    facilityUiidNameMap[track.locationTo[0]] &&
                    track.activeSection.showMap ? (
                    <div
                      className={`${classes.mapContainer} noImage`}
                      style={{ height: '100%' }}
                    >
                      {facilityUiidNameMap[track.locationFrom[0]].geolocation
                        ?.lat &&
                        facilityUiidNameMap[track.locationFrom[0]].geolocation
                          ?.lng &&
                        facilityUiidNameMap[track.locationTo[0]].geolocation
                          ?.lat &&
                        facilityUiidNameMap[track.locationTo[0]].geolocation
                          ?.lng && (
                          //  <Map isHeader zoom={8} positions={[[facilityUiidNameMap[track.locationFrom[0]].geolocation.lat, facilityUiidNameMap[track.locationFrom[0]].geolocation.lng], [facilityUiidNameMap[track.locationTo[0]].geolocation.lat, facilityUiidNameMap[track.locationTo[0]].geolocation.lng]]} lat={facilityUiidNameMap[track.locationTo[0]].geolocation.lat} lng={facilityUiidNameMap[track.locationTo[0]].geolocation.lng} />
                          <Map
                            isHeader
                            zoom={5}
                            multiPositions={getMultiPositionsLocationFromTo()}
                            positions={getPositionsLocationFromTo()}
                            lat={
                              facilityUiidNameMap[track.locationTo[0]]
                                .geolocation.lat
                            }
                            lng={
                              facilityUiidNameMap[track.locationTo[0]]
                                .geolocation.lng
                            }
                          />
                        )}
                    </div>
                  ) : (
                    <div
                      className={classes.mapContainer}
                      style={{ height: '100%' }}
                    >
                      {track.media?.length > 0 ? (
                        <SingleSource
                          key={track.media[0].cropped}
                          src={track.media[0].cropped}
                        />
                      ) : null}
                    </div>
                  )}
                  {/* <div className={classes.imgContainer} style={track.media?.length > 0 ? { minHeight: 150, backgroundImage: `url('${track.media[0].cropped}')` } : {}}>
            </div> */}
                </GridItem>
              </GridItem>
            )}
            <Box sx={{ marginBottom: '1%' }}>
              {(facilityUiidNameMap[track?.location]?.geolocation?.lat &&
                facilityUiidNameMap[track?.location]?.geolocation?.lng) ||
              (facilityUiidNameMap[track?.locationFrom?.[0]]?.geolocation
                ?.lat &&
                facilityUiidNameMap[track?.locationFrom?.[0]]?.geolocation
                  ?.lng &&
                facilityUiidNameMap[track?.locationTo?.[0]]?.geolocation?.lat &&
                facilityUiidNameMap[track?.locationTo?.[0]]?.geolocation
                  ?.lng) ? (
                <Button
                  style={{
                    backgroundColor: '#00A4AE',
                    zIndex: 1000,
                    margin: 0,
                    marginRight: '2%'
                  }}
                  //className='mapButton'
                  round
                  //justIcon
                  onClick={() => toggleMap(index)}
                  // disabled = {!(facilityUiidNameMap[track.location]?.geolocation?.lat && facilityUiidNameMap[track.location]?.geolocation?.lng)}
                >
                  {/* <i title={track.activeSection.showMap ? t('showImage') : t('showMap')} className={track.activeSection.showMap ? 'fas fa-image' : 'fas fa-map-marker-alt'} /> */}
                  <b>
                    {track.activeSection.showMap
                      ? t('showImage')
                      : t('showMap')}
                  </b>
                </Button>
              ) : null}
            </Box>
          </div>
        </GridItem>

        {/* end MAPPA ed Immagini  */}
        {/* start info testuali */}
        <GridItem sm={6} key={`${track.name}-${index}-grid-item-dx`}>
          {/* NOME FASE */}
          {/* <span className={`${classes.nameTraceability}`}>{t('phase')} {index + 1}/{totalPhases}</span> */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <span
              className={`${classes.nameTraceability}`}
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              {t('phaseOf', { phaseNumber: index + 1, phaseTot: totalPhases })}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <h4 className={`${classes.cardTitleDesktop}`}>{track.name}</h4>
            {/* <Button
                style={{ backgroundColor: '#00A4AE', zIndex: 1000 , marginRight:"3%" }}
                //className='mapButton'
                round
                justIcon
                onClick={() => toggleMap(index)}
              >
                <i title={track.activeSection.showMap ? t('showImage') : t('showMap')} className={track.activeSection.showMap ? 'fas fa-image' : 'fas fa-map-marker-alt'} />
              </Button> */}
          </div>
          <Typography
            ref={lengthRef}
            id={`${track.name}-${index}`}
            variant='body2'
            style={{
              marginBottom: 8,
              marginTop: 8,
              width: '100%',
              textAlign: 'left',
              color: '#000'
            }}
            className={classes.cardDescription}
          >
            {truncatedDescription}
          </Typography>

          {/* LOCATION  */}
          {(!organizationToRules[organizationId] ||
            (organizationToRules[organizationId] &&
              !organizationToRules[organizationId].tags)) &&
            (track.location ? (
              <GridContainer
                style={{
                  width: '100%',
                  margin: '10px 0',
                  display: 'flex',
                  justifyContent: 'start'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      style={{ width: '100%', textAlign: 'start' }}
                      className='fredoka'
                    >
                      {t('location')}
                    </Typography>
                    <h6>
                      <b>{track.locationName}</b>
                    </h6>
                  </div>
                  {/* {
                  (facilityUiidNameMap[track.location]?.geolocation?.lat && facilityUiidNameMap[track.location]?.geolocation?.lng) ? 
                  (<Button
                    style={{ backgroundColor: '#00A4AE', zIndex: 1000 , marginRight:"3%" }}
                    //className='mapButton'
                    round
                    justIcon
                    onClick={() => toggleMap(index)}
                    // disabled = {!(facilityUiidNameMap[track.location]?.geolocation?.lat && facilityUiidNameMap[track.location]?.geolocation?.lng)}
                  >
                    <i title={track.activeSection.showMap ? t('showImage') : t('showMap')} className={track.activeSection.showMap ? 'fas fa-image' : 'fas fa-map-marker-alt'} />
                  </Button>):
                  null
                } */}
                </div>
              </GridContainer>
            ) : // <GridContainer className='metaContainer'>
            //   {hideOrShowProperty(discover, 'allLocations', 'lot') && <>
            //     <GridItem md={4} lg={4} />
            //     <GridItem md={2} lg={4} className='metaInnerContainer metaInnerContainerCenter'>
            //       <div key={`${track.name}-${index}-div-3`} style={{ paddingTop: 5 }}>
            //         <LocationOnIcon style={{ color: '#3C4858' }} />
            //       </div>
            //       <div key={`${track.name}-${index}-div-4`} style={{ paddingTop: 15, textTransform: 'uppercase', textAlign: 'center' }}>
            //         <span style={{ textTransform: 'uppercase' }}>
            //       {track.locationName}
            //     </span>
            //       </div>
            //     </GridItem>
            //  </>}
            // </GridContainer>
            track.locationFrom &&
              Array.isArray(track.locationFrom) &&
              track.locationFrom.length > 0 &&
              track.locationTo &&
              Array.isArray(track.locationTo) &&
              track.locationTo.length > 0 ? (
              <GridContainer
                style={{
                  width: '100%',
                  margin: '10px 0',
                  display: 'flex',
                  justifyContent: 'start'
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    style={{ width: '100%', textAlign: 'start' }}
                    className='fredoka'
                  >
                    {t('location')}
                  </Typography>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginRight: '2%'
                  }}
                >
                  <GridItem md={5} lg={4} className='metaInnerContainer'>
                    {hideOrShowProperty(discover, 'allLocations', 'lot') && (
                      <>
                        <div
                          key={`${track.name}-${index}-div-3`}
                          style={{ paddingTop: 5 }}
                        >
                          <LocationOnIcon style={{ color: '#E74B3C' }} />
                        </div>
                        <div style={{ textAlign: 'center', paddingTop: 15 }}>
                          <span style={{ textTransform: 'uppercase' }}>
                            {track.locationFrom ? (
                              <ul style={{ paddingLeft: 20 }}>
                                {track.locationFrom?.map((locFrom) => (
                                  <li style={{ textAlign: 'left' }}>
                                    {facilityUiidNameMap[locFrom]?.name}{' '}
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              track.locationFromName
                            )}
                            {/* {track.locationsFromName && Array.isArray(track.locationsFromName)? 
                      <ul>
                        {track.locationsFromName.map((locNameFrom) => 
                        <li style={{textAlign: "left"}}>
                          {locNameFrom}
                        </li> 
                        )
                        }        
                      </ul>
                      : track.locationFromName} */}
                          </span>
                        </div>
                      </>
                    )}
                  </GridItem>

                  <GridItem md={2} lg={4} className='metaInnerContainer'>
                    <div className='locationIcon' style={{ paddingTop: 10 }}>
                      <LocalShippingIcon style={{ color: '#3C4858' }} />
                    </div>
                  </GridItem>
                  <GridItem md={5} lg={4} className='metaInnerContainer'>
                    {hideOrShowProperty(discover, 'allLocations', 'lot') && (
                      <>
                        <div
                          key={`${track.name}-${index}-div-3`}
                          style={{ paddingTop: 5 }}
                        >
                          <LocationOnIcon style={{ color: '#52E73C' }} />
                        </div>
                        <div style={{ textAlign: 'center', paddingTop: 15 }}>
                          <span style={{ textTransform: 'uppercase' }}>
                            {track.locationFrom ? (
                              <ul style={{ paddingLeft: 20 }}>
                                {track.locationTo?.map((locTo) => (
                                  <li style={{ textAlign: 'left' }}>
                                    {facilityUiidNameMap[locTo]?.name}{' '}
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              track.locationToName
                            )}
                            {/* {track.locationsToName && Array.isArray(track.locationsToName)? 
                      <ul>
                        {track.locationsToName.map((locNameTo) => 
                        <li style={{textAlign: "left"}}>
                          {locNameTo}
                        </li> 
                        )
                        }        
                      </ul>
                      : track.locationToName} */}
                          </span>
                        </div>
                      </>
                    )}
                  </GridItem>
                  {/* {
                  (facilityUiidNameMap[track.locationFrom?.[0]]?.geolocation?.lat && facilityUiidNameMap[track.locationFrom?.[0]]?.geolocation?.lng && facilityUiidNameMap[track.locationTo?.[0]]?.geolocation?.lat && facilityUiidNameMap[track.locationTo?.[0]]?.geolocation?.lng) ? 
                  (<Button
                    style={{ backgroundColor: '#00A4AE', zIndex: 1000 , marginRight:"3%" }}
                    //className='mapButton'
                    round
                    justIcon
                    onClick={() => toggleMap(index)}
                    // disabled = {!(facilityUiidNameMap[track.locationFrom?.[0]]?.geolocation?.lat && facilityUiidNameMap[track.locationFrom?.[0]]?.geolocation?.lng && facilityUiidNameMap[track.locationTo?.[0]]?.geolocation?.lat && facilityUiidNameMap[track.locationTo?.[0]]?.geolocation?.lng)}
                  >
                    <i title={track.activeSection.showMap ? t('showImage') : t('showMap')} className={track.activeSection.showMap ? 'fas fa-image' : 'fas fa-map-marker-alt'} />
                  </Button>):
                  null
                } */}
                </div>
                {/* <GridItem md={5} lg={4} className='metaInnerContainer'>

                  {hideOrShowProperty(discover, 'allLocations', 'lot') && 
                  <>
                    <div key={`${track.name}-${index}-div-3`} style={{ paddingTop: 5 }}>
                      <LocationOnIcon style={{ color: '#3C4858' }} />
                    </div>
                    <div style={{ textAlign: 'center', paddingTop: 15 }}>
                      <span style={{ textTransform: 'uppercase' }}>
                    {track.locationFromName}
                  </span>
                    </div>
                  </>}
                </GridItem>

                <GridItem md={2} lg={4} className='metaInnerContainer'>
                  <div className='locationIcon' style={{ paddingTop: 10 }}>
                    <LocalShippingIcon style={{ color: '#3C4858' }} />
                  </div>
                </GridItem>
                <GridItem md={5} lg={4} className='metaInnerContainer'>
                  {hideOrShowProperty(discover, 'allLocations', 'lot') && <>
                    <div key={`${track.name}-${index}-div-3`} style={{ paddingTop: 5 }}>
                      <LocationOnIcon style={{ color: '#3C4858' }} />
                    </div>
                    <div style={{ textAlign: 'center', paddingTop: 15 }}>
                      <span style={{ textTransform: 'uppercase' }}>
                    {track.locationToName}
                  </span>
                    </div>
                  </>}
                </GridItem> */}
              </GridContainer>
            ) : (
              ''
            ))}

          {/* MATERIALI */}

          {/* <GridContainer className='materialsContainer'>
            {(!organizationToRules[organizationId] || (organizationToRules[organizationId] && !organizationToRules[organizationId].tags)) 
            ? (track.outputs && track.outputs.length > 0)
              ? <>
              {hideOrShowProperty(discover, 'allInputs', 'lot') && <GridItem md={5} lg={4} className='metaInnerContainer'>
                <Typography style={{ textAlign: 'center', width: '100%' }} className='fredoka'>
                  {t('usedMaterials')}
                </Typography>
                {inputsOutputsTableDoby(typeOfUi, track, track.inputs)}
                {track.inputs.length > 2 &&
                  <div>
                <span
                    style={{
                      cursor: 'pointer',
                      marginTop: 30,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      textDecoration: 'underline'
                    }}
                    color='primary' onClick={handleClickOpenMaterials}
                  >
                    <b>{t('other')}</b>
                  </span>
                <Dialog
                    maxWidth='lg'
                    className='materialsDialog'
                    open={openMaterials}
                    onClose={handleCloseMaterials}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                  >
                    <DialogTitle id='alert-dialog-title'>
                      <Typography variant='h4' className='fredoka'>{t('usedMaterials')}</Typography>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id='alert-dialog-description'>
                        
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseMaterials} color='generalButton' autoFocus>
                        Chiudi
                      </Button>
                    </DialogActions>
                  </Dialog>
              </div>}
              </GridItem>}
              {hideOrShowProperty(discover, 'allOutputs', 'lot') &&
                <GridItem md={5} lg={4} className='metaInnerContainer'>
                  <Typography style={{ textAlign: 'center', width: '100%' }} className='fredoka'>
                {t('producedMaterials')}
              </Typography>
                  {inputsOutputsTableDoby(typeOfUi, track, [], track.outputs)}
                  {track.outputs.length > 2 &&
                <div>
                    <span
                      style={{
                        cursor: 'pointer',
                        marginTop: 30,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        textDecoration: 'underline'
                      }}
                      color='primary' onClick={handleClickOpenMaterials}
                    >
                      <b>{t('other')}</b>
                    </span>

                    <Dialog
                      className='materialsDialog'
                      maxWidth='lg'
                      open={openMaterials}
                      onClose={handleCloseMaterials}
                      aria-labelledby='alert-dialog-title'
                      aria-describedby='alert-dialog-description'
                    >
                      <DialogTitle id='alert-dialog-title'>
                        <Typography variant='h4' className='fredoka'>{t('producedMaterials')}</Typography>
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                          {inputsOutputsTableDoby(typeOfUi, track, [], track.outputs)}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseMaterials} color='generalButton' autoFocus>
                          Chiudi
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>}
                </GridItem>}
              </>
            : <>
              {hideOrShowProperty(discover, 'allInputs', 'lot') && <GridItem sm={12} className='metaInnerContainer'>
                <Typography style={{ textAlign: 'center', width: '100%' }} className='fredoka'>
                  {t('usedMaterials')}
                </Typography>
                {inputsOutputsTableDoby(typeOfUi, track, track.inputs)}
                {track.inputs.length > 2 &&
                  <div>
                <span
                    style={{
                      cursor: 'pointer',
                      marginTop: 30,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textDecoration: 'underline'
                    }} color='primary' onClick={handleClickOpenMaterials}
                  >
                    <b>{t('other')}</b>
                  </span>
                <Dialog
                    className='materialsDialog'
                    maxWidth='lg'
                    open={openMaterials}
                    onClose={handleCloseMaterials}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                  >
                    <DialogTitle class='fredoka'>
                      <Typography variant='h4' className='fredoka'>{t('usedMaterials')}</Typography>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id='alert-dialog-description'>
                        {inputsOutputsTableDoby(typeOfUi, track, track.inputs)}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseMaterials} color='generalButton' autoFocus>
                        Chiudi
                      </Button>
                    </DialogActions>
                  </Dialog>
              </div>}
              </GridItem>}
              </>
            : null
            }
             {(organizationToRules[organizationId] && organizationToRules[organizationId].tags) && 
          (events && Array.isArray(events) && events.length > 0) && 
          [events]
          .map(evts => 
            evts.filter(event => 
              event.tagsId.some(evt => track.tagsId.includes(evt))
            ))
          .map((evts) => <XdaiStorytelling events={evts} glnName={glnName} gtinName={gtinName} phaseIndex={index} organizationId={organizationId}></XdaiStorytelling>)
          // <XdaiTable events={evts} glnName={glnName} gtinName={gtinName}></XdaiTable>)
          }
          </GridContainer> */}

          {/* MATERIE */}
          <GridContainer style={{ width: '100%' }}>
            <GridItem>
              {(!organizationToRules[organizationId] ||
                (organizationToRules[organizationId] &&
                  !organizationToRules[organizationId].tags)) && (
                <Accordion defaultExpanded='true' className='accordionRoot'>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                  >
                    <Typography
                      style={{ textAlign: 'start', width: '100%' }}
                      className='fredoka'
                    >
                      {t('usedMaterials')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: 'block' }}>
                    {inputsOutputsTableDoby(track, track.inputs)}
                  </AccordionDetails>
                </Accordion>
              )}
              {track.outputs &&
                track.outputs.length > 0 &&
                (!organizationToRules[organizationId] ||
                  (organizationToRules[organizationId] &&
                    !organizationToRules[organizationId].tags)) && (
                  <Accordion defaultExpanded='true' className='accordionRoot'>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                    >
                      <Typography
                        style={{ textAlign: 'start', width: '100%' }}
                        className='fredoka'
                      >
                        {t('producedMaterials')}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                      {inputsOutputsTableDoby(track, [], track.outputs)}
                    </AccordionDetails>
                  </Accordion>
                )}
            </GridItem>
          </GridContainer>
        </GridItem>
        {/* end info testuali */}
      </GridContainer>
    </Grid>
  )
}

export default ProductTraceabilityCard
