const REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

export function uuidValidate(uuid) {
  return typeof uuid === 'string' && REGEX.test(uuid)
}
export const hideOrShowProperty = (discover, property, type) => {
  if (discover && Object.keys(discover).length > 0) {
    if (!discover.metadata.visibility[type][property]) {
      if (
        discover.metadata.visibility[type][property] === undefined ||
        discover.metadata.visibility[type][property] === null
      ) {
        return true
      }
    }
    return !!discover.metadata.visibility[type][property]
  } else {
    return true
  }
}

export const isTrackit = (domain) => {
  const subDomains = domain.split('.')
  if (subDomains.includes('trackit')) {
    return true
  } else {
    return false
  }
}

export const getRandomColor = () => {
  // Genera tre valori casuali per i componenti R, G e B
  const r = Math.floor(Math.random() * 256) // Valore compreso tra 0 e 255
  const g = Math.floor(Math.random() * 256)
  const b = Math.floor(Math.random() * 256)

  // Converti i valori R, G e B in formato esadecimale e concatena
  const color =
    '#' +
    r.toString(16).padStart(2, '0') +
    g.toString(16).padStart(2, '0') +
    b.toString(16).padStart(2, '0')

  return color
}

export const hasNonEmptyNutritionalValue = (data) => {
  // Controlla l'elemento 'energy' che ha una struttura diversa
  if (data.energy) {
    if (data.energy.kJ && data.energy.kJ.standardGrams) {
      return true
    }
    if (data.energy.kcal && data.energy.kcal.standardGrams) {
      return true
    }
  }

  // Controlla tutti gli altri elementi
  for (const key in data) {
    if (key !== 'energy' && data[key].standardGrams) {
      return true
    }
  }

  return false
}
