import React, { useContext } from 'react'
import { makeStyles } from 'tss-react/mui'
import useMediaQuery from '@mui/material/useMediaQuery'
import Skeleton from 'react-loading-skeleton'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { AppContext } from '../../context/AppState'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import './style.css'

const useStyles = makeStyles()(presentationStyle)

const AttributesGrid = () => {
  const { classes } = useStyles()
  const { state } = useContext(AppContext)
  const { product } = state

  const isSmall = useMediaQuery('(max-width: 600px)')

  return (
    <GridContainer className={classes.contentContainer} style={{ background: 'white' }}>
      {
      product && product.attributes
        ? product.attributes.map((attribute, index) => <GridItem key={`${attribute.name}${attribute.value}`} xs={12} md={6} xl={4} className={classes.attributeGrid} style={isSmall && index !== (product.attributes.length - 1) ? { paddingBottom: '0px' } : {}}>
          <b className={classes.fredoka}>{attribute.name || <Skeleton duration={0.7} />}</b>
          <div>{attribute.value || <Skeleton duration={0.7} count={3} />}</div>
                                                       </GridItem>)
        : [<GridItem key='skeleton-1' xs={12} md={6} xl={4} className={classes.attributeGrid} style={isSmall ? { paddingBottom: '0px' } : {}}>
          <h4><Skeleton duration={0.7} /></h4>
          <Skeleton duration={0.7} count={2} />
           </GridItem>,
          <GridItem key='skeleton-2' xs={12} md={6} xl={4} className={classes.attributeGrid} style={isSmall ? { paddingBottom: '0px' } : {}}>
            <h4><Skeleton duration={0.7} /></h4>
            <Skeleton duration={0.7} count={2} />
          </GridItem>]

    }
    </GridContainer>
  )
}
export default AttributesGrid
