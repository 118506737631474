import React, { useContext } from 'react'
import { makeStyles } from 'tss-react/mui'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle'
import { Dialog, DialogTitle, DialogContent, Box, Typography, List, ListItem, ListItemText, DialogActions, useTheme } from '@mui/material'
import DownLoadIcon from '@mui/icons-material/CloudDownload'
import useMediaQuery from '@mui/material/useMediaQuery'
import Button from 'components/CustomButtons/Button'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'

const useStyles = makeStyles()(presentationStyle)

const DocumentsDialog = () => {
  const { classes } = useStyles()
  const { t } = useTranslation('documentsDialog')

  // stato globale dell'app estrapolato dal context
  const { state, dispatch } = useContext(AppContext)
  const { openTrackDoc, selectedTrackDoc } = state

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isSmall = useMediaQuery('(max-width: 600px)')
  const [fullWidth] = React.useState(true)
  const [maxWidth] = React.useState('sm')

  // funzione che chiude modal documenti
  const handleCloseTrackDoc = () => {
    dispatch({
      type: 'SET_OPEN_TRACK_DOC',
      payload: false
    })
  }

  // Funzione che scarica il documento
  const downloadDocument = (name, url) => {
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = name
    a.click()
    window.URL.revokeObjectURL(url)
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      scroll='paper'
      open={openTrackDoc}
      onClose={handleCloseTrackDoc}
      aria-labelledby='track-document-dialog'
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle className='fredoka' id='track-document-dialog'>{t('title')}<br /> {selectedTrackDoc.name}</DialogTitle>
      <DialogContent dividers style={{ paddingLeft: 8, paddingRight: 8 }}>
        <Box p={2}>
          <Typography style={{ marginTop: '8px', color: '#9e9e9e', fontFamily: "'Raleway', sans-serif" }} align='center' component='h2' variant='body1'>{selectedTrackDoc.documents && selectedTrackDoc.documents.length > 0 ? t('downloadMessage') : t('noDocsMessage')}</Typography>
        </Box>
        <List>
          {selectedTrackDoc.documents && selectedTrackDoc.documents.map((document, docIndex) => <ListItem style={{ margin: '16px 0', backgroundColor: docIndex % 2 === 0 ? 'white' : '#fafafa', padding: '16px', fontFamily: "'Fredoka One', sans-serif", borderBottom: '1px solid #eaeaea', flexDirection: 'column' }} key={`doc-${docIndex}`}>
            <ListItemText
              primary={<div style={{ fontFamily: "Fredoka One', cursive", width: '100%' }} className={isSmall ? classes.textEllipsisMobile : classes.textEllipsis}>
                {document.name || t('defaultName')}
                       </div>}
            />

            <Button
              style={{ fontFamily: "'Fredoka One', sans-serif" }}
              classes={{ containedPrimary: classes.chipPrimary }} onClick={() => downloadDocument(document.name, document.fileUrl)} variant='contained' color='ctaButton' endIcon={<DownLoadIcon />}
            >
              {t('downloadButton')}
            </Button>

                                                                                                </ListItem>)}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseTrackDoc} color='generalButton'>
          {t('closeButton')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DocumentsDialog
