import React, { useEffect, useContext } from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from 'tss-react/mui'
import useMediaQuery from '@mui/material/useMediaQuery'
import { AppContext } from '../../context/AppState'
// import { useTranslation } from 'react-i18next'
// import { domainToLogos } from '../../domainToLogos'
// core components
import Dropdown from 'components/CustomDropdown/CustomDropdown.js'

import { isLocalhost, domain } from 'serviceWorker'
import Parallax from 'components/Parallax/Parallax.js'
// import RegularButton from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
// import CardHeader from 'components/Card/CardHeader.js'
// import CardBody from 'components/Card/CardBody.js'
// import Menu from 'components/Menu/Menu'
import NotFound from 'components/NotFound/NotFound'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Box } from '@mui/material'
import { useParams } from 'react-router'

import CompanyInfo from 'components/CompanyInfo/CompanyInfo.js'
import CompanyInfoMobile from 'components/CompanyInfo/CompanyInfoMobile.js'

import CertificationsTable from 'components/CertificationsTable/CertificationsTable.js'
// import AllergenesTable from 'components/AllergenesTable/AllergenesTable.js'
import Traceability from 'components/Traceability/Traceability.js'
import Disposal from 'components/Disposal/Disposal.js'
import ProductPage from 'components/ProductPages/ProductPage'
import CertDialog from 'components/CertDialog/CertDialog.js'
import DescriptionDialog from 'components/DescriptionDialog/DescriptionDialog.js'
import DocumentsDialog from 'components/DocumentsDialog/DocumentsDialog.js'
// import SocialLinks from 'components/SocialLinks/SocialLinks.js'
import LanguageIcon from '@mui/icons-material/Language'
//import NutritionalValue from '../../components/AttributesGrid/NutritionalValue'

import Avatar from '@mui/material/Avatar'
// import BuyNowModal from 'components/BuyNowModal/BuyNowModal.js'

import {
  getProduct,
  getProject,
  getDiscovers,
  getLightLotsTranslated,
  getCompany,
  populateProductData,
  getFacilities
} from '../../actions/AppActions'
// import { changeLanguage } from 'utils/translations'
// import CompanyVerificationBanner from 'components/CompanyVerificationBanner'
import { returnAbbr, changeLanguage, currLang } from 'utils/translations'
import PresentationPageMobile from './PresentationPageMobile'
import PresentationPageDesktop from './PresentationPageDesktop'
import LogoLoader from 'components/LogoLoaderImage/index'
import { alog } from 'utils/apioLog'
import { hideOrShowProperty, isTrackit } from '../../utils/utils'
import FooterTrackit from 'components/Footer/FooterTrackit'
import FooterTrusty from 'components/Footer/FooterTrusty'
import FooterTrackitDesktop from 'components/Footer/FooterTrackitDesktop'
import SocialLinks from 'components/SocialLinks/SocialLinks.js'
import MyAppBar from 'components/AppBar/MyAppBar'
import ProductTab from 'components/ProductTab/ProductTab'

const useStyles = makeStyles()(presentationStyle)

//const ga = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-LT5T6BXHS4');"
// main hook
export default function PresentationPage() {
  // const { t } = useTranslation('presentationPage')
  // const [openBuyNow, setOpenBuyNow] = React.useState(false)

  // React-router hook per prendere i parametri passati nell'url (uuid o gtin prodotto, uuid o lotNumber del lotto)
  const { prod, lot, version } = useParams()
  // stato globale dell'app e funzione che fa il dispatch delle azioni
  const { state, dispatch } = useContext(AppContext)

  const {
    sectionActive,
    product,
    project,
    informationFound,
    informationLoaded,
    company,
    discover,
    languageCode
  } = state

  alog('isLocalHost', isLocalhost, 'report')
  // const domainSplitted = domain.split('.')
  const [languageSelected, setLanguageSelected] = React.useState(
    returnAbbr(currLang)
  )

  alog('domain', domain.domain, 'domain', 'ok')
  // funzione che controlla il link al social e se manca l'http lo aggiunge
  const checkHttpInSocialLinks = (socialLinks) => {
    Object.keys(socialLinks).forEach((social) => {
      if (
        socialLinks[social] &&
        !socialLinks[social].startsWith('http://') &&
        !socialLinks[social].startsWith('https://')
      ) {
        socialLinks[social] = `http://${socialLinks[social]}`
      }
    })
    return socialLinks
  }

  const facilityParams = {
    limit: Number.MAX_SAFE_INTEGER
    // fields: 'name,gln,uuid,geolocation'
  }

  // funzione che scarica i dati del prodotto scansionato e della sua azienda produttrice
  async function fetchProducts(acceptsLanguage = null) {
    try {
      let product
      // NOTE:this is useful in case of a product with a version im a gtin
      if (version) {
        product = await getProduct(
          prod.concat(`${encodeURIComponent(`/22/${version}`)}`),
          acceptsLanguage
        )
      } else {
        product = await getProduct(prod, acceptsLanguage)
      }
      const project = await getProject(product.projectId)
      const company = await getCompany(product.companyId, acceptsLanguage)
      const facilities = await getFacilities(product.projectId, facilityParams)
      const facilityUiidNameMap = {}
      facilities.forEach((x) => {
        facilityUiidNameMap[x.resourceId] = x
      })
      product = populateProductData(product)

      if (product && product.social) {
        const { website, ...social } = checkHttpInSocialLinks({
          ...product.social,
          website: product.website
        })
        product.social = social
        product.website = website
      }

      dispatch({
        type: 'SET_PRODUCT',
        payload: product
      })
      dispatch({
        type: 'SET_PROJECT',
        payload: project
      })
      dispatch({
        type: 'SET_COMPANY',
        payload: company
      })
      dispatch({
        type: 'SET_FACILITIES',
        payload: facilities
      })
      dispatch({
        type: 'SET_FACILITYUUIDNAMEMAP',
        payload: facilityUiidNameMap
      })
      dispatch({
        type: 'SET_INFORMATIONLOADED',
        payload: true
      })
    } catch (e) {
      dispatch({
        type: 'SET_INFO_FOUND',
        payload: false
      })
    }
  }

  async function fetchDiscoverResource(params) {
    try {
      const discover = await getDiscovers(params)

      dispatch({
        type: 'SET_DISCOVER',
        payload: discover
      })
    } catch (e) {
      alog('No hiding', 'DEBUG', 'info')
    }
  }

  // funzione che cambia lingua in base all'elemento selezionato
  // only 24 european official languages
  const selectLanguage = (label) => {
    switch (label) {
      case 'ITA': {
        changeLanguage('it-IT')
        setLanguageSelected(returnAbbr('it-IT'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'it-IT'
        })
        break
      }
      case 'ENG': {
        changeLanguage('en-US')
        setLanguageSelected(returnAbbr('en-US'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'en-US'
        })
        break
      }
      case 'FRA': {
        changeLanguage('fr-FR')
        setLanguageSelected(returnAbbr('fr-FR'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'fr-FR'
        })
        break
      }
      case 'DEU': {
        changeLanguage('de-DE')
        setLanguageSelected(returnAbbr('de-DE'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'de-DE'
        })
        break
      }
      case 'ESP': {
        changeLanguage('es-ES')
        setLanguageSelected(returnAbbr('es-ES'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'es-ES'
        })
        break
      }
      case 'POR': {
        changeLanguage('pt-PT')
        setLanguageSelected(returnAbbr('pt-PT'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'pt-PT'
        })
        break
      }
      case 'NLD': {
        changeLanguage('nl-NL')
        setLanguageSelected(returnAbbr('nl-NL'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'nl-NL'
        })
        break
      }
      case 'POL': {
        changeLanguage('pl-PL')
        setLanguageSelected(returnAbbr('pl-PL'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'pl-PL'
        })
        break
      }
      case 'SWE': {
        changeLanguage('sv-SE')
        setLanguageSelected(returnAbbr('sv-SE'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'sv-SE'
        })
        break
      }
      case 'FIN': {
        changeLanguage('fi-FI')
        setLanguageSelected(returnAbbr('fi-FI'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'fi-FI'
        })
        break
      }
      case 'DAN': {
        changeLanguage('da-DK')
        setLanguageSelected(returnAbbr('da-DK'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'da-DK'
        })
        break
      }
      case 'CZE': {
        changeLanguage('cs-CZ')
        setLanguageSelected(returnAbbr('cs-CZ'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'cs-CZ'
        })
        break
      }
      case 'HUN': {
        changeLanguage('hu-HU')
        setLanguageSelected(returnAbbr('hu-HU'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'hu-HU'
        })
        break
      }
      case 'ROM': {
        changeLanguage('ro-RO')
        setLanguageSelected(returnAbbr('ro-RO'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'ro-RO'
        })
        break
      }
      case 'BUL': {
        changeLanguage('bg-BG')
        setLanguageSelected(returnAbbr('bg-BG'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'bg-BG'
        })
        break
      }
      case 'EST': {
        changeLanguage('et-EE')
        setLanguageSelected(returnAbbr('et-EE'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'et-EE'
        })
        break
      }
      case 'LAV': {
        changeLanguage('lv-LV')
        setLanguageSelected(returnAbbr('lv-LV'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'lv-LV'
        })
        break
      }
      case 'LIT': {
        changeLanguage('lt-LT')
        setLanguageSelected(returnAbbr('lt-LT'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'lt-LT'
        })
        break
      }
      case 'SLV': {
        changeLanguage('sl-SI')
        setLanguageSelected(returnAbbr('sl-SI'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'sl-SI'
        })
        break
      }
      case 'HRV': {
        changeLanguage('hr-HR')
        setLanguageSelected(returnAbbr('hr-HR'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'hr-HR'
        })
        break
      }
      case 'GLE': {
        changeLanguage('ga-IE')
        setLanguageSelected(returnAbbr('ga-IE'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'ga-IE'
        })
        break
      }
      case 'MLT': {
        changeLanguage('mt-MT')
        setLanguageSelected(returnAbbr('mt-MT'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'mt-MT'
        })
        break
      }
      case 'GRE': {
        changeLanguage('el-GR')
        setLanguageSelected(returnAbbr('el-GR'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'el-GR'
        })
        break
      }
      case 'CYM': {
        changeLanguage('cy-GB')
        setLanguageSelected(returnAbbr('cy-GB'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'cy-GB'
        })
        break
      }
      default: {
        changeLanguage('it-IT')
        setLanguageSelected(returnAbbr('it-IT'))
        dispatch({
          type: 'SET_LANGUAGECODE',
          payload: 'it-IT'
        })
        break
      }
    }
  }

  // funzione che fa il fetch di prodotto e lotto e setta i dati per le pagine

  // funzione che fa il fetch di prodotto e lotto e setta i dati per le pagine
  async function fetchLightLots(acceptsLanguage = null) {
    try {
      const lots = await getLightLotsTranslated(prod, lot, acceptsLanguage)
      // Se il lotto esiste, allora popolo i campi e li mostro, altrimenti mostro il prodotto con schermata di lotto non trovato
      if (Object.entries(lots).length > 0) {
        // const expirationDate = lots.expirationDate
        // dispatch({
        //   type: 'SET_EXPIRATION_DATE',
        //   payload: expirationDate
        // })
        dispatch({
          type: 'SET_LIGHT_LOT',
          payload: lots
        })
        dispatch({
          type: 'SET_LIGHT_LOT_LOADED',
          payload: true
        })

        dispatch({
          type: 'SET_INFORMATIONLOADED',
          payload: true
        })
      } else {
        dispatch({
          type: 'SET_LIGHT_LOT_NOT_FOUND',
          payload: true
        })
      }
    } catch (e) {
      dispatch({
        type: 'SET_LIGHT_LOT_NOT_FOUND',
        payload: true
      })
    }
  }

  useEffect(() => {
    fetchProducts(languageCode)
    fetchDiscoverResource({ gtin: prod })
    if (!lot) {
      dispatch({
        type: 'SET_MENU_SECTION',
        payload: 1
      })
    } else {
      fetchLightLots(languageCode)
      dispatch({
        type: 'SET_MENU_SECTION',
        payload: 4
      })
      dispatch({
        type: 'SET_URL_HAS_LOT',
        payload: true
      })
      dispatch({
        type: 'SET_LOT_NUMBER',
        payload: lot
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageSelected])

  useEffect(() => {
    if (product.companyId) {
      let codeGa = ''
      switch (product.companyId) {
        case 'd3dfe2b7-8adf-4191-b657-1b6004025cfe': {
          codeGa = 'G-ZW7Z6KWGHV'
          break
        }
        case '6f091109-4f54-455b-b0c7-7e9af67c3243': {
          codeGa = 'G-VV5EMC886T'
          break
        }
        case 'de231ee7-e975-4d60-b219-d70714b1e2ec': {
          codeGa = 'G-LT5T6BXHS4'
          break
        }
        case '6780b2e4-8cff-425d-879d-a567278266c8': {
          codeGa = 'G-DX0Y0S4WFS'
          break
        }
        case 'dddf1878-6d6a-4af7-b622-47aeed6e1bfa': {
          codeGa = 'G-8XL9K2SZLX'
          break
        }
        case '4c256851-6546-4d17-9332-e810b21ba580': {
          codeGa = 'G-20R76T7RTN'
          break
        }
        default: {
          codeGa = 'G-8DE5LSGXPP'
          break
        }
      }
      let ga = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${codeGa}');`
      var scriptTag = document.createElement('script')
      // scriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=G-LT5T6BXHS4'
      // scriptTag.async = true
      scriptTag.async = true
      scriptTag.setAttribute(
        'src',
        `https://www.googletagmanager.com/gtag/js?id=${codeGa}`
      )
      var scriptTag1 = document.createElement('script')
      scriptTag1.appendChild(document.createTextNode(ga))
      document.head.appendChild(scriptTag)
      document.head.appendChild(scriptTag1)
      console.log('ga', ga)
    }
  }, [product])
  // Custom hook che fa il fetch dei dati in base ai parametri ricevuti nell'url
  const useFetchData = () => {
    useEffect(() => {
      fetchProducts()
      fetchDiscoverResource({ gtin: prod })
      if (!lot) {
        dispatch({
          type: 'SET_MENU_SECTION',
          payload: 1
        })
      } else {
        fetchLightLots()
        dispatch({
          type: 'SET_MENU_SECTION',
          payload: 4
        })
        dispatch({
          type: 'SET_URL_HAS_LOT',
          payload: true
        })
        dispatch({
          type: 'SET_LOT_NUMBER',
          payload: lot
        })
      }
    }, [])
  }
  // chiamata all'hook che fa il fetch dei dati

  useFetchData()

  // definisco stili e media query
  const { classes } = useStyles()
  const isSmall = useMediaQuery('(max-width: 600px)')
  const isMedium = useMediaQuery('(max-width: 960px)')
  const languageAvailable = []
  if (project && project.defaultLocale) {
    languageAvailable.push(project.defaultLocale)
  }
  product.availableTranslationsLanguage?.forEach((item) => {
    languageAvailable.push(item[0])
  })

  const dropdownListLanguage = languageAvailable.map((item) => {
    // only 24 european official languages
    switch (item) {
      case 'it-IT':
        return 'ITA'
      case 'en-EN':
        return 'ENG'
      case 'fr-FR':
        return 'FRA'
      case 'de-DE':
        return 'DEU'
      case 'es-ES':
        return 'ESP'
      case 'pt-PT':
        return 'POR'
      case 'nl-NL':
        return 'NLD'
      case 'pl-PL':
        return 'POL'
      case 'cs-CZ':
        return 'CZE'
      case 'sk-SK':
        return 'SVK'
      case 'hu-HU':
        return 'HUN'
      case 'ro-RO':
        return 'ROM'
      case 'bg-BG':
        return 'BUL'
      case 'el-GR':
        return 'GRE'
      case 'da-DK':
        return 'DAN'
      case 'fi-FI':
        return 'FIN'
      case 'sv-SE':
        return 'SWE'
      case 'et-EE':
        return 'EST'
      case 'lv-LV':
        return 'LAV'
      case 'lt-LT':
        return 'LIT'
      case 'sl-SI':
        return 'SLV'
      case 'ga-IE':
        return 'GLE'
      case 'mt-MT':
        return 'MLT'
      case 'cy-GB':
        return 'CYM'
      default:
        return 'ITA'
    }
  })

  console.log('dropdownListLanguage', dropdownListLanguage)

  const changeContentForLocalhost = (isLocalhost) => {
    if (isLocalhost) {
      return (
        <div
          style={{
            position: isMedium ? 'fixed' : 'absolute',
            top: 0,
            right: isSmall ? '20px' : '60px',
            zIndex: '999'
          }}
        >
          {/* <RegularButton color="warning"
                  onClick={() => setOpenBuyNow(true)}
                   round>{t('buyNow')}
                   </RegularButton> */}

          <Dropdown
            buttonText={languageSelected}
            className='fredoka'
            buttonProps={{
              color: 'ctaButton',
              startIcon: <LanguageIcon />
            }}
            dropdownList={dropdownListLanguage}
            onClick={(e) => selectLanguage(e)}
          />
        </div>
      )
    } else {
      return (
        <div
          style={{
            position: isMedium ? 'fixed' : 'absolute',
            top: 0,
            right: isSmall ? '20px' : '60px',
            zIndex: '999'
          }}
        >
          {/* <RegularButton color="warning"
                  onClick={() => setOpenBuyNow(true)}
                   round>{t('buyNow')}
                  </RegularButton> */}

          <Dropdown
            buttonText={languageSelected}
            className='fredoka'
            buttonProps={{
              color: 'ctaButton',
              startIcon: <LanguageIcon />
            }}
            dropdownList={dropdownListLanguage}
            onClick={(e) => selectLanguage(e)}
          />
        </div>
      )
    }
  }

  const isFashion = (project) => {
    if (project && Object.keys(project).length) {
      if (
        project.features &&
        Array.isArray(project.features) &&
        project.features.includes('fashion')
      ) {
        return true
      } else {
        return false
      }
    } else {
      // NOTE: questo è un errore perché il project arrivati qui ci deve essere per forza (al massimo non è stato ancora caricato)
    }
  }

  const dynamicFooter = (project) => {
    return isTrackit(domain) ? <FooterTrackit /> : <FooterTrusty />
  }
  const dynamicFooterDesktop = (project) => {
    return isTrackit(domain) ? <FooterTrackitDesktop /> : <FooterTrusty />
  }
  // NOTE: quando non viene trovato il prodotto.
  if (!informationFound) {
    return <NotFound />
  } else {
    if (!informationLoaded) {
      return <LogoLoader size='large' />
    } else {
      return (
        <SkeletonTheme color='#ddebdd' highlightColor='#d3e3d3'>
          {!isMedium && (
            <MyAppBar
              company={company}
              languageSelected={languageSelected}
              selectLanguage={selectLanguage}
              dropdownListLanguage={dropdownListLanguage}
              isFashion={isFashion(project)}
            />
          )}
          {isMedium && changeContentForLocalhost(isLocalhost)}
          <div>
            {/* dialog track documents */}
            <DocumentsDialog />
            {/* dialog track description */}
            <DescriptionDialog />
            {/* dialog certifications mobile */}
            <CertDialog />
            {/* content */}
            <div
              style={{
                minHeight: '98vh',
                backgroundColor: isSmall ? '#fafafa' : ''
              }}
            >
              {hideOrShowProperty(discover, 'backgroundImage', 'product') &&
              product &&
              product.backgroundImage ? (
                <Parallax
                  style={{
                    height: isSmall ? '30vh' : '60vh',
                    display:
                      isSmall &&
                      sectionActive !== 1 &&
                      sectionActive !== 2 &&
                      sectionActive !== 3 &&
                      sectionActive !== 4 &&
                      sectionActive !== 5 &&
                      sectionActive !== 6
                        ? 'none'
                        : ''
                  }}
                  image={
                    product.backgroundImage.cropped !== ''
                      ? product.backgroundImage.cropped
                      : '/image-not-found.png'
                  }
                  className={classes.parallax}
                />
              ) : (
                <Box id='skeleton-background'>
                  <Skeleton
                    duration={0.7}
                    height={isSmall ? '30vh' : '60vh'}
                    width='100%'
                  />
                </Box>
              )}
              <div
                className={
                  isSmall ? classes.cardContainerMobile : classes.cardContainer
                }
              >
                <Card
                  className={classNames(classes.main, classes.mainRaised)}
                  style={
                    isSmall
                      ? {
                          marginLeft: '0px',
                          marginRight: '0px',
                          borderRadius: '0px',
                          boxShadow: sectionActive !== 1 ? 'none' : ''
                        }
                      : {}
                  }
                >
                  {isSmall ? (
                    <PresentationPageMobile
                      product={product}
                      company={company}
                      sectionActive={sectionActive}
                      isFashion={isFashion(project)}
                    />
                  ) : (
                    <PresentationPageDesktop
                      product={product}
                      isFashion={isFashion(project)}
                    />
                  )}
                  {/* nel caso dello small è già messo dentro al PresentationPageMobile, per dare un impilazione diversa */}
                  {sectionActive === 1 && !isSmall ? (
                    <>
                      <ProductPage mobile={false} />
                      {/* social link */}
                      <Box
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <SocialLinks />
                      </Box>
                    </>
                  ) : sectionActive === 2 ? (
                    !isSmall ? (
                      <>
                        <CompanyInfo />
                        {/* social link */}
                        <Box
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <SocialLinks />
                        </Box>
                      </>
                    ) : (
                      <>
                        <CompanyInfoMobile />
                        {/* social link */}
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '20px'
                          }}
                        >
                          <SocialLinks />
                        </Box>
                      </>
                    )
                  ) : sectionActive === 3 ? (
                    // NOTE: l'hide per certifications, allergenes e traceability è messo direttamente nel caricamento delle relative icone sul menu
                    <CertificationsTable />
                  ) : // : sectionActive === 4
                  //   ? (
                  //     <AllergenesTable />
                  //     )
                  sectionActive === 4 ? (
                    <Traceability />
                  ) : sectionActive === 5 ? (
                    <Disposal />
                  ) : sectionActive === 6 ? (
                    // <NutritionalValue />
                    <ProductTab />
                  ) : (
                    ''
                  )}
                </Card>
              </div>
            </div>
          </div>
          {isMedium && (
            <div
              style={{
                position: isMedium ? 'fixed' : 'absolute',
                top: 5,
                left: isSmall ? '20px' : '60px',
                zIndex: '999'
              }}
            >
              {company && company.logo && company.logo.original ? (
                company.website ? (
                  <>
                    <a
                      href={`${company.website}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <Avatar
                        className={classes.roundedAvatar}
                        alt={`${company.name} logo`}
                        src={`${company.logo.original}`}
                      />
                    </a>
                  </>
                ) : (
                  <Avatar
                    className={classes.roundedAvatar}
                    alt={`${company.name} logo`}
                    src={`${company.logo.original}`}
                  />
                )
              ) : null}
            </div>
          )}

          {isSmall ? dynamicFooter(project) : dynamicFooterDesktop(project)}
        </SkeletonTheme>
      )
    }
  }
}
