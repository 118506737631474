import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import {
  Typography,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PlaceIcon from '@mui/icons-material/Place';
import ForwardIcon from '@mui/icons-material/Forward';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import TodayIcon from '@mui/icons-material/Today';
import organizationToRules from 'organizationToRules'




export default function XdaiStorytelling ({ events, glnName, gtinName, phaseIndex, organizationId }) {
  const { t } = useTranslation('xdaiStorytelling')
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {phaseIndex === 0 && (organizationToRules[organizationId] && organizationToRules[organizationId].traceabilityXdaiStorytelling) ? (
          <Grid container spacing={2}>
          <Grid item  xs={5} md={5}>
            <h6  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <TodayIcon style={{ color: '#3C4858' }} />
               {events?.[0] ? moment(events?.[0]?.eventTime).format('DD/MM/YYYY') : ''} 
            </h6>
          </Grid>
          <Grid item  xs={2} md={2}>
            <h6  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <ForwardIcon style={{ color: '#3C4858' }} />
            </h6>
          </Grid>
          <Grid item  xs={5} md={5}>
          <h6  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <InsertInvitationIcon style={{ color: '#3C4858' }} />
               {events?.[0] ? moment(events?.[0]?.metadata.endEventTime).format('DD/MM/YYYY') : ''} 
            </h6>
          </Grid>
          <Grid item  xs={12} md={12}>
            <h6  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <PlaceIcon style={{ color: '#3C4858' }} />
               {events?.[0] ? glnName[events?.[0]?.location]: ''} 
            </h6>
          </Grid>

        </Grid>
        ):(
        <Grid container spacing={2}>
          <Grid item  xs={6} md={6}>
            <h6  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <CalendarMonthIcon style={{ color: '#3C4858' }} />
               {events?.[0] ? moment(events?.[0]?.eventTime).format('DD/MM/YYYY') : ''} 
            </h6>
          </Grid>
          <Grid item  xs={6} md={6}>
            <h6  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <PlaceIcon style={{ color: '#3C4858' }} />
               {events?.[0] ? glnName[events?.[0]?.location]: ''} 
            </h6>
          </Grid>

        </Grid>
        )}
        
      </Grid>
      <Grid item xs={12}>
        <Accordion expanded="true" className="accordionRoot">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography style={{ textAlign: 'center', width: '100%' }} className="fredoka">{t('usedMaterials')}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'block' }}>
             {(organizationToRules[organizationId] && organizationToRules[organizationId].traceabilityXdaiStorytelling) ? (
              <>
                {events[0]?.inputs?.map((row)=>
              <Typography className="raleway">{`${t(`gtin.${row?.gtin}`, row?.metadata?.description)} - ${t("lotNumber")} ${row?.lotNumber.split('_')[0]} - ${t("quantity")} ${row?.quantity?.toString() +" [" +row?.uom +"]"}`}</Typography>
            )}
              </>
            ): (
              <>
             {events[0]?.inputs?.map((row)=>
              <Typography className="raleway">{` ${t(`gtin.${row?.gtin}`, row?.metadata?.description)}- ${row?.lotNumber} - ${row?.quantity?.toString() +" [" +row?.uom +"]"}`}</Typography>
            )}
            </>
            )}
          </AccordionDetails>
        </Accordion>
        {events[0]?.outputs?.length > 0 && events[0]?.eventType ==="transformation" ? 
          <Accordion expanded="true" className="accordionRoot">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ textAlign: 'center', width: '100%' }} className="fredoka">{t('producedMaterials')}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>
            {(organizationToRules[organizationId] && organizationToRules[organizationId].traceabilityXdaiStorytelling) ? (
              <>
                {events[0]?.outputs?.map((row)=>
              <Typography className="raleway">
                {`${t(`gtin.${row?.gtin}`, row?.metadata?.description)} - ${t('lotNumber')} ${row?.lotNumber.split('_')[0]} - ${t("quantity")} ${row?.quantity?.toString()} [${row?.uom}]`}
              </Typography>
            )}
              </>
            ): (
              <>
              {events[0]?.outputs?.map((row)=>
            <Typography className="raleway">{`${t(`gtin.${row?.gtin}`, row?.metadata?.description)} - ${row?.lotNumber} - ${row?.quantity?.toString() +" [" +row?.uom +"]"}`}</Typography>
          )}
            </>
            )}
            </AccordionDetails>
          </Accordion> : null}
      </Grid>
    </Grid>
  );
}
