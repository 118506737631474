import React, { useContext } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Link,
  Tooltip,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  // Typography
} from '@mui/material'
//import Hidden from '@mui/material/Hidden'
import CopyIcon from '@mui/icons-material/ContentCopy'
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { AppContext } from '../../context/AppState'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import BlockchainIcons from 'components/Blockchain/BlockchainIcons'

function Row(props) {
  const { row, glnName, gtinName, isTrackAndTraceClickable, index } = props
  const { t } = useTranslation('xdaiHeader')
  const shouldContainerBlockchainObject =
    row.blockchain && Object.keys(row.blockchain).length > 0

    const handleClickViewFile = (e, document) => {
      e.preventDefault()
      window.open(document.fileUrl)
    }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={`panel${index}bh-header`}
      >
        <Grid container>
          <Grid item xs={2}>
            {shouldContainerBlockchainObject &&
              Object.keys(row.blockchain).map(
                (bloc, index) => (
                  <Link
                    key={`index_${index}`}
                    target="_blank"
                    href={
                      row.blockchain[bloc][row.blockchain[bloc].length - 1]
                        .publicUrl
                    }
                    rel="noreferrer"
                  >
                    {shouldContainerBlockchainObject ? (
                      <BlockchainIcons blockchain={bloc} />
                    ) : (
                      ''
                    )}
                  </Link>
                )
                // row.blockchain[bloc][row.blockchain.length - 1].publicUrl
              )}
          </Grid>
         
          <Grid item xs={10}>
            {t(`eventType.${row.eventType}`)+(row.bizStep?" - "+t(`bizStep.${row.bizStep}`):"")}
          </Grid>
          <Grid item xs={2}/>
          <Grid item xs={8}>
          {row.eventTime
            ? moment(row.eventTime).format('DD/MM/YYYY')
            : moment().format('DD/MM/YYYY')}
          </Grid>
          
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {row.eventType==="uploading_file" ? 
            <Grid item xs={12}>
            <Box sx={{ p: 1 }}>
            {t(`file`)}{":"}
            {row.documents?.map((document,i)=>
          <div>
            {/* <Link
            key={`file_uploading_${i}`}
            target='_blank'
            href={
              document.fileUrl
            } rel='noreferrer'
            >
            {"upload_file_"+(i+1)}
            </Link> */}
            <Link
                    key={`file_uploading_${i}`}
                    target="_blank"
                    href={document.fileUrl}
                    rel="noreferrer"
                    onClick={(e) => {
                      handleClickViewFile(e, document)
                    }}
                  >
                     {document.originalFilename || 'upload_file_' + (i + 1)}
                  </Link>
          </div>)}
            </Box>
          </Grid>
          :
          <>
          <Grid item xs={12}>
            <Box sx={{ p: 1 }}>
            {row.bizStep && (row.bizStep==="receiving" ||row.bizStep==="shipping")  ? 
            (<div>
            <div>{t('locationFrom')}{": "}
            {row.sourceLocations?.map((source,index)=><p key={`source_${source}_${index}`}>{glnName[source] || "-"}</p>)}</div> 
            <div>{t('locationTo')}{": "}
            {row.destinationLocations?.map((destination,index)=><p key={`destination_${destination}_${index}`}>{glnName[destination] || "-"}</p>)}
            </div>
            </div>)
            : (<div>{t('location')}{": "}<p>{glnName[row.location] || "-"}</p></div>)
            } 
            </Box>
          </Grid>
          <Grid item xs={12} sm={row.bizStep !== 'receiving' ? 6 : 12}>
            <Grid container sx={{ p: 1 }}>
              <Grid item xs={12}>
                {t('materials') + ' Input'}
              </Grid>
              <Grid item xs={12}>
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ width: '40%' }}
                      >
                        {t('input')}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {t('lottoInput')}
                      </TableCell>
                      <TableCell component="th" scope="row" align="right">
                        {t('quantityInput')}
                      </TableCell>
                    </TableRow>
                    {row.inputs.map((e, inputIndex) => (
                      <TableRow key={`inputs_${inputIndex}`}>
                        <TableCell component="th" scope="row">
                          {isTrackAndTraceClickable ? (
                            <Link
                              target="_blank"
                              href={`${window.location.origin}/01/${e.gtin}`}
                              variant="body2"
                              color="inherit"
                              rel="noreferrer"
                            >
                              {gtinName[e.gtin]}
                            </Link>
                          ) : (
                            <>{t(`gtin.${e.gtin}`, gtinName[e.gtin] )}</>
                          )}
                        </TableCell>
                        <TableCell>
                          <div>
                            {e.lotNumber}
                            <Tooltip title={t('copy')}>
                              <IconButton
                                aria-label="copy"
                                size="small"
                                onClick={() =>
                                  navigator.clipboard.writeText(e.lotNumber)
                                }
                              >
                                <CopyIcon fontSize="inherit" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          {e.quantity
                            ? e.quantity + '[' + (e.uom || '-') + ']'
                            : '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Grid>
          {/* {row.bizStep !== 'receiving' && ( */}
          {row.eventType === 'transformation' && (
            <Grid item xs={12} sm={6}>
              <Grid container sx={{ mt: { xs: 3, md: 0 }, p: 1 }}>
                <Grid item xs={12}>
                  {t('materials') + ' Output'}
                </Grid>
                <Grid item xs={12}>
                  <Table size="small" aria-label="purchases">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: '40%' }}
                        >
                          {t('output')}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {t('lottoOutput')}
                        </TableCell>
                        <TableCell component="th" scope="row" align="right">
                          {t('quantityOutput')}
                        </TableCell>
                      </TableRow>
                      {row.outputs.map((e, inputIndex) => (
                        <TableRow key={`inputs_${inputIndex}`}>
                          <TableCell component="th" scope="row">
                            {isTrackAndTraceClickable ? (
                              <Link
                                target="_blank"
                                href={`${window.location.origin}/01/${e.gtin}`}
                                variant="body2"
                                color="inherit"
                                rel="noreferrer"
                              >
                                {gtinName[e.gtin]}
                              </Link>
                            ) : (
                              <>{t(`gtin.${e.gtin}`, gtinName[e.gtin] )}</>
                            )}
                          </TableCell>
                          <TableCell>
                            <div>
                              {e.lotNumber}
                              <Tooltip title={t('copy')}>
                                <IconButton
                                  aria-label="copy"
                                  size="small"
                                  onClick={() =>
                                    navigator.clipboard.writeText(e.lotNumber)
                                  }
                                >
                                  <CopyIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </TableCell>
                          <TableCell size="small" align="right">
                            {e.quantity
                              ? e.quantity + '[' + (e.uom || '-') + ']'
                              : '-'}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Grid>
          )}
          {/* {shouldContainerBlockchainObject &&
          <Grid item  xs={12}>
            <Box sx={{p:1,alignContent:"center", display:"flex", color:"#99999999"}}>
              <HelpOutlineIcon fontSize="small"/> 
              <Typography variant='body2'> Click the icon to show data in blockchain {Object.keys(row.blockchain).map(
                (bloc, index) => (
                  <Link
                    key={`index_${index}`}
                    target="_blank"
                    href={
                      row.blockchain[bloc][row.blockchain[bloc].length - 1]
                        .publicUrl
                    }
                    rel="noreferrer"
                  >
                    {shouldContainerBlockchainObject ? (
                      <BlockchainIcons blockchain={bloc} />
                    ) : (
                      ''
                    )}
                  </Link>
                )
              )}</Typography>
              </Box>
          </Grid>
          } */}
          </>}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default function XdaiTableMobile({ events, glnName, gtinName }) {
  // const { t } = useTranslation('xdaiHeader')
  const { state } = useContext(AppContext)
  const { product } = state
  const isTrackAndTraceClickable = product.metadata.isTrackAndTraceClickable
 console.log("events", events)
  return (
    <>
      <Grid container spacing={2}>
        {events.map((row, index) => (
          <Grid item xs={12} key={index}>
            <Row
              key={`events_${index}`}
              index={index}
              row={row}
              glnName={glnName}
              gtinName={gtinName}
              isTrackAndTraceClickable={isTrackAndTraceClickable}
            />
          </Grid>
        ))}
      </Grid>

      {/* </Hidden> */}
    </>
  )
}
