export const purchaseOrderStaticHUILA = [{
  "_id": {
    "$oid": "659eb937bda30a565dd262bc"
  },
  "type": "event",
  "status": "active",
  "sourceLocations": [
    "34aff1eb-5902-476a-ae55-a6ee29ad98a9-f00006271"
  ],
  "destinationLocations": [
    "34aff1eb-5902-476a-ae55-a6ee29ad98a9-f00003413"
  ],
  "tagsId": [],
  "projectId": "34aff1eb-5902-476a-ae55-a6ee29ad98a9",
  "eventTime": "2023-02-03T00:00:00.000Z",
  "eventType": "observation",
  "name": "Receiving from cooperative ASOPROCAR (Huila)",
  "bizStep": "receiving",
  "metadata": {
    "purchaseOrderNumber": "4300037087"
  },
  "inputs": [
    {
      "_id": {
        "$oid": "659eb937bda30ac4add262bd"
      },
      "gtin": "e365f71b-0651-4ed2-9994-59b5fd425877-p00000027",
      "lotNumber": "9900209723",
      "metadata": {
        "description": "COCOA HUILA",
        "customerErpCode": "4000004"
      },
      "quantity": 2702.2,
      "uom": "KG",
      "hash": "27c7fe2e2eb5a1d056e330569963ded286828d1fb338c83eca7f198d0bebe96e"
    }
  ],
  "uuid": "1b580f29-0b0c-4735-a9b7-1cf86718af93",
  "outputs": [
    {
      "_id": {
        "$oid": "659eb937bda30a0a41d262be"
      },
      "gtin": "e365f71b-0651-4ed2-9994-59b5fd425877-p00000027",
      "lotNumber": "9900209723",
      "metadata": {
        "description": "COCOA HUILA",
        "customerErpCode": "4000004"
      },
      "quantity": 2702.2,
      "uom": "KG",
      "hash": "27c7fe2e2eb5a1d056e330569963ded286828d1fb338c83eca7f198d0bebe96e"
    }
  ],
  "location": "34aff1eb-5902-476a-ae55-a6ee29ad98a9-f00003413",
  "hash": "849956e272a3b77fe8e69a686c2895a936ac8451e1aa132b0bf642c0a3c7d62b",
  "media": [],
  "documents": [],
  "bizTransactionList": [],
  "createdAt": {
    "$date": "2024-01-10T15:35:19.032Z"
  },
  "updatedAt": {
    "$date": "2024-01-10T15:35:19.032Z"
  },
  "__v": 0
},
{
  "_id": {
    "$oid": "659eb8c8bda30a9c24d24f00"
  },
  "type": "event",
  "status": "active",
  "sourceLocations": [
    "34aff1eb-5902-476a-ae55-a6ee29ad98a9-f00006340"
  ],
  "destinationLocations": [
    "34aff1eb-5902-476a-ae55-a6ee29ad98a9-f00003413"
  ],
  "tagsId": [],
  "projectId": "34aff1eb-5902-476a-ae55-a6ee29ad98a9",
  "eventTime": "2022-10-28T00:00:00.000Z",
  "eventType": "observation",
  "name": "Receiving from cooperative CORCACAO (Huila)",
  "bizStep": "receiving",
  "metadata": {
    "purchaseOrderNumber": "4300035806"
  },
  "inputs": [
    {
      "_id": {
        "$oid": "659eb8c8bda30af544d24f01"
      },
      "gtin": "e365f71b-0651-4ed2-9994-59b5fd425877-p00000027",
      "lotNumber": "9900202741",
      "metadata": {
        "description": "COCOA HUILA",
        "customerErpCode": "4000004"
      },
      "quantity": 1509,
      "uom": "KG",
      "hash": "201c53d6e21abd327a515f34eb3d11582dcd5f505c7806dd15a3f5ce1b2dbff1"
    }
  ],
  "uuid": "423c820c-4e5b-4bcc-ba9b-284904c8aded",
  "outputs": [
    {
      "_id": {
        "$oid": "659eb8c8bda30a8b33d24f02"
      },
      "gtin": "e365f71b-0651-4ed2-9994-59b5fd425877-p00000027",
      "lotNumber": "9900202741",
      "metadata": {
        "description": "COCOA HUILA",
        "customerErpCode": "4000004"
      },
      "quantity": 1509,
      "uom": "KG",
      "hash": "201c53d6e21abd327a515f34eb3d11582dcd5f505c7806dd15a3f5ce1b2dbff1"
    }
  ],
  "location": "34aff1eb-5902-476a-ae55-a6ee29ad98a9-f00003413",
  "hash": "214f0ae97a336128a98dd9bf490f82f8e6df7611556c2aff3d4ef77338289393",
  "media": [],
  "documents": [],
  "bizTransactionList": [],
  "createdAt": {
    "$date": "2024-01-10T15:33:28.640Z"
  },
  "updatedAt": {
    "$date": "2024-01-10T15:33:28.640Z"
  },
  "__v": 0
}]

export const purchaseOrderStaticTUMACO = [{
  "_id": {
    "$oid": "659eb937bda30a565dd262bc"
  },
  "type": "event",
  "status": "active",
  "sourceLocations": [
    "34aff1eb-5902-476a-ae55-a6ee29ad98a9-f00006271"
  ],
  "destinationLocations": [
    "34aff1eb-5902-476a-ae55-a6ee29ad98a9-f00003413"
  ],
  "tagsId": [],
  "projectId": "34aff1eb-5902-476a-ae55-a6ee29ad98a9",
  "eventTime": "2023-01-26T00:00:00.000Z",
  "eventType": "observation",
  "name": "Receiving from cooperative ASPROCAT (Tumaco)",
  "bizStep": "receiving",
  "metadata": {
    "purchaseOrderNumber": "4300036957"
  },
  "inputs": [
    {
      "_id": {
        "$oid": "659eb937bda30ac4add262bd"
      },
      "gtin": "e365f71b-0651-4ed2-9994-59b5fd425877-p00000027",
      "lotNumber": "9900901767",
      "metadata": {
        "description": "COCOA TUMACO A",
        "customerErpCode": "4000004"
      },
      "quantity": 10030.0,
      "uom": "KG",
      "hash": "27c7fe2e2eb5a1d056e330569963ded286828d1fb338c83eca7f198d0bebe96e"
    }
  ],
  "uuid": "1b580f29-0b0c-4735-a9b7-1cf86718af93",
  "outputs": [
    {
      "_id": {
        "$oid": "659eb937bda30a0a41d262be"
      },
      "gtin": "e365f71b-0651-4ed2-9994-59b5fd425877-p00000027",
      "lotNumber": "9900209723",
      "metadata": {
        "description": "COCOA HUILA",
        "customerErpCode": "4000004"
      },
      "quantity": 2702.2,
      "uom": "KG",
      "hash": "27c7fe2e2eb5a1d056e330569963ded286828d1fb338c83eca7f198d0bebe96e"
    }
  ],
  "location": "34aff1eb-5902-476a-ae55-a6ee29ad98a9-f00003413",
  "hash": "849956e272a3b77fe8e69a686c2895a936ac8451e1aa132b0bf642c0a3c7d62b",
  "media": [],
  "documents": [],
  "bizTransactionList": [],
  "createdAt": {
    "$date": "2024-01-10T15:35:19.032Z"
  },
  "updatedAt": {
    "$date": "2024-01-10T15:35:19.032Z"
  },
  "__v": 0
},
]