import React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { isTrackit } from '../../utils/utils'
import { domain } from 'serviceWorker'

const FooterTrusty = () => {
    const isSmall = useMediaQuery('(max-width: 600px)')
  return (
    <footer style={{
        color:"#999999",
        textAlign: 'center',
        display:"flex",
        alignItems: 'center',
        justifyContent: 'center',
       // position: 'fixed',
        bottom: 100, width: '100%', marginBottom: !isTrackit(domain) ? isSmall ? "115px":"10px" :""}}>
          <div style={{textAlign:"center", alignItems: 'center', fontSize:"14px", fontFamily:"Raleway, sans-serif"}}>
          Powered by 
          </div>
          <a style={{ width: isSmall? '17%': '4.5%' }} href="https://www.trusty.id" target="_blank" rel="noopener noreferrer">
            <img style={{ width: "100%" }} src={'/logo-trusty-web-version.png'} alt='trusty-logo' />
          </a>   
    </footer>
  )
}

export default FooterTrusty
