import React, { useContext, useEffect } from 'react'
import { AppContext } from '../../context/AppState'
import NutritionalValue from '../AttributesGrid/NutritionalValue'
import IngredientsSection from '../AttributesGrid/IngredientsSection'
import IngredientsAromaticWineSection from '../AttributesGrid/IngredientsAromaticWineSection'
import AllergenesSection from '../AttributesGrid/AllergenesSection'

import useMediaQuery from '@mui/material/useMediaQuery'

const ProductTab = (props) => {
  const isSmall = useMediaQuery('(max-width: 600px)')
  const { state } = useContext(AppContext)
  const { sectionActive, product } = state
  //const { mobile } = props
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [sectionActive])

  return (
    <div
      style={
        isSmall
          ? { marginTop: '16px', paddingLeft: 20, paddingRight: 20 }
          : { paddingLeft: 40, paddingRight: 40 }
      }
    >
      {product.ingredients?.metadata?.isAromaticWine ? (
        <IngredientsAromaticWineSection />
      ) : (
        <IngredientsSection />
      )}
      <AllergenesSection />
      <NutritionalValue />
    </div>
  )
}
export default ProductTab
