import React, { useState, useEffect } from 'react'
import { MapContainer, TileLayer, Marker, Popup, Polyline, useMap } from 'react-leaflet'
import Button from 'components/CustomButtons/Button'
import { makeStyles } from 'tss-react/mui'
import L from 'leaflet'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles()(theme => ({
  changeTile: {
    position: 'absolute',
    top: theme.spacing(1),
    left: '70px',
    padding: theme.spacing(1),
    zIndex: '400',
    '@media(max-width: 600px)': {
      left: 'unset',
      right: theme.spacing(1)
    }
  }
}))

const MapAutoCenter = ({ positions }) => {
  const map = useMap()
  console.log(positions && positions.length > 0)
  useEffect(() => {
     if (positions && positions.length > 0) {
      // map.panTo({ lat, lng })
    map.fitBounds(L.latLngBounds(positions?.map((pos)=> L.latLng(pos?.position?.[0] || pos?.[0], pos?.position?.[1] || pos?.[1]))).pad(0.5))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positions])

  return null
}

function Map (props) {
  const { classes } = useStyles()
  const { t } = useTranslation('map')
  
  // different tile urls objects
  const urlTiles = {
    satellite: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    openstreetmap: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  }

  // current tile. Default is satellite
  const [tileSet, setTileSet] = useState(urlTiles.satellite)

  // function to toggle map visualization
  const toggleTileSet = () => {
    tileSet === urlTiles.satellite ? setTileSet(urlTiles.openstreetmap) : setTileSet(urlTiles.satellite)
  }

  //function to get Icon
 const getIcon = ({iconUrl="/marker/marker_blue.svg", position}) => {
  console.log("iconURL", iconUrl)
  console.log("position", position)
  const icon = new L.Icon({
    iconUrl,
    iconRetinaUrl: iconUrl,
    iconAnchor: new L.Point(15, 30),
    popupAnchor: new L.Point(0, -30),
    // shadowUrl: null,
    // shadowSize: null,
    // shadowAnchor: null,
    iconSize: new L.Point(30, 30)

  })
  return icon
 }

  return (
    <MapContainer center={[props.lat, props.lng]} doubleClickZoom={false} scrollWheelZoom={false} touchZoom zoom={props.zoom ? props.zoom : 17} style={props.isHeader ? { height: '100%' } : { height: '100%' }} boxZoom={false}>
      <MapAutoCenter positions={props.positions} lat={props.lat || 0} lng={props.lng || 0} zoom={props.zoom} />
      <TileLayer attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url={tileSet} />
      {props.positions
        ? props.positions.map((position, index) => <Marker key={`marker-${index}`} position={[position?.position?.[0] || position?.[0], position?.position?.[1] || position?.[1]]} icon={getIcon({iconUrl:position.iconUrl, position:[position?.position?.[0] || position?.[0], position?.position?.[1] || position?.[1]]})} > 
          <Popup>
            <div>
            {position.popup || null}
            <a style={{ textDecoration: 'none' }} href={`https://www.google.com/maps/search/?api=1&query=${position[0]},${position[1]}`} target='_blank' rel='noopener noreferrer nofollow'>Apri la mappa</a>
            </div>
          </Popup>
        </Marker>)
        : <Marker position={[props.lat, props.lng]} icon={getIcon({ position:[props.lat, props.lng]})}>
          <Popup>
            <a style={{ textDecoration: 'none' }} href={`https://www.google.com/maps/search/?api=1&query=${props.lat},${props.lng}`} target='_blank' rel='noopener noreferrer nofollow'>Apri la mappa</a>
          </Popup>
        </Marker>}
      <Button onClick={() => toggleTileSet()} color='ctaButton' className={classes.changeTile}>{tileSet === urlTiles.openstreetmap ? t("satelliteView",'Vista Satellitare') : t("streetView",'Vista Stradale')}</Button>
      {(props.positions && !props.multiPositions) ? <Polyline color='#09a963' positions={props.positions} /> : ''}
      {/* {props.multiPositions ?  props.multiPositions.positions.map((positions, index)=> <Polyline color={props.multiPositions?.colors?.[index]||'#09a963'} positions={positions} />) : ''} */}
      {props.multiPositions ?  props.multiPositions.map((positions, index)=> <Polyline color='#09a963' positions={positions} />) : ''}
    </MapContainer>

  )
}

export default Map
