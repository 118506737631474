import React, { useContext, useEffect, useState } from 'react'
// import { makeStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery';
import { AppContext } from '../../context/AppState'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'
import './style.css'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {  currLang } from '../../utils/translations'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(presentationStyle)

const AllergenesSection = () => {
  const { classes } = useStyles()
  const { state } = useContext(AppContext)
  const { product } = state
  const [allergenes, setAllergenes] = useState([])
  let allergenesTemp = []

  const { t } = useTranslation('allergenes')

  useEffect(() => {
    if (product.allergenes && Array.isArray(product.allergenes) && product.allergenes.length) {
      allergenesTemp = product.allergenes.map(x => {
        if (x === 'sulphites') {
          return t(`components.${x}`).toUpperCase()
        }else {
          return t(`components.${x}`)
        }}).join(', ')
    } 
    setAllergenes(allergenesTemp)
    // eslint-disable-next-line
  }, [currLang])

  const isSmall = useMediaQuery('(max-width: 600px)')

  return <div className='ingredientContainer'  style={ isSmall ? { marginTop: '16px' } : { background: 'white' }}>
    {product.allergenes && Array.isArray(product.allergenes) && product.allergenes.length > 0 && 
    <>
    <Typography variant='h5' component='p' style={{ color: '#3C4858', margin: '0px 0 20px 0' }} className={classes.fredoka}>{t(`title`)}</Typography>
    <Typography variant='body' sx={{ mb: 2}}>{allergenes}</Typography>
    </>
  }
  </div>
}
export default AllergenesSection
